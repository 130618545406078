import React, { useState } from 'react';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const SearchableSelect = ({ options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const toggle = () => setIsOpen(!isOpen);

  const handleSearchInputChange = (event) => setSearchValue(event.target.value);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
    setSearchValue('');
  };

  const filteredOptions = options.filter(option =>
    option.value.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} direction='end'>
      <DropdownToggle caret className="form-control-alternative status-filter dropdown-border">
        {selectedOption ? selectedOption.value : '---Select an option---'}
      </DropdownToggle>
      <DropdownMenu  class="h-auto d-inline-block" style={{maxHeight: 300, overflowX:"hidden"}}>
        <Input className="form-control-alternative" value={searchValue} onChange={handleSearchInputChange} placeholder="Search..." />
        {filteredOptions.map(option => (
          <DropdownItem key={option.key} onClick={() => handleOptionSelect(option)}>
            {option.value}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SearchableSelect;
