/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ModalHeader,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useToasts } from "react-toast-notifications";
import { getMethod, postMethod } from "services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import EditSuperAgent from "./edit-super-agent";
import { btnCss, iconCss } from "appConfig";
import ViewSuperAgent from "./view-super-agent";
import ReactPaginate from "react-paginate";
import { getPrivileges } from "services/util";

const sortIS = {
  sortName: 0,
  sortEmail: 0,
  sortPhone: 0,
};

const SuperAgentList = () => {
  const [, setSubsList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize] = React.useState(5);
  const [pagesCount, setPageCount] = React.useState(0);
  const [totalRec, setTotalRec] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [subsObj, setSubsObj] = React.useState(null);
  const [view, setView] = React.useState(false);
  const [openEdit, setOpenEditModal] = React.useState(false);
  const [editObj, setEditObj] = React.useState(null);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [openDelModal, setOpenDelModal] = React.useState(false);
  const [banks, setBanks] = React.useState(null);
  const [mobileMoney, setMMO] = React.useState(null);
  const [verifyObj, setVerifyObj] = React.useState(null);
  const [openVerifyModal, setOpenVerifyModal] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [subsLoading, setSubsLoading] = React.useState(false);
  const [blockLoading, setBlockLoading] = React.useState(false);
  const [verifyBankLoading, setVerifyBankLoading] = React.useState(false);
  const [verifyBankObj, setVerifyBankObj] = React.useState(null);
  const [openVerifyBankModal, setOpenVerifyBankModal] = React.useState(false);
  const [sort, setSort] = React.useState(sortIS);
  const [refresh, setRefresh] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);

  const { addToast } = useToasts();
  const privileges = getPrivileges("super_agent");

  React.useEffect(() => {
    // fetch mobilemoney operator
    // fetchSubs();
    fetchBank();
    fetchMMO();
  }, []);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if ((searchText && searchText.length >= 3) || searchText.length === 0) {
        fetchSubs();
      }
    }
  }, [searchText]);

  React.useEffect(() => {
    fetchSubs();
  }, [currentPage]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        fetchSubs();
      }
    }
  }, [sort]);

  React.useEffect(() => {
    if (refresh && isMounted) {
      setSearchText("");
      setSort(sortIS);
      if (currentPage === 0) {
        fetchSubs();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    // fetchSubs();
  };

  const getSearch = (val) => {
    setSearchText(val);
    // if (searchText.length == 0) {
    //   fetchSubs()
    // }
  };

  const showSubs = (obj) => {
    setSubsObj(obj);
    setView(true);
  };

  const closeViewModal = () => {
    setView(false);
    setSubsObj(null);
  };

  const closeEditModal = () => {
    // setEditObj(null);
    setOpenEditModal(false);
    fetchSubs();
  };

  const openDelete = (mmo) => {
    setDeleteObj(mmo);
    setOpenDelModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteObj(null);
    setOpenDelModal(false);
  };

  const fetchSubs = async () => {
    fetchSubsHandle();
  };

  const fetchSubsHandle = async () => {
    let errorMsg = "";
    setLoading(true);
    let data = {
      PageNumber: Number(currentPage + 1),
      PerPage: pageSize,
    };

    if (searchText && searchText.length >= 3) {
      data["SearchText"] = searchText;
    }

    if (sort.sortName === "1") {
      data["SortingOn"] = "Name";
      data["IsDescending"] = false;
    } else if (sort.sortName === "2") {
      data["SortingOn"] = "Name";
      data["IsDescending"] = true;
    } else if (sort.sortEmail === "1") {
      data["SortingOn"] = "Email";
      data["IsDescending"] = false;
    } else if (sort.sortEmail === "2") {
      data["SortingOn"] = "Email";
      data["IsDescending"] = true;
    } else if (sort.sortPhone === "1") {
      data["SortingOn"] = "SubscriberUid";
      data["IsDescending"] = false;
    } else if (sort.sortName === "2") {
      data["SortingOn"] = "SubscriberUid";
      data["IsDescending"] = true;
    }
    postMethod("/api/Agent/AllSuperAgent", data, "post")
      .then((res) => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result.list || !res.data.result.list.length) {
              res.data.result.list = [];
            }
            setSubsList(res.data.result.list);
            setFilteredList(res.data.result.list);
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            // let pc = res.data.result.totalRecords;
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed("Internal Server Error");
      });
  };

  const fetchSupAgentById = (id) => {
    let errorMsg = "";
    postMethod("/api/Agent/EditSuperAgent/" + id, {}, "post")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setEditObj(res.data.result);
            setOpenEditModal(true);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            // setSError(errorMsg);
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            // setSError(errorMsg);
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          // setSError(errorMsg);
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        // setSError();
        notifyFailed("Internal Server Error");
      });
  };

  const deleteSub = () => {
    let errorMsg = "";
    setBlockLoading(true);
    let url = "/api/User/block/" + deleteObj.userProfileId;
    postMethod(url, {}, "post")
      .then((res) => {
        setBlockLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            let text = deleteObj.isBlocked
              ? "Super Agent unblocked successfully"
              : "Super Agent blocked successfully";
            notifySuccess(text);
            closeDeleteModal();
            fetchSubs();
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setBlockLoading(false);
        errorMsg = "Internal Server Error";
        notifyFailed(errorMsg);
      });
  };

  const fetchBank = async () => {
    let errorMsg = "";
    getMethod("/api/bankProfile/ddllist?AccountType=Collection", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setBanks(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
      });
  };

  const fetchMMO = async () => {
    let errorMsg = "";
    getMethod("/api/MobileMoney/ddllist", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setMMO(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
      });
  };

  const closeVerifyModal = () => {
    setVerifyObj(null);
    setOpenVerifyModal(false);
  };

  const verifySub = () => {
    let errorMsg = "";
    setSubsLoading(true);
    let url = "/api/subscriber/adminverify/" + verifyObj.userProfileId;
    postMethod(url, {}, "post")
      .then((res) => {
        setSubsLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            notifySuccess("Verified Successfuly");
            closeVerifyModal();
            fetchSubs();
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setSubsLoading(false);
        errorMsg = "Internal Server Error";
        notifyFailed(errorMsg);
      });
  };

  const closeVerifyBankModal = () => {
    setVerifyBankObj(null);
    setOpenVerifyBankModal(false);
  };

  const verifySubsBank = () => {
    let errorMsg = "";
    setVerifyBankLoading(true);
    let url = `/api/User/subscriberbankverify`;
    let data = {
      UserId: verifyBankObj.userProfileId,
      Verify: true,
    };
    postMethod(url, data, "post")
      .then((res) => {
        setVerifyBankLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            notifySuccess("Verified Successfuly");
            closeVerifyBankModal();
            fetchSubs();
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setVerifyBankLoading(false);
        errorMsg = "Internal Server Error";
        notifyFailed(errorMsg);
      });
  };

  const reloadList = () => {
    setSearchText("");
    setRefresh(true);
    fetchBank();
    fetchMMO();
  };

  const getAmt = (amt) => {
    if (amt) {
      return amt.toLocaleString();
    } else {
      return 0;
    }
  };

  const getBlockText = () => {
    let str = "block";
    if (deleteObj.isBlocked) {
      str = "unblock";
    }

    return `Do you want to ${str} ${deleteObj.firstName} ${deleteObj.lastName} ?`;
  };

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue === "0") {
      newValue = "1";
    } else if (fieldValue === "1") {
      newValue = "2";
    } else if (fieldValue === "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  };

  const getSortCls = (fieldName) => {
    if (fieldName === "0") {
      return "fas fa-sort fa-lg";
    } else if (fieldName === "1") {
      return "fas fa-sort-up fa-lg";
    } else if (fieldName === "2") {
      return "fas fa-sort-down fa-lg";
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col" style={loading ? { pointerEvents: "none" } : {}}>
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <Form
                  className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup className="mb-0 my-4 navbar-search">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="search-cls"
                        placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  &nbsp;&nbsp;
                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh"
                  >
                    <i className="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>
                </Form>
              </CardHeader>
              {loading && <FullPageLoader label={"Fetching..."} />}

              <Modal isOpen={view} style={{ width: "70vw", maxWidth: "70vw" }}>
                <ModalBody style={{ padding: 0 }}>
                  <ViewSuperAgent
                    closeModal={closeViewModal}
                    {...subsObj}
                    bankList={banks}
                    mobileMoneyList={mobileMoney}
                  />
                </ModalBody>
              </Modal>

              <Modal
                isOpen={openEdit}
                style={{ width: "70vw", maxWidth: "70vw" }}
              >
                <ModalBody style={{ padding: 0 }}>
                  <EditSuperAgent
                    closeModal={closeEditModal}
                    {...editObj}
                    bankList={banks}
                    mobileMoneyList={mobileMoney}
                  />
                </ModalBody>
              </Modal>

              <Modal isOpen={openDelModal} className="modal-sm">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    {deleteObj && deleteObj.isBlocked
                      ? "Unblock Subscriber"
                      : "Block Subscriber"}
                  </h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {deleteObj && deleteObj.firstName ? (
                      <div>
                        <p className="text-left">{getBlockText()}</p>
                        <div className="text-right">
                          <Button
                            color="primary"
                            onClick={deleteSub}
                            disabled={blockLoading}
                          >
                            Yes{" "}
                            {blockLoading && (
                              <i className="fa fa-spinner fa-spin"></i>
                            )}
                          </Button>{" "}
                          <Button
                            color="secondary"
                            onClick={closeDeleteModal}
                            disabled={blockLoading}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <> </>
                    )}
                  </div>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={openVerifyModal}
                style={{ minWidth: "25vw", width: "25vw" }}
              >
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">Verify Subscriber</h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {verifyObj && verifyObj.firstName ? (
                      <div>
                        <p className="text-left">
                          Do you want to verify {verifyObj.firstName}{" "}
                          {verifyObj.lastName} ?
                        </p>
                        <div className="text-right">
                          <Button
                            color="primary"
                            onClick={verifySub}
                            disabled={subsLoading}
                          >
                            Yes{" "}
                            {subsLoading && (
                              <i className="fa fa-spinner fa-spin"></i>
                            )}
                          </Button>{" "}
                          <Button
                            color="secondary"
                            onClick={closeVerifyModal}
                            disabled={subsLoading}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <> </>
                    )}
                  </div>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={openVerifyBankModal}
                style={{ minWidth: "30vw", width: "30vw" }}
              >
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">Verify Bank</h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {verifyBankObj && verifyBankObj.firstName ? (
                      <div>
                        <p className="text-left">
                          Do you want to verify bank of{" "}
                          {verifyBankObj.firstName} {verifyBankObj.lastName} ?
                        </p>

                        <Row className="mb-3">
                          <Col>
                            <strong>Bank Details</strong>
                            <div className="mt-2">
                              <div>
                                <small>
                                  Bank Name : {verifyBankObj.bankName}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  BBAN : {verifyBankObj.bban}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Bank Linked Mobile Number :{" "}
                                  {`(${verifyBankObj.mobileCode}) - ${verifyBankObj.mobileNumber}`}
                                </small>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="text-right mt-4">
                          <Button
                            color="primary"
                            onClick={verifySubsBank}
                            disabled={verifyBankLoading}
                          >
                            Yes{" "}
                            {verifyBankLoading && (
                              <i className="fa fa-spinner fa-spin"></i>
                            )}
                          </Button>{" "}
                          <Button
                            color="secondary"
                            onClick={closeVerifyBankModal}
                            disabled={verifyBankLoading}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <> </>
                    )}
                  </div>
                </ModalBody>
              </Modal>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      scope="col"
                      onClick={() => sortHandle(sort.sortName, "sortName")}
                    >
                      Name
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.sortName)}
                          onClick={() => sortHandle(sort.sortName, "sortName")}
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      onClick={() => sortHandle(sort.sortEmail, "sortEmail")}
                    >
                      Email
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.sortEmail)}
                          onClick={() =>
                            sortHandle(sort.sortEmail, "sortEmail")
                          }
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      onClick={() => sortHandle(sort.sortPhone, "sortPhone")}
                    >
                      Phone
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.sortPhone)}
                          onClick={() =>
                            sortHandle(sort.sortPhone, "sortPhone")
                          }
                        />
                      </button>
                    </th>
                    {/* <th scope="col">BBAN</th> */}
                    <th scope="col">Voucher Balance</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredList.length > 0 &&
                    filteredList.map((mmo, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">
                            {mmo.firstName} {mmo.lastName}
                          </th>
                          <td>{mmo.email}</td>
                          <td>
                            ({mmo.mobileCode})-
                            {mmo.mobileNumber}
                          </td>
                          {/* <td>{mmo.bban}</td> */}
                          <td className="text-right">
                            {getAmt(mmo.balanceAmount)}
                          </td>
                          <td>
                            {privileges.view && (
                              <Button
                                color="primary"
                                onClick={() => showSubs(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="View"
                              >
                                <i className="fas fa-eye fa-lg" style={iconCss}></i>
                              </Button>
                            )}

                            {privileges.edit && (
                              <Button
                                color="primary"
                                onClick={() => fetchSupAgentById(mmo.id)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Edit"
                              >
                                <i
                                  className="fas fa-edit fa-lg"
                                  style={iconCss}
                                ></i>
                              </Button>
                            )}

                            {!mmo.isBlocked && privileges.block && (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Block"
                              >
                                <i
                                  className="fa fa-eye-slash fa-lg"
                                  style={iconCss}
                                ></i>
                              </Button>
                            )}

                            {mmo.isBlocked && privileges.unblock && (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Unblock"
                              >
                                <i
                                  className="fa fa-eye-slash fa-lg"
                                  style={{ color: "#FF0000" }}
                                ></i>
                              </Button>
                            )}

                            {!mmo.isBankVerified && (
                              <Button
                                color="primary"
                                // onClick={() => openVerify(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Bank not verified"
                              >
                                <i
                                  className="fas fa-exclamation fa-lg"
                                  style={{ color: "#FF0000" }}
                                ></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {!loading && !filteredList.length && <NoRecordFound />}
                {filteredList.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>Total Records : {totalRec}</strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6">
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage}
                          />
                        </div>
                      </nav>
                    </Col>
                  </Row>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SuperAgentList;
