/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col,
} from "reactstrap";

const AdminNavbar = () => {
  return (
    <>
      <Navbar className=" navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4 d-flex align-items-center justify-content-center">
          <NavbarBrand to="/" tag={Link}>
            {/* <img
              alt="..."
              src={
                require("../../assets/img/brand/voucher-white.png").default
              }
              style={{width: '160px', height: '40px'}}
            /> */}
            <img
              alt="..."
              src={require("../../assets/img/brand/lapologo.PNG").default}
              style={{ width: "140px", objectFit: "contain", height: "140px" }}
            />
          </NavbarBrand>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
