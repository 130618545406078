
import React, { useEffect } from "react";
import { useToasts } from 'react-toast-notifications';
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { postMethod } from "services/httpServices";
import { getAmt } from "services/util";
import { bankCommMTDChartData,  bankCT_MTD } from "variables/charts";
import { daysInMonth, daysInArray } from "services/util";

const BankHeader = (props) => {

  const [totalSubs, setSubs] = React.useState(0);
  const [verifiedSubs, setVerifiedSubs] = React.useState(0);
  const [unVerifiedSubs, setUnverifiedSubs] = React.useState(0);
  const [totalCollectn, setCollectn] = React.useState(0);
  const [cardLoading, setCardLoading] = React.useState(false);

  const { addToast } = useToasts();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(()=> {
    props.showBankCommLoader(true);
    setBankCommMonth();
  }, [props.bankCommMonth]);

  useEffect(()=> {
    props.showBankCommLoader(true);
    setBankCommMonth();
  }, [props.bankCommYear]);

  useEffect(()=> {
    props.showBankCTLoader(true);
    setBankMTDCt();
  }, [props.bankCTMonth]);

  useEffect(()=> {
    props.showBankCTLoader(true);
    setBankMTDCt();
  }, [props.bankCTYear]);


  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }


  const fetch = () => {
    let errorMsg = '';
    setCardLoading(true);
    postMethod('/api/dashboard/Bank', {}, 'post')
      .then(res => {
        setCardLoading(false);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            if (res.data.result.hasOwnProperty('totalSubscriber')) {
              setSubs(res.data.result.totalSubscriber);
            }
            if (res.data.result.hasOwnProperty('totalCollection')) {
              setCollectn(res.data.result.totalCollection);
            }
            if (res.data.result.hasOwnProperty('verifiedSubscriber')) {
              setVerifiedSubs(res.data.result.verifiedSubscriber);
            }
            if (res.data.result.hasOwnProperty('unVerifiedSubscriber')) {
              setUnverifiedSubs(res.data.result.unVerifiedSubscriber);
            }
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching dashboard items';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setCardLoading(false);
        notifyFailed('Internal Server Error');
      });
  }

  const setBankCommMonth = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.bankCommYear),
      'Month': Number(props.bankCommMonth) + 1
    };
    postMethod('/api/dashboard/bank/MonthlyCommission/', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success
          let month = Number(props.bankCommMonth);
          let year = Number(props.bankCommYear);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let valArr = [];
          let totalComm = 0;

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('commissionAmount')) {
              valArr.push(Number(val.commissionAmount));
              totalComm = totalComm + Number(val.commissionAmount);
            } else {
              valArr.push(0);
            }
          }
          let mtdChart = bankCommMTDChartData({
            daysArr: arr, 
            dataArr: valArr,
            totalComm: totalComm
          });
          props.showBankCommMTD(mtdChart);
          props.showBankCommLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showBankCommLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showBankCommLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showBankCommLoader(false);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
      props.showBankCommLoader(false);
    });
  }


  const setBankMTDCt = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.bankCTYear),
      'Month': Number(props.bankCTMonth) + 1
    };
    postMethod('/api/dashboard/bank/MonthlyCustomerTransfers/', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success
          let month = Number(props.bankCTMonth);
          let year = Number(props.bankCTYear);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let topUpArr = []; let totalTopUp = 0;
          let paymentArr = []; let totalPayment = 0;

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('topUp')) {
              topUpArr.push(Number(val.topUp));
              totalTopUp = totalTopUp + Number(val.topUp)
            } else {
              topUpArr.push(0);
            }
            if (val && val.hasOwnProperty('payment')) {
              paymentArr.push(Number(val.payment));
              totalPayment = totalPayment + Number(val.payment);
            } else {
              paymentArr.push(0);
            }
          }
          let mtdChart = bankCT_MTD({
            daysArr: arr, 
            topUpArr: topUpArr,
            paymentArr: paymentArr,
            totalTopUp,
            totalPayment
          });
          props.showBankCTMTD(mtdChart);
          props.showBankCTLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showBankCTLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showBankCTLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showBankCTLoader(false);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
      props.showBankCTLoader(false);
    });
  }


  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col xl="3" md="6" lg="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Collection</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalCollectn)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>
            
              <Col xl="3" md="6" lg="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Subscriber</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-user-friends"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalSubs)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>
            

              <Col xl="3" md="6" lg="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Verified Subscriber</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i class="fas fa-user-check"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(verifiedSubs)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>
            

              <Col xl="3" md="6" lg="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>UnVerified Subscriber</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i class="fas fa-user-times"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(unVerifiedSubs)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>
            
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BankHeader;
