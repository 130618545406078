import { call, put, takeLatest } from 'redux-saga/effects';
import { getLoginType, getMethod, getUserId, postMethod } from '../../services/httpServices';
import { ADD_VOUCHER, FETCH_MERCHANT, FETCH_PAYABLE_AMT, FETCH_SUBSCRIBER_INFO, SET_SUBSCRIBER_INFO } from './constant';
import { 
  fetchSubs, setSubs,
  setError, addVoucherFailed,
  addVoucherSuccess, 
  resetVoucher,
  setMerchant,
  fetchMerchantError,
  resetMerchant,
  setPayableAmt,
  fetchPayableAmtErro,
  resetPayableAmt
} from './action';

export function* fetchSubscriberInfo() {
  let errorMsg = null;
  let userId = getUserId();
  let loginType = getLoginType()
  let url = loginType === "Master Agent" ? '/api/Agent/GetMasterAgentById/' : '/api/Subscriber/GetByUserId/';
  try {
    const res = yield postMethod(url + userId, {}, 'post');
    if (res.data.responseCode === "200") {
      // Success
      yield put(setSubs(res.data.result));
    } else if (res.data.responseCode === "400") {
      // Error
      errorMsg = res.data.responseMessage || 'Error in fetching subscriber info';
      yield put(setError(errorMsg));
    } else {
      // Unknown Error
      errorMsg = 'Unknown Error in fetching subscriber info';
      yield put(setError(errorMsg));
    }
  } catch (err) {
    errorMsg = 'Internal Server Error';
    yield put(setError(errorMsg));
  }
}

export function* addVoucher(obj) {
  let errorMsg = null;
  let data = obj.data;
  try {
    const res = yield postMethod('/api/Transactions/Save' , data , 'post');
    if (res.data.responseCode === "200") {
      // Success
      yield put(addVoucherSuccess(res.data.result));
      yield put(resetVoucher());
    } else if (res.data.responseCode === "400") {
      // Error
      errorMsg = res.data.responseMessage || 'Error in fetching subscriber info';
      yield put(addVoucherFailed(errorMsg));
      yield put(resetVoucher());
    } else {
      // Unknown Error
      errorMsg = 'Unknown Error in fetching subscriber info';
      yield put(addVoucherFailed(errorMsg));
      yield put(resetVoucher());
    }
  } catch (err) {
    errorMsg = 'Internal Server Error';
    yield put(addVoucherFailed(errorMsg));
    yield put(resetVoucher());
  }
}

export function* fetchMerchantInfo() {
  let errorMsg = null;
  try {
    const res = yield getMethod('/api/MerchantProfile/all', 'get');
    if (res.data.responseCode === "200") {
      // Success
      yield put(setMerchant(res.data.result));
    } else if (res.data.responseCode === "400") {
      // Error
      errorMsg = res.data.responseMessage || 'Error in fetching merchant info';
      yield put(fetchMerchantError(errorMsg));
      yield put(resetMerchant());
    } else {
      // Unknown Error
      errorMsg = 'Unknown Error in fetching subscriber info';
      yield put(fetchMerchantError(errorMsg));
      yield put(resetMerchant());
    }
  } catch (err) {
    errorMsg = 'Internal Server Error';
    yield put(fetchMerchantError(errorMsg));
    yield put(resetMerchant());
  }
}

export function* fetchPayableAmount(obj) {
  let errorMsg = null;
  let data = obj.data;
  var errObj = {};
  try {
    const res = yield postMethod('/api/Transactions/validateproduct', data, 'post');
    if (res.data.responseCode === "200") {
      // Success
      yield put(setPayableAmt(res.data.result));
      yield put(resetPayableAmt());
    } else if (
      res.data.responseCode === "400" ||
      res.data.responseCode === "500"
    ) {
      if (res.data && res.data.responseMessage) {
        errorMsg = res.data.responseMessage;
      } else {
        errorMsg = 'Error in validating';
      }
      yield put(fetchPayableAmtErro(errorMsg));
      yield put(resetPayableAmt());
    } else {
      // Unknown Error
      errorMsg = 'Unknown Error in validating';
      yield put(fetchPayableAmtErro(errorMsg));
      yield put(resetPayableAmt());
    }
  } catch (err) {
    errorMsg = 'Internal Server Error';
    yield put(fetchPayableAmtErro(errorMsg));
    yield put(resetPayableAmt());
  }
}

export default function* subsSaga() {
  yield takeLatest(FETCH_SUBSCRIBER_INFO, fetchSubscriberInfo);
  yield takeLatest(ADD_VOUCHER, addVoucher);
  yield takeLatest(FETCH_MERCHANT, fetchMerchantInfo);
  yield takeLatest(FETCH_PAYABLE_AMT, fetchPayableAmount);
}
