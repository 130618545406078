
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { useToasts } from 'react-toast-notifications';
import { checkSpace, getMethod, postMethod } from '../../services/httpServices';
import { AUTH_MODE, AMOUNT_HINT, MOBILE_ONLY_COUNTRY } from 'appConfig';
import { checkBankAccNo } from 'services/util';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { checkBbanValidation } from "services/util";

const MODES = AUTH_MODE;
const HINT = AMOUNT_HINT;

const codes = [23225, 23230, 23231, 23232,
  23233, 23234, 23244, 23275, 23276, 23277,
  23278, 23279, 23280, 23288, 23299];

const mobileMoney = [];

const banks = [];
const initialValue = {};

const EditSubscriber = (props) => {
  initialValue.firstName = props.firstName;
  initialValue.lastName = props.lastName;
  initialValue.mobileNumber = props.mobileNumber;
  initialValue.mobileCode = props.mobileCode;
  initialValue.mmoId = props.mmoId;
  initialValue.bankProfileId = props.bankProfileId;
  initialValue.mobileMoneyAccNo = props.mobileMoneyAccNo;
  initialValue.bban = props.bban === "null" ? "" : props.bban;
  initialValue.bbanLinkMobileCode = props.bbanLinkMobileCode;
  initialValue.bbanLinkMobileNumber = props?.bbanLinkMobileNumber === null ? props.mobileNumber : props?.bbanLinkMobileNumber;
  initialValue.id = props.id;
  initialValue.bankList = props.bankList;
  initialValue.mobileMoneyList = props.mobileMoneyList;
  initialValue.email = props.email;
  initialValue.mmoAccountNumber = props.mmoAccountNumber;
  initialValue.authMode = props.authFactorType ? props.authFactorType : "";
  initialValue.oneFAAmt = props.excidedPaymentAmount ? props.excidedPaymentAmount : 0;
  initialValue.subscriberType = props.subscriberType ? props.subscriberType : '';
  initialValue.organizationName = props.organizationName ? props.organizationName : '';
  initialValue.bankAccNo = props.bankAccountNumber ? props.bankAccountNumber : '';

  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const mobWithCC = values.mobileCode + values.mobileNumber;
  const bankMobWithCC = values.bbanLinkMobileCode + values?.bbanLinkMobileNumber;
  const { addToast } = useToasts();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      if (name === 'phone') {
        if (checkMobValidation(value)) {
          changeState({ ...values, [name]: value });
        }
      } else if (name === 'bban') {
        if (checkBbanValidation(value)) {
          changeState({ ...values, [name]: value });
        }
      } else if (name === 'mmoAccountNumber') {
        if (checkMobileMoneyAccNo(value)) {
          changeState({ ...values, [name]: value });
        }
      } else if (name === 'bbanLinkMobileNumber') {
        if (checkMobValidation(value)) {
          changeState({ ...values, [name]: value });
        }
      } else if (name === 'oneFAAmt') {
        if (checkDigitOnly(value)) {
          changeState({ ...values, [name]: value});
        }
      } else if (name === 'bankAccNo') {
        // if (checkBankAccNo(value)) {
          changeState({ ...values, [name]: value });
        // }
      } else {
        changeState({ ...values, [name]: value });
      }
    }
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      changeState({...values, bankMobWithCC: value, bbanLinkMobileCode: data.dialCode,
        bbanLinkMobileNumber: value.substring(data.dialCode.length)
      });
    }
    return;
  }

  const changeState = (obj) => {
    setValues(obj);
  };

  const checkMobileMoneyAccNo = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 11) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 6) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const closeModal = () => {
    props.closeModal();
  }

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const validate = (inputs) => {
    let emailRegEx = /\S+@\S+\.\S+/
    let errors = {};
    if (inputs.mobileNumber) {
      inputs.mobileNumber = inputs.mobileNumber.toString();
    }
    if (inputs.bban) {
      inputs.bban = inputs.bban.toString();
    }
    if (inputs.bbanLinkMobileNumber) {
      inputs.bbanLinkMobileNumber = inputs.bbanLinkMobileNumber.toString();
    }
    if (inputs.subscriberType == 'Individual') {
      if (!inputs.firstName) {
        errors.firstName = 'First Name is required';
      }
      if (!inputs.lastName) {
        errors.lastName = "Last Name is required";
      }
    } else if (inputs.subscriberType == 'Organisation') {
      if (!inputs.organizationName) {
        errors.organizationName = 'Organisation Name is Required';
      }
    }
    
    if (!inputs.mobileCode) {
      errors.mobileCode = 'Please select mobile code';
    }
    if (!inputs.mobileNumber) {
      errors.mobileNumber = 'Phone Number is Required';
    } 
    // else if (inputs.mobileNumber.length !== 6) {
    //   errors.mobileNumber = 'Phone Number should have 6 digits';
    // }
    if (inputs.email && !emailRegEx.test(inputs.email)) {
      errors.email = 'Enter valid email';
    }
    if (inputs.mmoId) {
      if (!inputs.mmoAccountNumber) {
        errors.mmoAccountNumber = 'Mobile Money account number is required';
      } 
      // else if (inputs.mmoAccountNumber.length !== 11) {
      //   errors.mmoAccountNumber = 'Mobile Money account number should have 11 digits';
      // }
    }
    if (inputs.mmoAccountNumber) {
      // if (!inputs.mmoId) {
      //   errors.mmoId = 'Mobile Money is required';
      // }
      // if (inputs.mmoAccountNumber.length !== 11) {
      //   errors.mmoAccountNumber = 'Mobile Money account number should have 11 digits';
      // }
    }
    // if (!inputs.mmoId) {
    //   errors.mmoId = 'Mobile Money is required';
    // }
    // if (!inputs.mmoAccountNumber) {
    //   errors.mmoAccountNumber = 'Mobile Money account number is required';
    // } else if (inputs.mmoAccountNumber.length !== 11) {
    //   errors.mmoAccountNumber = 'Mobile Money account number should have 11 digits';
    // }
    if (!inputs.bankProfileId) {
      errors.bankProfileId = 'Bank is required';
    }
    // if (!inputs.bban) {
    //   errors.bban = 'BBAN is required';
    // } else if (inputs.bban.length < 8 || inputs.bban.length > 18) {
    //   errors.bban = 'BBAN should have 8 to 18 digits';
    // }
    if (inputs?.bban?.length !== 0 && (inputs?.bban?.length < 8 || inputs?.bban?.length > 18)) {
      errors.bban = 'BBAN should have 8 to 18 digits';
    }
    // if (!inputs.bbanLinkMobileCode) {
    //   errors.bbanLinkMobileCode = 'Mobile Code is required';
    // }
    // if (!inputs.bbanLinkMobileNumber) {
    //   errors.bbanLinkMobileNumber = 'Phone number is required';
    // } else if (inputs.bbanLinkMobileNumber.length < 6) {
    //   errors.bbanLinkMobileNumber = 'Phone Number should have  minimum 6 digits';
    // }

    if (!inputs.authMode) {
      errors.authMode = 'Please select authentication mode';
    }
    if (!inputs.bankAccNo) {
      errors.bankAccNo = 'Please enter bank account number';
    } 
    // else if (inputs.bankAccNo.length < 8 || inputs.bankAccNo.length > 18) {
    //   errors.bankAccNo = 'Please enter 8 to 18 digit Bank Account Number';
    // }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      var data = new FormData();
      if (values.subscriberType == 'Individual') {
        data.append('FirstName', values.firstName);
        data.append('LastName', values.lastName);
      } else if (values.subscriberType == 'Organisation') {
        data.append('OrganizationName', values.organizationName);
      }
      data.append('SubscriberType', values.subscriberType);
      data.append('MobileCode', values.mobileCode);
      data.append('MobileNumber', values.mobileNumber);
      data.append('Email', values.email ? values.email : '');
      data.append('BBAN', values.bban);
      data.append('BBANLinkMobileNumber', values.bbanLinkMobileNumber);
      data.append('BBANLinkMobileCode', values.bbanLinkMobileCode);
      data.append('AccountCreatedBy', 'Admin');
      data.append('Password', values.password);
      data.append('BankProfileId', values.bankProfileId);
      data.append('IsVerifiedByAdmin', true);
      data.append('Id', values.id);
      if (values.mmoId) {
        data.append('MMOId', values.mmoId);
      } else {
        data.append('MMOId', 0);
      }
      if (values.mmoAccountNumber) {
        data.append('MMOAccountNumber', values.mmoAccountNumber)
      } else {
        data.append('MMOAccountNumber', "")
      }
      data.append('AuthFactorType', values.authMode);
      data.append('ExcidedPaymentAmount', 
      values.authMode == "1FA" ? values.oneFAAmt : 0);
      data.append('BankAccountNumber', values.bankAccNo);
      if(values.bankProfileId > 0 ) {
        data.append('isBankInfoAvailable', true)
      }
      let errorMsg = '';
      setLoading(true);
      postMethod('/api/Subscriber/update', data, 'post')
      .then(res => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            notifySuccess('Updated Successfuly');
            closeModal();
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || 'Error while saving';
            notifyFailed(errorMsg);
            // setSError(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in saving';
            notifyFailed(errorMsg);
            // setSError(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
          // setSError(errorMsg);
        }
      }).catch(err => {
        setLoading(false);
        errorMsg = 'Internal Server Error';
        notifyFailed(errorMsg);
      });
    }
  }

  const checkDigitOnly = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  }


  return (
    <>
      {/* Page content */}
      <Container className="p-0">
        <Row >
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Subscriber Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: 'pointer', fontSize: '25px' }}
                    >
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <h6 className="heading-small text-muted mb-4">
                    Profile Details
                  </h6>
                  <div className="pl-lg-4">
                  <Row>
                      {
                        values.subscriberType == 'Individual' &&
                        <>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-fname"
                              >
                                First Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-fname"
                                placeholder="First Name"
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                // disabled={true}
                                onChange={handleInputChange}
                              />
                              {errors.firstName && <div className="text-left text-danger">
                                <small>* {errors.firstName}</small>
                              </div>}
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-fname"
                              >
                                Last Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-lname"
                                placeholder="Last Name"
                                type="text"
                                name="lastName"
                                value={values.lastName}
                                // disabled={true}
                                onChange={handleInputChange}
                              />
                              {errors.lastName && <div className="text-left text-danger">
                                <small>* {errors.lastName}</small>
                              </div>}
                            </FormGroup>
                          </Col>

                        </>
                      }

                      {values.subscriberType == 'Organisation' &&
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-oname"
                            >
                              Organisation Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-oname"
                              placeholder="Organisation Name"
                              type="text"
                              name="organizationName"
                              value={values.organizationName}
                              // disabled={true}
                              onChange={handleInputChange}
                            />
                            {errors.organizationName && <div className="text-left text-danger">
                              <small>* {errors.organizationName}</small>
                            </div>}
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                    <Row>
                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="input-mobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="input-mobileCode"
                            className="form-control-alternative"
                            name="mobileCode"
                            value={values.mobileCode}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            placeholder="Phone Number"
                            type="text"
                            name="mobileNumber"
                            value={values.mobileNumber}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.mobileNumber && <div className="text-left text-danger">
                            <small>* {errors.mobileNumber}</small>
                          </div>}
                        </FormGroup>
                      </Col> */}

                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={'sl'}
                            value={mobWithCC}
                            //onlyCountries={MOBILE_ONLY_COUNTRY}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="email"
                            name="email"
                            value={values.email}
                            // disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.email && <div className="text-left text-danger">
                            <small>* {errors.email}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                    </Row>

                    <Row>


                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-authmode"
                            className="form-control-label">
                            Authentication Mode
                            </label>
                          <Input
                            type="select"
                            id="input-authmode"
                            className="form-control-alternative"
                            name="authMode"
                            value={values.authMode}
                            onChange={handleInputChange}
                          >
                            <option key="">----Select Mode-----</option>
                            {MODES.map(c => <option key={c}>{c.value}</option>)}
                          </Input>
                          {errors.authMode && <div className="text-left text-danger">
                            <small>* {errors.authMode}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      {values.authMode == "1FA" && <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-1FA"
                          >
                            Enter amount for using 1FA
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-1FA"
                            placeholder="Amount"
                            type="text"
                            name="oneFAAmt"
                            value={values.oneFAAmt}
                            onChange={handleInputChange}
                          />

                          <div className="text-left">
                            <i>
                              <small>* {HINT}</small>
                            </i>
                          </div>
                          {errors.oneFAAmt && <div className="text-left text-danger">
                            <small>* {errors.oneFAAmt}</small>
                          </div>}

                        </FormGroup>
                      </Col>}
                      
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Mobile Money Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-mobilemoney"
                            className="form-control-label">
                            Mobile Money (optional)
                          </label>
                          <Input
                            type="select"
                            id="input-mobilemoney"
                            className="form-control-alternative"
                            name="mmoId"
                            value={values.mmoId}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">-----Select MMO-----</option>
                            {values.mobileMoneyList && values.mobileMoneyList.map(c => 
                            <option key={c.key} value={c.key}>{c.value}</option>)}
                          </Input>
                          {errors.mmoId && <div className="text-left text-danger">
                            <small>* {errors.mmoId}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-mmaccno"
                          >
                            MM Account Number (optional)
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-mmaccno"
                            placeholder="Acount Number (optional)"
                            type="text"
                            name="mmoAccountNumber"
                            value={values.mmoAccountNumber}
                            onChange={handleInputChange}
                          />
                          {errors.mmoAccountNumber && <div className="text-left text-danger">
                            <small>* {errors.mmoAccountNumber}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Bank Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-bank"
                            className="form-control-label">
                            Bank
                          </label>
                          <Input
                            type="select"
                            id="input-bank"
                            className="form-control-alternative"
                            name="bankProfileId"
                            value={values.bankProfileId}
                            onChange={handleInputChange}
                          >
                            {values.bankList && values.bankList.map(c => 
                              <option key={c.key} value={c.key}>{c.value}</option>
                           )}
                          </Input>
                          {errors.bankProfileId && <div className="text-left text-danger">
                            <small>* {errors.bankProfileId}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bban"
                          >
                            BBAN Number (optional)
                        </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bban"
                            placeholder="BBAN Number (optional)"
                            type="text"
                            name="bban"
                            value={values.bban}
                            onChange={handleInputChange}
                          />
                          {errors.bban && <div className="text-left text-danger">
                            <small>* {errors.bban}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bank-accno"
                          >
                            Bank Account Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bank-accno"
                            placeholder="Bank Account Number"
                            type="text"
                            name="bankAccNo"
                            value={values.bankAccNo}
                            onChange={handleInputChange}
                          />
                          {errors.bankAccNo && <div className="text-left text-danger">
                            <small>* {errors.bankAccNo}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="input-bbanLinkMobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="input-bbanLinkMobileCode"
                            className="form-control-alternative"
                            name="bbanLinkMobileCode"
                            value={values.bbanLinkMobileCode}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bbanLinkMobileNumber"
                          >
                            Bank Linked Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bbanLinkMobileNumber"
                            placeholder="Phone Number"
                            type="text"
                            name="bbanLinkMobileNumber"
                            value={values.bbanLinkMobileNumber}
                            onChange={handleInputChange}
                          />
                          {errors.bbanLinkMobileNumber && <div className="text-left text-danger">
                            <small>* {errors.bbanLinkMobileNumber}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                     */}
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Bank Linked Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={'sl'}
                            value={bankMobWithCC}
                            //onlyCountries={MOBILE_ONLY_COUNTRY}
                            onChange={phoneChange}
                          />
                            {errors.bbanLinkMobileNumber && <div className="text-left text-danger">
                            <small>* {errors.bbanLinkMobileNumber}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                    <Col lg-12>
                      <div>
                        <Button className="btn float-right" 
                          color="primary" type="submit"
                          disabled={loading}>
                          Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditSubscriber;
