import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

import FullPageLoader from "components/FullPageLoader/fullpageloader";
import { getUserId, postMethod } from "services/httpServices";
import { useToasts } from 'react-toast-notifications';

const VoucherWallet = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [amount, setAmount] = React.useState(10000);
  const [success, setSuccess] = React.useState(2);
  const { addToast } = useToasts();

  const getAmt = (val) => {
    let amt = Number(val);
    return amt.toLocaleString();
  }

  // setTimeout(() => {
  //   setLoading(false);
  // }, 5000);

  useEffect(()=> {
    fetchBalance();
  }, []);

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const fetchBalance = () => {
    let errorMsg = '';
    let id = getUserId();
    setLoading(true);
    postMethod('/api/Subscriber/GetByUserId/' + id, {}, 'post')
    .then(res => {
      setLoading(false);
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          setSuccess(1);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching list';
          notifyFailed(errorMsg);
          setSuccess(0);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);
          setSuccess(0);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        setSuccess(0);
      }
    }).catch(err => {
      setLoading(false);
      notifyFailed('Internal Server Error');
      setSuccess(0);
    });
  }
  return (
    <>
      <Container className="mt--5 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="4">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <h3 className="mb-0 text-center">Voucher Wallet</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-4" style={{ minHeight: '240px' }}>
                {loading && <FullPageLoader />}
                {!loading && <div>
                  <Row>
                    <Col className="text-center">
                      {success == 1 && <img
                        alt="..."
                        height="100"
                        width="100"
                        className="round-circle"
                        src={
                          require("../../assets/img/brand/right-checkmark-removebg-preview.png")
                            .default
                        }
                      />}

                      {success == 0 && <img
                        alt="..."
                        height="100"
                        width="100"
                        className="round-circle"
                        src={
                          require("../../assets/img/brand/error.png")
                            .default
                        }
                      />}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center mt-3">
                      <label>
                        {success == 1 &&<strong>
                          Balance fetch successfully
                        </strong>}

                        {success == 0 &&<strong>
                          Error in fetching voucher balance
                        </strong>}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      {success == 1 && <label>{getAmt(amount)}</label>}
                    </Col>
                  </Row>
              
                </div>}
               </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VoucherWallet;
