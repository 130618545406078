import React, { useEffect, useState } from "react";
import {
  Container, Row, Card,
  CardHeader, CardFooter, Col,
  Form, FormGroup, Input,
  InputGroup, InputGroupAddon,
  InputGroupText, Button
} from "reactstrap";
import moment from "moment";
import { useToasts } from 'react-toast-notifications';
import { postMethod } from "services/httpServices";
import { User_Log_Action_Type } from "services/util";

const UserLogs = (props) => {

  const [logType, setLogType] = React.useState(User_Log_Action_Type);
  const [logTypeFilter, setLogTypeFilter] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [minEndDate, setMinEndDate] = React.useState('');
  const [isMounted, setIsMounted] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const [submitButton, setSubmitButton] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const { addToast } = useToasts();

  /**============================== React Hooks ============================= */

  // useEffect(() => {
  //   setLogType(props.logType);
  // }, [props.logType]);

  useEffect(() => {
    if (isMounted) {
      fetchLogs();
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (isMounted) {
      fetchLogs();
    }
  }, [props.sort]);

  useEffect(() => {
    props.parentLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (
      endDate &&
      moment(new Date(endDate)).isBefore(new Date(startDate))
    ) {
      setEndDate('');
    }
  }, [startDate]);




  /**============================== File Functiond ============================= */

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const changeLogType = (val) => {
    setLogTypeFilter(val);
  }

  const getSearch = (val) => {
    setSearchText(val);
  };

  const onSubmit = () => {
    setSubmitButton(true);
    if (props.currentPage != 0) {
      props.changePage(0);
    } else {
      fetchLogs();
    }
  }

  /**============================== Form Validation ============================= */

  const validate = () => {
    var error = {};
    if (!startDate) {
      error.startDate = 'Please select start date';
    }
    if (!endDate) {
      error.endDate = 'Please select end date';
    }
    return error;
  }

  /**============================== API Calls ================================== */

  const fetchLogs = () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      let errorMsg = '';
      setLoading(true);
      let data = {
        'FromDate': moment(new Date(startDate)).format('DD/MM/YYYY'),
        'ToDate': moment(new Date(endDate)).format('DD/MM/YYYY'),
        "Status": "",
        'PageNumber': Number(props.currentPage + 1),
        'PerPage': pageSize
      }
      if (logTypeFilter && logTypeFilter != '0') {
        data['ActionType'] = logTypeFilter;
      }
      if (searchText) {
        data['SearchText'] = searchText;
      }
      if (props.sort && props.sort.sortAction == "1") {
        data['SortingOn'] = 'ActionType';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortAction == "2") {
        data['SortingOn'] = 'ActionType';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortActionBy == "1") {
        data['SortingOn'] = 'PerformBy';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortActionBy == "2") {
        data['SortingOn'] = 'PerformBy';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortLoginUserType == "1") {
        data['SortingOn'] = 'PerformByRole';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortLoginUserType == "2") {
        data['SortingOn'] = 'PerformByRole';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortUserType == "1") {
        data['SortingOn'] = 'UserType';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortUserType == "2") {
        data['SortingOn'] = 'UserType';
        data['IsDescending'] = true;
      }  else if (props.sort && props.sort.sortDate == "1") {
        data['SortingOn'] = 'CreatedAt';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortDate == "2") {
        data['SortingOn'] = 'CreatedAt';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortStatus == "1") {
        data['SortingOn'] = 'Status';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortStatus == "2") {
        data['SortingOn'] = 'Status';
        data['IsDescending'] = true;
      } 

      postMethod('/api/logs/UserLogsList', JSON.stringify(data), 'post')
      .then(res => {
        setLoading(false);
        setIsMounted(true);
        setSubmitButton(false);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200" &&
            res.data.result) {
            // Success
            if (
              !res.data.result.list ||
              !res.data.result.list.length
            ) {
              res.data.result.list = [];
            }
            props.parentCallback(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        setLoading(false);
        setIsMounted(true);
        notifyFailed('Internal Server Error');
      })
    }
  }

  return (
    <>
      &nbsp; &nbsp; &nbsp; &nbsp;

      <Row>
        <Col className={errors.startDate || errors.endDate ? 'mt-3': ''}>

          <Row>
            <Col lg="12">

              <small><strong>Start Date</strong></small>
            </Col>
          </Row>

          <Row>
            <Col lg="12">


              <FormGroup className="mb-0">

                <div class="dropdown-border">
                  <Input
                    type="date"
                    id="status"
                    className="form-control-alternative status-filter"
                    name="startDate"
                    placeholder="Start Date"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={(e) => setStartDate(e.target.value)}
                  >
                  </Input>
                </div>
              </FormGroup>


            </Col>
          </Row>

          <Row>
            <Col lg="12">

              {errors.startDate && <div className="text-left text-danger">
                <small>* {errors.startDate}</small>
              </div>}

            </Col>
          </Row>
        </Col>

        <Col className={errors.startDate || errors.endDate ? 'mt-3': ''}>

          <Row>
            <Col lg="12">

              <small><strong>End Date</strong></small>
            </Col>
          </Row>

          <Row>
            <Col lg="12">

              <FormGroup className="mb-0">

                <div class="dropdown-border">
                  <Input
                    type="date"
                    id="status"
                    className="form-control-alternative status-filter"
                    name="endDate"
                    placeholder="End Date"
                    min={startDate}
                    max={moment().format("YYYY-MM-DD")}
                    disabled={!startDate}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  >
                  </Input>
                </div>
              </FormGroup>


            </Col>
          </Row>

          <Row>
            <Col lg="12">

              {errors.endDate && <div className="text-left text-danger">
                <small>* {errors.endDate}</small>
              </div>}

            </Col>
          </Row>

        </Col>

        <Col className={errors.startDate || errors.endDate ? 'mt-3': ''}>
          <Row>
            <Col lg="12">

              <small><strong>Action Type</strong></small>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <div class="dropdown-border">
                  <Input
                    type="select"
                    id="logTypeFilter"
                    className="form-control-alternative status-filter"
                    name="logTypeFilter"
                    value={logTypeFilter}
                    onChange={(e) => changeLogType(e.target.value)}
                  >
                    <option value={0}>----Select Log Type----</option>
                    {logType.map(c =>
                      <option key={c.key} value={c.id}>{c.value}</option>
                    )}
                  </Input>
                </div>
              </FormGroup>

            </Col>
          </Row>

          <Row>
            <Col lg="12">
              {errors.logTypeFilter && <div className="text-left text-danger">
                <small>* {errors.logTypeFilter}</small>
              </div>}
            </Col>
          </Row>
        </Col>

      </Row>
      <Row className={errors.startDate || errors.endDate ? 'mt-0': 'mt-2'}>
        <Col>
          <Row>
            <Col lg="12">

              <small><strong>Search</strong></small>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="mb-0 dropdown-border">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input className="logs-search-cls" placeholder="Search"
                    type="text"
                    value={searchText}
                    onChange={(e) => getSearch(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

            </Col>
          </Row>

        </Col>

        <Col>
          <Row>
            <Col lg="12">
              <Button color="primary"
                className="mt-4"
                onClick={onSubmit}
                disabled={loading}>
                Submit &nbsp; &nbsp;
                {loading && submitButton &&<i class="fa fa-spinner fa-spin"></i>}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default UserLogs;
