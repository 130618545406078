const fields = [
  'minAmt1', 'maxAmt1', 'calcMethod1',
  'minAmt2', 'maxAmt2', 'calcMethod2',
  'minAmt3', 'maxAmt3', 'calcMethod3',
  'minAmt4', 'maxAmt4', 'calcMethod4',
  'minAmt5', 'maxAmt5', 'calcMethod5',
];

const ALL_FIELDS = [
  'minAmt1', 'maxAmt1', 'commissionAmt1', 'calcMethod1', 'commissionRate1',
  'minAmt2', 'maxAmt2', 'commissionAmt2', 'calcMethod1', 'commissionRate2',
  'minAmt3', 'maxAmt3', 'commissionAmt3', 'calcMethod1', 'commissionRate3',
  'minAmt4', 'maxAmt4', 'commissionAmt4', 'calcMethod1', 'commissionRate4',
  'minAmt5', 'maxAmt5', 'commissionAmt5', 'calcMethod1', 'commissionRate5',
];

export const CommissionType = [
  // {key: 'Merchant', value: 'Merchant Commission'},
  // {key: 'WalletTopUp', value: 'Wallet Commission'},
  {key: 'CashTopUp', value: 'Cash Top Up'},
  {key: 'BankCommission', value: 'Bank Commission'},
  // {key: 'Cashout', value: 'Cash Out'},
];

export const CalcMethod = [
  {key: '1', value: 'Zero Commission'},
  {key: '2', value: 'Flat Amount'},
  {key: '3', value: 'Flat Rate'},
  {key: '4', value: 'Table'},
  {key: '5', value: '60:40'},
]

export const checkTableField = (name) => {
  let fields = [
    'minAmt1', 'maxAmt1', 'commissionAmt1', 
    'minAmt2', 'maxAmt2', 'commissionAmt2',
    'minAmt3', 'maxAmt3', 'commissionAmt3',
    'minAmt4', 'maxAmt4', 'commissionAmt4',
    'minAmt5', 'maxAmt5', 'commissionAmt5'
  ];
  if (fields.indexOf(name) > -1) {
    return true;
  } else {
    return false;
  }
}

export const changeCalMethod = (values, method) => {
  let v = {...values};
  if (method !== '4') {     // Table
    for (let i = 0; i < ALL_FIELDS.length; i++) {
      v.table[ALL_FIELDS[i]] = '';
    }
  }

  if (method != '1') {  // Zero
    v.amount = '';
  }
  
  if (method != '2') { // Flat Amount
    v.flatamount = '';
  }

  if (method != '3') { // Flat Rate
    v.rate = '';
  }

  return v;
}

export const checkTableValues = (inputs) => {
  var valid = true;
  for (let i = 0; i < fields.length; i++) {
    if (!inputs.table[fields[i]]) {
      valid = false;
      break;
    } else {
      if (fields[i] == 'calcMethod1') {
        if (inputs.table.calcMethod1 == "2" && !inputs.table['commissionRate1']) {
          valid = false;
          break;
        }
        if (inputs.table.calcMethod1 == "1" && !inputs.table['commissionAmt1']) {
          valid = false;
          break;
        }
      } 
      if (fields[i] == 'calcMethod2') {
        if (inputs.table.calcMethod2 == "2" && !inputs.table['commissionRate2']) {
          valid = false;
          break;
        }
        if (inputs.table.calcMethod2 == "1" &&!inputs.table['commissionAmt2']) {
          valid = false;
          break;
        }
      } 
      if (fields[i] == 'calcMethod3') {
        if (inputs.table.calcMethod3 == "2" &&!inputs.table['commissionRate3']) {
          valid = false;
          break;
        }
        if (inputs.table.calcMethod3 == "1" &&!inputs.table['commissionAmt3']) {
          valid = false;
          break;
        }
      } 
      if (fields[i] == 'calcMethod4') {
        if (inputs.table.calcMethod4 == "2" && !inputs.table['commissionRate4']) {
          valid = false;
          break;
        }
        if (inputs.table.calcMethod4 == "1" &&!inputs.table['commissionAmt4']) {
          valid = false;
          break;
        }
      } 
      if (fields[i] == 'calcMethod5') {
        if (inputs.table.calcMethod5 == "2" && !inputs.table['commissionRate5']) {
          valid = false;
          break;
        }
        if (inputs.table.calcMethod5 == "1" && !inputs.table['commissionAmt5']) {
          valid = false;
          break;
        }
      } 
    }
  }
  return valid;
}

export const checkMinMax = (inputs) => {
  var valid = true;
  for (let i = 1; i < 6; i++) {
    if (Number(inputs.table[`minAmt${i}`]) > Number(inputs.table[`maxAmt${i}`])) {
      valid = false;
      break;
    } 
  } 
  return valid;
}

export const ValidateCommission = (inputs) => {
  var errors = {};

  if (!inputs.name) {
    errors.name = 'Please provide commission name';
  }
  if (!inputs.type) {
    errors.type = 'Please select commission type';
  }

  if (!inputs.method) {
    errors.method = 'Please select calculation method';
  }
  if (inputs.method == '2') {
    if (!inputs.flatamount) {
      errors.flatamount = 'Please enter flat amount';
    }
  } 
  if (inputs.method == '3') {
    if (!inputs.rate) {
      errors.rate = 'Please enter flat rate';
    }
  } 
  if (inputs.method == '4') {
    if (!checkTableValues(inputs)) {
      errors.table = 'Please provide table values';
    } else if (!checkMinMax(inputs)) {
      errors.table = `Maximum amount can't be less than Minimum amount`;
    }
  }
  return errors; 
}

export const getInitialValue = (props) => {
  const initialValue = {
    name: props.commissionName ? props.commissionName : '',
    type: props.commissionType ? props.commissionType : '',
    method: props.commissionCalculateMethod ? props.commissionCalculateMethod : '',
    amount: props.commissionCalculateMethod == 1 ? props.commissionValue : '',
    flatamount: props.commissionCalculateMethod == 2 ? props.commissionValue : '',
    rate: props.commissionCalculateMethod == 3 || props.commissionCalculateMethod == 5 ? props.commissionValue : '',
    id: props.commissionId,
    table: {
      minAmt1: '',
      maxAmt1: '',
      commissionAmt1: '',
      commissionRate1: '',
      calcMethod1: '',
      minAmt2: '',
      maxAmt2: '',
      commissionAmt2: '',
      commissionRate2: '',
      calcMethod2: '',
      minAmt3: '',
      maxAmt3: '',
      commissionAmt3: '',
      commissionRate3: '',
      calcMethod3: '',
      minAmt4: '',
      maxAmt4: '',
      commissionAmt4: '',
      commissionRate4: '',
      calcMethod4: '',
      minAmt5: '',
      maxAmt5: '',
      commissionAmt5: '',
      commissionRate5: '',
      calcMethod5: '',
      commissionTableId1: 0,
      commissionTableId2: 0,
      commissionTableId3: 0,
      commissionTableId4: 0,
      commissionTableId5: 0,
    }
  };
  if (
    props.commissionCalculateMethod == '4' &&
    props.commissionTable &&
    props.commissionTable.length
  ) {
    for (let i = 0; i < props.commissionTable.length; i++) {
      if (props.commissionTable[i].hasOwnProperty('index')) {

        let key = Number(props.commissionTable[i].index) + 1;
        initialValue.table['minAmt' + key] = props.commissionTable[i].minAmount;
        initialValue.table['maxAmt' + key] = props.commissionTable[i].maxAmount;
        initialValue.table['commissionTableId' + key] = props.commissionTable[i].commissionTableId;
        initialValue.table['calcMethod' + key] = props.commissionTable[i].calculateMethod;

        if (props.commissionTable[i].calculateMethod == '1') {
          initialValue.table['commissionAmt' + key] = props.commissionTable[i].commissionValue;
        } else if (props.commissionTable[i].calculateMethod == '2') {
          initialValue.table['commissionRate' + key] = props.commissionTable[i].commissionValue;
        }

      }
    }
  }
  return initialValue;
}

export const setCommissionValues = (values) => {
  let data = {
    'CommissionType': values.type,
    'CommissionName': values.name.trim(),
    'CommissionCalculateMethod': Number(values.method),
  }
  if (values.method == '1' || values.method == '4') {
    data['CommissionValue'] = 0
  } else if (values.method == '2') {
    data['CommissionValue'] = Number(values.flatamount);
  } else if (values.method == '3') {
    data['CommissionValue'] = Number(values.rate);
  }

  if (values.method == '4') {
    data['CommissionTable'] = [
      {
        'MinAmount': Number(values.table.minAmt1),
        'MaxAmount': Number(values.table.maxAmt1),
        'CommissionTableId': values.table.commissionTableId1, 
        'Index': 0,
        'CommissionId': values.id ? values.id : 0,
        'CalculateMethod': Number(values.table.calcMethod1),
        'CommissionValue': values.table.calcMethod1 == 1 ? 
        Number(values.table.commissionAmt1) : Number(values.table.commissionRate1)
      },
      {
        'MinAmount': Number(values.table.minAmt2),
        'MaxAmount': Number(values.table.maxAmt2),
        'CommissionTableId': values.table.commissionTableId2, 
        'Index': 1,
        'CommissionId': values.id ? values.id : 0,
        'CalculateMethod': Number(values.table.calcMethod2),
        'CommissionValue': values.table.calcMethod2 == 1 ? 
        Number(values.table.commissionAmt2) : Number(values.table.commissionRate2)
      },
      {
        'MinAmount': Number(values.table.minAmt3),
        'MaxAmount': Number(values.table.maxAmt3),
        'CommissionTableId': values.table.commissionTableId3, 
        'Index': 2,
        'CommissionId': values.id ? values.id : 0,
        'CalculateMethod': Number(values.table.calcMethod3),
        'CommissionValue': values.table.calcMethod3 == 1 ? 
        Number(values.table.commissionAmt3) : Number(values.table.commissionRate3)
      },
      {
        'MinAmount': Number(values.table.minAmt4),
        'MaxAmount': Number(values.table.maxAmt4),
        'CommissionTableId': values.table.commissionTableId4,
        'Index': 3,
        'CommissionId': values.id ? values.id : 0,
        'CalculateMethod': Number(values.table.calcMethod4),
        'CommissionValue': values.table.calcMethod4 == 1 ? 
        Number(values.table.commissionAmt4) : Number(values.table.commissionRate4)
      },
      {
        'MinAmount': Number(values.table.minAmt5),
        'MaxAmount': Number(values.table.maxAmt5),
        'CommissionTableId': values.table.commissionTableId5,
        'Index': 4,
        'CommissionId': values.id ? values.id : 0,
        'CalculateMethod': Number(values.table.calcMethod5),
        'CommissionValue': values.table.calcMethod5 == 1 ? 
        Number(values.table.commissionAmt5) : Number(values.table.commissionRate5)
      }
    ]
  }
  return data;
}

export const getCommissionTypeName = (value) => {
  let ob = CalcMethod.find(o => o.key == value);
  if (ob) {
    return ob.value
  } else {
    return value;
  }
}

export const checkTableRateField = (name) => {
  let fields = [
    'commissionRate1',
    'commissionRate2',
    'commissionRate3',
    'commissionRate4',
    'commissionRate5',
  ];
  if (fields.indexOf(name) > -1) {
    return true;
  } else {
    return false;
  }
}

export const TableCalcMethod = [
  {'key': 1, 'value': 'Flat Amount'},
  {'key': 2, 'value': 'Flat Rate'}
];

export const TableBody = [
  {'key': 'Min. Threshold', 'count': 1},
  {'key': 'Band 1 amount', 'count': 2},
  {'key': 'Band 2 amount', 'count': 3},
  {'key': 'Band 3 amount', 'count': 4},
  {'key': 'Max. Threshold', 'count': 5}
]

export const checkTableAmountTypeField = (name) => {
  let fields = [
    'calcMethod1',
    'calcMethod2',
    'calcMethod3',
    'calcMethod4',
    'calcMethod5',
  ];
  if (fields.indexOf(name) > -1) {
    return true;
  } else {
    return false;
  }
}

export const getMinValueName = (name) => {
  let str = null;
  if (name == 'maxAmt1') {
    str = 'minAmt2';
  } else if (name == 'maxAmt2') {
    str = 'minAmt3';
  } else if (name == 'maxAmt3') {
    str = 'minAmt4';
  } else if (name == 'maxAmt4') {
    str = 'minAmt5';
  }
  return str;
}

export const checkMinAmtDisable = (index) =>  {
  if (index > 0) {
    return true;
  } else {
    return false;
  }
}

export default {
  CommissionType,
  CalcMethod,
  checkTableField,
  changeCalMethod,
  checkTableValues,
  ValidateCommission,
  getInitialValue,
  setCommissionValues,
  getCommissionTypeName,
  checkTableRateField,
  checkTableAmountTypeField,
  TableCalcMethod,
  TableBody,
  getMinValueName,
  checkMinAmtDisable
}