import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { useToasts } from 'react-toast-notifications';
import {
  Container, Row, Card,
  CardHeader, CardFooter, Col,
  Form, FormGroup, Input
} from "reactstrap";
import UserLogs from "./user-logs";
import UserLogTable from "./user-logs.table";
import ReactPaginate from 'react-paginate';
import TransactionLogs from "./transaction-logs";
import TransactionLogTable from "./transaction-log-table";

const ACTION_TYPE = [
  { key: 1, value: 'User Logs' },
  { key: 2, value: 'Transaction Logs' }
];

const Logs = () => {

  const [actionType, setActionType] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [pagesCount, setPageCount] = React.useState(0);
  const [response, setResponse] = React.useState(false);
  const [sort, setSort] = React.useState({});
  const [totalRec, setTotalRec] = React.useState(0);
  const [collectionR, setCollectionR] = React.useState([]);


  const { addToast } = useToasts();


  /**======================== React Hooks ======================== */





  /**======================== File Function ======================== */

  const changeActionType = (val) => {
    setActionType(val);
    setLoading(false);
    setResponse(false);
    setResult([]);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const detailHandleCB = (resp) => {
    setResponse(true);
    setResult(resp.list);
    let pc = Math.ceil(resp.totalRecords / pageSize);
    setTotalRec(resp.totalRecords);
    // setCurrentPage(0);
    setCollectionR(resp.list);
    setPageCount(pc);
  }

  const reportLoading = (loaderValue) => {
    setLoading(loaderValue);
  }

  const changePageNo = (num) => {
    setCurrentPage(num);
  }

  const detailSummarySort = (s) => {
    setSort(s);
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }


  /**======================== Validation ======================== */







  /**======================== Api Calls ======================== */



  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <Form className="form-inline"
                  onSubmit={e => { e.preventDefault(); }}>
                  <Row>
                    <Col>
                      <Row>
                        <Col lg="12">
                          <small><strong>Logs Type</strong></small>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup>
                            <div class="dropdown-border">
                              <Input
                                type="select"
                                id="report"
                                className="form-control-alternative status-filter"
                                name="actionType"
                                value={actionType}
                                onChange={(e) => changeActionType(e.target.value)}
                              >
                                <option>----Select Logs ----</option>
                                {ACTION_TYPE.map(c =>
                                  <option key={c.key} value={c.key}>{c.value}</option>
                                )}
                              </Input>
                            </div>

                          </FormGroup>
                        </Col>

                      </Row>

                    </Col>

                  </Row>

                  {actionType === "1" &&
                    <UserLogs
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={detailHandleCB}
                      parentLoading={reportLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />

                  }

                  {actionType === "2" &&
                    <TransactionLogs 
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={detailHandleCB}
                      parentLoading={reportLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  }
                </Form>

              </CardHeader>

              {
                response && actionType === "1" &&
                <UserLogTable
                  datalist={result} currentPage={currentPage} pageSize={pageSize}
                  setParentSort={detailSummarySort} loading={loading}
                />
              }

              {
                response && actionType === "2" &&
                <TransactionLogTable 
                  datalist={result} currentPage={currentPage} pageSize={pageSize}
                  setParentSort={detailSummarySort} loading={loading}
                />
              }

              <CardFooter className="py-5">
                {result.length > 0 && <Row>
                  <Col lg="6">
                    <div>
                      <small>
                        <strong>
                          Total Records : {totalRec}
                        </strong>
                      </small>
                    </div>
                  </Col>

                  <Col lg="6" >
                    <nav aria-label="...">
                      <div className="float-right">
                        <ReactPaginate
                          previousLabel={"prev"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pagesCount}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"paginationV2"}
                          subContainerClassName={"pages paginationV2"}
                          activeClassName={"active"}
                          forcePage={currentPage} />
                      </div>
                    </nav>

                  </Col>
                </Row>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );

}

export default Logs;