import React, { useEffect } from "react";
import {
  FormGroup,
  Input,
  Button,
  Row,
  Col
} from "reactstrap";
import moment from "moment";
import { getUserId, postMethod } from "services/httpServices";
import { useToasts } from 'react-toast-notifications';

const WalletCommission = (props) => {

  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [minEndDate, setMinEndDate] = React.useState('');
  const [bank, setBank] = React.useState([]);
  const [bankCode, setBankCodeFilter] = React.useState('');
  const [isMounted, setIsMounted] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const [paymentType, setPaymentType] = React.useState('');
  const [submitButton, setSubmitButton] = React.useState(false);
  const { addToast } = useToasts();

  /**================================= Redux Hook ============================ */

  useEffect(() => {
    setBank(props.bank);
  }, [props.bank]);

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (isMounted) {
      fetchReport();
    }
  }, [props.sort]);

  useEffect(() => {
    props.parentLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (props.pdfLoading && isMounted) {
      fetchReportData();
    }
  }, [props.pdfLoading])

  useEffect(() => {
    if (props.xlLoading && isMounted) {
      fetchReportData();
    }
  }, [props.xlLoading])

  useEffect(() => {
    if (
      endDate &&
      moment(new Date(endDate)).isBefore(new Date(startDate))
    ) {
      setEndDate('');
    }
  }, [startDate]);


  /**================================= File Function ============================ */

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const changeBankCode = (val) => {
    setBankCodeFilter(val);
  }

  const onSubmitReport = () => {
    setSubmitButton(true);
    if (props.currentPage != 0) {
      props.changePage(0);
    } else {
      fetchReport();
    }
  }

  /**================================= Validation ============================ */

  const validate = () => {
    var error = {};
    if (!startDate) {
      error.startDate = 'Please select start date';
    }
    if (!endDate) {
      error.endDate = 'Please select end date';
    }

    return error;
  }

  /**================================= API Calls ============================ */

  const fetchReport = () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      let errorMsg = '';
      setLoading(true);
      let id = getUserId();
      let data = {
        'FromDate': moment(new Date(startDate)).format('DD/MM/YYYY'),
        'ToDate': moment(new Date(endDate)).format('DD/MM/YYYY'),
        'PageNumber': Number(props.currentPage + 1),
        'PerPage': pageSize,
        'CommissionUserType': 'Bank',
        'PaymentType': 'topup'
      }

      if (bankCode && bankCode != '0') {
        data['UserIntId'] = Number(bankCode);
      }

      if (props.sort && props.sort.sortCreatedat == "1") {
        data['SortingOn'] = 'CreatedAt';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortCreatedat == "2") {
        data['SortingOn'] = 'CreatedAt';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortBankName == "1") {
        data['SortingOn'] = 'BankName';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortBankName == "2") {
        data['SortingOn'] = 'BankName';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortSubscriberName == "1") {
        data['SortingOn'] = 'SubscriberName';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortSubscriberName == "2") {
        data['SortingOn'] = 'SubscriberName';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortAmount == "1") {
        data['SortingOn'] = 'PaymentAmount';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortAmount == "2") {
        data['SortingOn'] = 'PaymentAmount';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortTransactionType == "1") {
        data['SortingOn'] = 'PaymentType';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortTransactionType == "2") {
        data['SortingOn'] = 'PaymentType';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortFeesAmount == "1") {
        data['SortingOn'] = 'CommissionAmount';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortFeesAmount == "2") {
        data['SortingOn'] = 'CommissionAmount';
        data['IsDescending'] = true;
      } 


      postMethod('/api/reports/WalletCommissionReport', JSON.stringify(data), 'post')
        .then(res => {
          setLoading(false);
          setIsMounted(true);
          setSubmitButton(false);
          if (
            res && res.data
          ) {
            if (res.data.responseCode === "200" &&
              res.data.result) {
              // Success
              if (
                !res.data.result.list ||
                !res.data.result.list.length
              ) {
                res.data.result.list = [];
              }
              props.parentCallback(res.data.result);
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || 'Error in fetching list';
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in fetching';
              notifyFailed(errorMsg);

            }
          } else {
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);

          }
        }).catch(err => {
          setLoading(false);
          setIsMounted(true);
          notifyFailed('Internal Server Error');
        })
    }
  }

  const fetchReportData = () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      let errorMsg = '';
      let data = {
        'FromDate': moment(new Date(startDate)).format('DD/MM/YYYY'),
        'ToDate': moment(new Date(endDate)).format('DD/MM/YYYY'),
        "Status": "",
        'PageNumber': 1,
        'PerPage': props.totalRec,
        'CommissionUserType': 'Bank',
        'PaymentType': 'topup'
      }

      if (bankCode && bankCode != '0') {
        data['UserIntId'] = Number(bankCode);
      }

      if (props.sort && props.sort.sortCreatedat == "1") {
        data['SortingOn'] = 'CreatedAt';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortCreatedat == "2") {
        data['SortingOn'] = 'CreatedAt';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortBankName == "1") {
        data['SortingOn'] = 'BankName';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortBankName == "2") {
        data['SortingOn'] = 'BankName';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortSubscriberName == "1") {
        data['SortingOn'] = 'SubscriberName';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortSubscriberName == "2") {
        data['SortingOn'] = 'SubscriberName';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortAmount == "1") {
        data['SortingOn'] = 'PaymentAmount';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortAmount == "2") {
        data['SortingOn'] = 'PaymentAmount';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortTransactionType == "1") {
        data['SortingOn'] = 'PaymentType';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortTransactionType == "2") {
        data['SortingOn'] = 'PaymentType';
        data['IsDescending'] = true;
      } else if (props.sort && props.sort.sortFeesAmount == "1") {
        data['SortingOn'] = 'CommissionAmount';
        data['IsDescending'] = false;
      } else if (props.sort && props.sort.sortFeesAmount == "2") {
        data['SortingOn'] = 'CommissionAmount';
        data['IsDescending'] = true;
      } 

      postMethod('/api/reports/WalletCommissionReport', JSON.stringify(data), 'post')
        .then(res => {
          setIsMounted(true);
          if (
            res && res.data
          ) {
            if (res.data.responseCode === "200" &&
              res.data.result) {
              // Success
              if (
                !res.data.result.list ||
                !res.data.result.list.length
              ) {
                res.data.result.list = [];
              }
              props.parentReportData(res.data.result);
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || 'Error in fetching list';
              notifyFailed(errorMsg);
              props.errorReportData();
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in fetching';
              notifyFailed(errorMsg);
              props.errorReportData();
            }
          } else {
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);
            props.errorReportData();
          }
        }).catch(err => {
          setIsMounted(true);
          notifyFailed('Internal Server Error');
          props.errorReportData();
        })
    }
  }

  return (
    <>
      &nbsp; &nbsp; &nbsp; &nbsp;

      <Row>
        <Col className={errors.startDate || errors.endDate ? 'mt-3': ''}>

          <Row>
            <Col lg="12">

              <small><strong>Start Date</strong></small>
            </Col>
          </Row>

          <Row>
            <Col lg="12">


              <FormGroup className="mb-0">

                <div class="dropdown-border">
                  <Input
                    type="date"
                    id="status"
                    className="form-control-alternative status-filter"
                    name="startDate"
                    placeholder="Start Date"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={(e) => setStartDate(e.target.value)}
                  >
                  </Input>
                </div>
              </FormGroup>


            </Col>
          </Row>

          <Row>
            <Col lg="12">

              {errors.startDate && <div className="text-left text-danger">
                <small>* {errors.startDate}</small>
              </div>}

            </Col>
          </Row>
        </Col>

        <Col className={errors.startDate || errors.endDate ? 'mt-3': ''}>

          <Row>
            <Col lg="12">

              <small><strong>End Date</strong></small>
            </Col>
          </Row>

          <Row>
            <Col lg="12">

              <FormGroup className="mb-0">

                <div class="dropdown-border">
                  <Input
                    type="date"
                    id="status"
                    className="form-control-alternative status-filter"
                    name="endDate"
                    placeholder="End Date"
                    min={startDate}
                    max={moment().format("YYYY-MM-DD")}
                    disabled={!startDate}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  >
                  </Input>
                </div>
              </FormGroup>


            </Col>
          </Row>

          <Row>
            <Col lg="12">

              {errors.endDate && <div className="text-left text-danger">
                <small>* {errors.endDate}</small>
              </div>}

            </Col>
          </Row>

        </Col>

        <Col className={errors.startDate || errors.endDate ? 'mt-3': ''}>
          <Row>
            <Col lg="12">
              <small><strong>Bank</strong></small>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <div class="dropdown-border">
                  <Input
                    type="select"
                    id="merchant"
                    className="form-control-alternative status-filter"
                    name="bankCode"
                    value={bankCode}
                    onChange={(e) => changeBankCode(e.target.value)}
                  >
                    <option value={0}>----Select Bank Code----</option>
                    {bank.map(c =>
                      <option key={c.key} value={c.key}>{c.value} - {c.uid}</option>
                    )}
                  </Input>
                </div>
              </FormGroup>

            </Col>
          </Row>

          <Row>
            <Col lg="12">
              {errors.bankCode && <div className="text-left text-danger">
                <small>* {errors.bankCode}</small>
              </div>}
            </Col>
          </Row>
        </Col>

      </Row>

      <Row className={errors.startDate || errors.endDate ? 'mt-1': 'mt-2'}>

        <Col>
          <Row>
            <Col lg="12">
              <Button color="primary"
                className="mt-4"
                onClick={onSubmitReport}
                disabled={loading}>
                Submit &nbsp; &nbsp;
                {loading && submitButton && <i class="fa fa-spinner fa-spin"></i>}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );

};
export default WalletCommission;