
import React, { useEffect } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useToasts } from 'react-toast-notifications';
import { getUserId, postMethod } from "services/httpServices";

const SuperAgentHeader = (props) => {
  const [totalAmt, setAmount] = React.useState('');
  const [receivedAmt, setReceivedAmt] = React.useState('');
  const [transferAmt, setTransferAmt] = React.useState('');
  const [balanceLoading, setBalLoading] = React.useState(false);
  const [subAgentCommissionCumulative, setSubAgentCommissionCumulative] = React.useState(0);
  const [subAgentBalance, setSubAgentBalance] = React.useState(0);
  const [subagentList, setSubagentList] = React.useState([])
  const [showSubAgentsList, setShowSubAgentsList] = React.useState(false)
  const [reverseBalance, setReverseBalance] = React.useState(0)
  const { addToast } = useToasts();

  useEffect(()=> {
    fetchBalance();
  }, [])

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const fetchBalance = () => {
    let errorMsg = '';
    let id = getUserId();
    setBalLoading(true);
    postMethod('/api/dashboard/SuperAgent/', {}, 'post')
    .then(res => {
      setBalLoading(false);
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          if (res.data.result.hasOwnProperty('balance')) {
            setAmount(res.data.result.balance);
          } else {
            setAmount(0);
          }

          if (res.data.result.hasOwnProperty('totalReceived')) {
            setReceivedAmt(res.data.result.totalReceived || 0);
          } else {
            setReceivedAmt(0);
          }

          if (res.data.result.hasOwnProperty('totalSubAgentBalance')) {
            setSubAgentBalance(res.data.result.totalSubAgentBalance || 0);
          } else {
            setSubAgentBalance(0);
          }

          if (res.data.result.hasOwnProperty('transferAmount')) {
            setTransferAmt(res.data.result.transferAmount);
          } else {
            setTransferAmt(0);
          }
          if (
            res.data.result.hasOwnProperty("subAgentCommissionCumulative")
          ) {
            setSubAgentCommissionCumulative( res.data.result?.subAgentCommissionCumulative?.length ? 
              res.data.result?.subAgentCommissionCumulative[0]
                ?.commissionAmount : 0
            );
          } else {
            setSubAgentCommissionCumulative(0);
          }
          if (res.data.result.hasOwnProperty("subagentList")) {
            setSubagentList(res.data.result.subagentList);
          } else {
            setSubagentList([]);
          }
          if (res.data.result.hasOwnProperty("reverseBalance")) {
            setReverseBalance(res.data.result.reverseBalance);
          } else {
            setReverseBalance(0);
          }
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching list';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      setBalLoading(false);
      notifyFailed('Internal Server Error');
    });
  }


  const getAmt = (amt) => {
    return amt?.toLocaleString();
  }

  const showAgentsList = (list) => {
    return list.map((item, idx) => (
      <div className="d-flex justify-content-between w-100">
        <h5>{item?.firstName} {item?.lastName}</h5>
        <h5>{getAmt(item?.balance)}</h5>
      </div>
    ))
  }
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row className="mt-3">
            <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Available Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalAmt)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
        
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Wallet Transfer</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i class="fas fa-envelope-open-text"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(transferAmt)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
            

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Wallet Received</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i class="fas fa-wallet"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(receivedAmt)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
            
            </Row>
            <Row className="mt-4">
            <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Sub Agent Commission Cumulative</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(subAgentCommissionCumulative)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0" style={{cursor:"pointer"}} onClick={() => setShowSubAgentsList(!showSubAgentsList)}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Sub Agent Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(subAgentBalance)}
                        </span>
                      )}
                    </p>
                    {
                      showSubAgentsList && <Row className="border-top border-gray mt-4 p-2">
                      {showAgentsList(subagentList)}
                    </Row>
                    }
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Reverse Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(reverseBalance)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SuperAgentHeader;