
import React, { useEffect } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { useHistory } from "react-router";
import { getLoginType, getUserB64, getUserName, postMethod, setUserB64 } from "../../services/httpServices";
import { connect } from "react-redux";
import swal from 'sweetalert2';
import { useToasts } from 'react-toast-notifications';



const AdminNavbar = (props) => {

  const history = useHistory();
  const profileImage = getUserB64();
  const userName = getUserName();
  const loginType = getLoginType();
  const { addToast } = useToasts();


  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }
 

  const logout = (e) => {
    e.preventDefault();
    //  window.localStorage.clear();
    // setUserB64(null);
    // history.push('/auth/login');
    // postMethod('/api/auth/LogOff', {}, 'post')
    // .then(()=> {
    // }).catch(e => {
    //   console.log('Error', e);
    // })
    openModal('Please wait system is logging out...')
  }

  const openModal = (msg) => {
    swal.fire({
      // title: 'Uploading...',
      html: msg,
      customClass: 'logout-modal',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        swal.showLoading()
      }
    });
    postMethod('/api/auth/LogOff', {}, 'post')
    .then((res)=> {
      let errorMsg = '';
      swal.close();
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
           window.localStorage.clear();
          setUserB64(null);
          history.push('/auth/login');
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in log out';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in log out';
          notifyFailed(errorMsg);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);

      }
    }).catch(e => {
      swal.close();
      notifyFailed('Internal Server Error');
    })
  }


  const changepswd = (e) => {
    e.preventDefault();
    history.push('/change-password');
  }

  const changepin = (e) => {
    e.preventDefault();
    history.push('/change-pin');
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <span className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
            {props.brandText}
          </span>
          {/* <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            
          </Link> */}
          
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {
                      profileImage && 
                      <img
                        alt="..."
                        src={profileImage}
                      />
                    }

                    {
                      !profileImage && 
                      <img
                        alt="..."
                        src={
                          require("../../assets/img/theme/blank-profile.png")
                            .default
                        }
                      />
                    } 
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold text-white">
                      {userName}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {/* <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider /> */}
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem onClick={(e) => changepswd(e)}>
                  <i className="ni ni-lock-circle-open"/>
                  <span>Change Password</span>
                </DropdownItem>

                {loginType == 'Subscriber' &&
                  <DropdownItem onClick={(e) => changepin(e)}>
                    <i class="fa fa-key" aria-hidden="true" />
                    <span>Change Pin</span>
                  </DropdownItem>
                }

                <DropdownItem divider />

                <DropdownItem href="#pablo" onClick={(e) => logout(e)}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {...state}
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     setImg: (data) => dispatch(setUserImage(data)),
//   };
// };

export default connect(mapStateToProps)(AdminNavbar);
