
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components

import { checkSpace, getMethod, postMethod } from '../../services/httpServices';
import { useToasts } from 'react-toast-notifications';
import { bankType, MOBILE_ONLY_COUNTRY } from '../../appConfig';
import { checkBankAccNo, checkName } from 'services/util';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";



var FormData = require('form-data');
// var fs = require('fs');

const codes = [23225, 23230, 23231, 23232,
  23233, 23234, 23244, 23275, 23276, 23277,
  23278, 23279, 23280, 23288, 23299];

const initialValue = {
  bankname: '',
  mmoemail: '',
  mmomobileCode: '',
  mmophone: '',
  bankPhoneWithCC: '',
  mmocbin: '',
  itemail: '',
  itmobileCode: '',
  itPhoneWithCC: '',
  itPhone: '',
  address: '',
  collectionAccName: '',
  collectionAccNo: '',
  attachFile: null,
  id: null,
  isApiAvailable: false,
  commissionAccName: '',
  commissionAccNo: '',
  commissionId: '',
  masterBankId: ''
}

const EditBank = (props) => {

  initialValue.bankname = props.bankName;
  initialValue.address = props.contactAddress;
  initialValue.mmocbin = props.centralBankIdentificationNo;
  initialValue.mmophone = props.contactMobileNumber;
  initialValue.mmomobileCode = props.contactMobileCode;
  initialValue.bankPhoneWithCC = props.contactMobileCode + props.contactMobileNumber;
  initialValue.mmoemail = props.contactEmail;
  initialValue.itemail = props.itContactEmail;
  initialValue.itPhone = props.itContactMobileNumber;
  initialValue.itPhoneWithCC = props.itContactMobileCode + props.itContactMobileNumber;
  initialValue.itmobileCode = props.itContactMobileCode;
  initialValue.collectionAccNo = props.accountNo;
  initialValue.collectionAccName = props.accountName;
  initialValue.id = props.id;
  initialValue.accountType = props.accountType;
  initialValue.isApiAvailable = props.isApiAvailable;
  initialValue.commissionAccName = props.commissionAccName ? props.commissionAccName : '';
  initialValue.commissionAccNo = props.commissionAccNo ? props.commissionAccNo : '';
  initialValue.commissionId = props.commissionId ? props.commissionId : '';
  initialValue.masterBankId = props.masterBankId ? props.masterBankId : '';

  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [file, setFile] = React.useState(null);
  const [commissions, setCommission] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  const { addToast } = useToasts();


  React.useEffect(() => {
    fetchCommission();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      if (name === 'mmophone' || name === 'itPhone') {
        if (checkMobValidation(value)) {
          changeState({
            ...values,
            [name]: value,
          });
        }
      } else if (name === 'collectionAccNo' || name === 'commissionAccNo') {
        // if (checkBankAccNo(value)) {
          changeState({
            ...values,
            [name]: value,
          });
        // }
      } else if (name === 'commissionAccName' || name === 'collectionAccName') {
        if (checkName(value)) {
          changeState({
            ...values,
            [name]: value,
          });
        }
      } else {
        changeState({
          ...values,
          [name]: value,
        });
      }
    }
  };

  const itPhoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      changeState({
        ...values, itPhoneWithCC: value, itmobileCode: data.dialCode,
        itPhone: value.substring(data.dialCode.length)
      });
    }
    return;
  }

  const changeState = (obj) => {
    setValues(obj);
  };

  const checkDigitOnly = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 6) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  const closeModal = () => {
    props.closeModal();
  }

  const validate = (inputs) => {
    let errors = {};
    let emailRegEx = /\S+@\S+\.\S+/
    if (inputs.mmophone) {
      inputs.mmophone = inputs.mmophone.toString();
    }
    if (inputs.itPhone) {
      inputs.itPhone = inputs.itPhone.toString();
    }
    if (!inputs.bankname) {
      errors.bankname = 'Bank Name is Required';
    }
    if (!inputs.mmoemail) {
      errors.mmoemail = 'Email is Required';
    } else if (!emailRegEx.test(inputs.mmoemail)) {
      errors.mmoemail = 'Enter valid email';
    }
    if (!inputs.mmocbin) {
      errors.mmocbin = 'Central Bank Identification Number is Required';
    }
    if (!inputs.mmomobileCode) {
      errors.mmomobileCode = 'Please select mobile code';
    }
    if (!inputs.mmophone) {
      errors.mmophone = 'Phone Number is Required';
    }
    // else if (inputs.mmophone.length !== 6) {
    //   errors.mmophone = 'Phone Number should have 6 digits';
    // }
    if (!inputs.collectionAccName) {
      errors.collectionAccName = 'Collection Account Name is Required';
    }
    if (!inputs.collectionAccNo) {
      errors.collectionAccNo = 'Collection Account Number is Required';
    } 
    // else if (inputs.collectionAccNo.length != 13) {
    //   errors.collectionAccNo = 'Please enter collection account number of 13 digits';
    // }
    if (!inputs.address) {
      errors.address = 'Address is Required';
    }
    if (!inputs.itmobileCode) {
      errors.itmobileCode = 'Please select mobile code';
    }
    if (!inputs.itPhone) {
      errors.itPhone = 'Phone Number is Required';
    }
    // else if (inputs.itPhone.length !== 6) {
    //   errors.itPhone = 'Phone Number should have 6 digits';
    // }
    if (!inputs.itemail) {
      errors.itemail = 'Email is Required';
    } else if (!emailRegEx.test(inputs.itemail)) {
      errors.itemail = 'Enter valid email';
    }
    // if (!file) {
    //   errors.attachFile = 'Attach file is required';
    // }
    if (!inputs.accountType) {
      errors.accountType = 'Account type is required';
    }
    if (inputs.accountType === 'Collection') {
      if (!inputs.commissionAccName) {
        errors.commissionAccName = 'Commission Account Name is Required';
      }
      if (!inputs.commissionAccNo) {
        errors.commissionAccNo = 'Commission Account Number is Required';
      } 
      // else if (inputs.commissionAccNo.length != 13) {
      //   errors.commissionAccNo = 'Please enter commission account number of 13 digits';
      // }
      if (!inputs.commissionId) {
        errors.commissionId = 'Please select commission';
      }
    }
    return errors;
  }

  const fileChanger = (event) => {
    setFile(event.target.files[0]);
  }

  const notify = (text, type) => {
    if (!text) {
      text = 'Edit successfully';
    }
    if (!type) {
      type = 'success';
    }
    addToast(text, {
      appearance: type,
      autoDismiss: true,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      var data = new FormData();
      data.append('bankName', values.bankname);
      data.append('contactAddress', values.address);
      data.append('centralBankIdentificationNo', values.mmocbin);
      data.append('contactMobileNumber', values.mmophone);
      data.append('contactMobileCode', values.mmomobileCode);
      data.append('contactEmail', values.mmoemail);
      data.append('itContactEmail', values.itemail);
      data.append('itContactMobileNumber', values.itPhone);
      data.append('itContactMobileCode', values.itmobileCode);
      // data.append('collectionAccountName', values.collectionAccName);
      // data.append('collectionAccountNo', values.collectionAccNo);
      // data.append('AttachedDocumentPath', 'attachedDocuments');
      // data.append('attachment',  file);
      data.append('AccountName', values.collectionAccName.trim());
      data.append('AccountNo', values.collectionAccNo);
      data.append('AccountType', values.accountType);
      data.append('Id', values.id);
      data.append('isApiAvailable', values.isApiAvailable);
      data.append('CommissionAccName', values.commissionAccName.trim());
      data.append('CommissionAccNo', values.commissionAccNo);
      data.append('CommissionId', Number(values.commissionId));
      data.append('MasterBankId', values.masterBankId);

      if (file) {
        data.append('attachment', file);
      }
      let errorMsg = '';
      setLoading(true);
      postMethod('/api/BankProfile/update', data, 'post')
        .then(res => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notify('Updated Successfuly', 'success');
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || 'Error while saving';
              notify(errorMsg, 'error');
              // setSError(errorMsg);
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in saving';
              notify(errorMsg, 'error');
              // setSError(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = 'Unknown Error';
            notify(errorMsg, 'error');
            // setSError(errorMsg);
          }
        }).catch(err => {
          setLoading(false);
          errorMsg = 'Internal Server Error';
          notify(errorMsg, 'error');
        });
    }
  }

  const downloadFile = () => {
    let url = '/api/BankProfile/downloadFile/' + values.id;
    setSpinner(true);
    getMethod(url, 'get')
      .then(res => {
        setSpinner(false);
        if (res && res.config && res.config.url) {
          window.location.href = res.config.url;
        } else {
          notify('Error in fetching file from server', 'error');
        }
      }).catch(err => {
        setSpinner(false);
        notify('Internal Server Error', 'error');
      })
  }

  const fetchCommission = () => {
    let errorMsg = '';
    getMethod('/api/commission/ddllist?CommissionType=WalletTopUp', 'get')
      .then(res => {
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            if (
              !res.data.result ||
              !res.data.result.length
            ) {
              res.data.result = [];
            }
            setCommission(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching commission';
            notify(errorMsg, 'error');
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notify(errorMsg, 'error');

          }
        } else {
          errorMsg = 'Unknown Error';
          notify(errorMsg, 'error');

        }
      }).catch(err => {
        notify('Internal Server Error', 'error');
      })
  }
  return (
    <>
      {/* Page content */}
      <Container className="p-0">
        <Row >
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Bank Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="sm"
                      close
                      style={{ cursor: 'pointer', fontSize: '25px' }}
                    >

                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-mmoname"
                          >
                            Bank Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-mmoname"
                            placeholder="Bank Name"
                            type="text"
                            name="bankname"
                            value={values.bankname}
                            onChange={handleInputChange}
                            disabled
                          />
                          {errors.bankname && <div className="text-left text-danger">
                            <small>* {errors.bankname}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-mmoemail"
                          >
                            Contact Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-mmoemail"
                            placeholder="jesse@example.com"
                            type="email"
                            name="mmoemail"
                            value={values.mmoemail}
                            disabled
                            onChange={handleInputChange}
                          />
                          {errors.mmoemail && <div className="text-left text-danger">
                            <small>* {errors.mmoemail}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="mmomobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="mmomobileCode"
                            className="form-control-alternative"
                            name="mmomobileCode"
                            value={values.mmomobileCode}
                            onChange={handleInputChange}
                            disabled
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-mmophone"
                          >
                            Contact Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-mmophone"
                            placeholder="Phone Number"
                            type="text"
                            name="mmophone"
                            value={values.mmophone}
                            onChange={handleInputChange}
                            disabled
                          />
                          {errors.mmophone && <div className="text-left text-danger">
                            <small>* {errors.mmophone}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                       */}

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bankphone"
                          >
                            Contact Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={'sl'}
                            name="bankPhoneWithCC"
                            value={values.bankPhoneWithCC}
                            // onlyCountries={MOBILE_ONLY_COUNTRY}
                            countryCodeEditable={false}
                            disabled
                          />
                          {errors.bankphone && <div className="text-left text-danger">
                            <small>* {errors.bankphone}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cbin"
                          >
                            Central Bank Identification Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-cbin"
                            placeholder="Central Bank Identification No."
                            type="text"
                            name="mmocbin"
                            value={values.mmocbin}
                            onChange={handleInputChange}
                          />
                          {errors.mmocbin && <div className="text-left text-danger">
                            <small>* {errors.mmocbin}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="itmobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="itmobileCode"
                            className="form-control-alternative"
                            name="itmobileCode"
                            value={values.itmobileCode}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-itphone"
                          >
                            IT Contact Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-itphone"
                            placeholder="IT Contact Phone Number"
                            type="text"
                            name="itPhone"
                            value={values.itPhone}
                            onChange={handleInputChange}
                          />
                          {errors.itPhone && <div className="text-left text-danger">
                            <small>* {errors.itPhone}</small>
                          </div>}
                        </FormGroup>
                      </Col>
 */}

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-itphone"
                          >
                            IT Contact Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={'sl'}
                            name="itPhoneWithCC"
                            value={values.itPhoneWithCC}
                            // onlyCountries={MOBILE_ONLY_COUNTRY}
                            countryCodeEditable={false}
                            onChange={itPhoneChange}
                          />
                          {errors.itPhone && <div className="text-left text-danger">
                            <small>* {errors.itPhone}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-itemail"
                          >
                            IT Contact Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-itemail"
                            placeholder="it@example.com"
                            type="text"
                            name="itemail"
                            value={values.itemail}
                            onChange={handleInputChange}
                          />
                          {errors.itemail && <div className="text-left text-danger">
                            <small>* {errors.itemail}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="accountType-id"
                            className="form-control-label">
                            Account Type
                          </label>
                          <Input
                            type="select"
                            id="accountType-id"
                            className="form-control-alternative"
                            name="accountType"
                            value={values.accountType}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            {bankType.map(c => <option key={c.key} value={c.key}>{c.value}</option>)}
                          </Input>
                          {errors.accountType && <div className="text-left text-danger">
                            <small>* {errors.accountType}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup>
                          <label
                            for="api-id"
                            className="form-control-label">
                            Integrated with api
                          </label>
                          <Input
                            type="select"
                            id="api-id"
                            className="form-control-alternative"
                            name="isApiAvailable"
                            value={values.isApiAvailable}
                            onChange={handleInputChange}
                          >
                            <option key="yes" value="true">Yes</option>
                            <option key="no" value="false">No</option>
                          </Input>
                          {errors.isApiAvailable && <div className="text-left text-danger">
                            <small>* {errors.isApiAvailable}</small>
                          </div>}
                        </FormGroup>

                      </Col>

                    </Row>

                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-address">
                          Address
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-address"
                          placeholder="Addess"
                          type="textarea"
                          rows="4"
                          name="address"
                          value={values.address}
                          onChange={handleInputChange}
                        />
                        {errors.address && <div className="text-left text-danger">
                          <small>* {errors.address}</small>
                        </div>}
                      </Col>

                    </Row>
                  </div>

                  {values.accountType != 'Settlement' &&
                    <>
                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small text-muted mb-4">
                        Commission Information
                      </h6>

                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-commissionAccName"
                              >
                                Commission Account Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-commissionAccName"
                                placeholder="Commission Account Name"
                                type="text"
                                name="commissionAccName"
                                value={values.commissionAccName}
                                onChange={handleInputChange}
                              />
                              {errors.commissionAccName && <div className="text-left text-danger">
                                <small>* {errors.commissionAccName}</small>
                              </div>}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-commissionAccNumber"
                              >
                                Commission Account Number
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-commissionAccNumber"
                                placeholder="Commission Account Number"
                                type="text"
                                name="commissionAccNo"
                                value={values.commissionAccNo}
                                onChange={handleInputChange}
                                valid={false}
                              />
                              {errors.commissionAccNo && <div className="text-left text-danger">
                                <small>* {errors.commissionAccNo}</small>
                              </div>}
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                for="commission"
                                className="form-control-label">
                                Commission
                              </label>
                              <Input
                                type="select"
                                id="commission"
                                className="form-control-alternative"
                                name="commissionId"
                                value={values.commissionId}
                                onChange={handleInputChange}
                              >
                                <option key="" value="">-----Select Commission ------</option>
                                {commissions.map(c => <option key={c.key} value={c.key}>{c.value}</option>)}
                              </Input>
                              {errors.commissionId && <div className="text-left text-danger">
                                <small>* {errors.commissionId}</small>
                              </div>}
                            </FormGroup>
                          </Col>

                        </Row>
                      </div>
                    </>
                  }

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Collection information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-collectAccName"
                          >
                            Account Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-collectAccName"
                            placeholder="Account Name"
                            type="text"
                            name="collectionAccName"
                            value={values.collectionAccName}
                            onChange={handleInputChange}
                          />
                          {errors.collectionAccName && <div className="text-left text-danger">
                            <small>* {errors.collectionAccName}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-collectionAccNumber"
                          >
                            Account Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-collectionAccNumber"
                            placeholder="Account Number"
                            type="text"
                            name="collectionAccNo"
                            value={values.collectionAccNo}
                            onChange={handleInputChange}
                            valid={false}
                          />
                          {errors.collectionAccNo && <div className="text-left text-danger">
                            <small>* {errors.collectionAccNo}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <div style={{ marginTop: "20px" }}>
                            <a href="#"
                              onClick={downloadFile}
                              style={{ marginTop: '20px' }}
                              id="tag">
                              Download Attached Document
                            </a>
                            &nbsp; &nbsp;
                            {spinner && <div class="spinner-border" role="status">
                              <span class="sr-only"></span>
                            </div>}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" for="attachFile">
                            Attachment
                          </label>
                          <Input
                            className="form-control-file"
                            type="file"
                            name="attachFile" id="attachFile"
                            accept="application/pdf"
                            onChange={fileChanger}
                          />
                          <span>* pdf only</span>
                          {errors.attachFile && <div className="text-left text-danger">
                            <small>* {errors.attachFile}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg-6>

                      </Col>
                    </Row>
                    <Row>
                      <Col lg-12>
                        <div>
                          <Button className="btn float-right" color="primary" type="submit"
                            disabled={loading}>
                            Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
                {/* <FullPageLoader /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditBank;
