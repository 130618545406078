
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
// core components
import { useToasts } from 'react-toast-notifications';
import { checkSpace, postMethod, getMethod } from '../../services/httpServices';
import { bankType, MOBILE_ONLY_COUNTRY } from '../../appConfig';
import { checkBankAccNo, checkName } from 'services/util';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


const codes = [23225, 23230, 23231, 23232,
  23233, 23234, 23244, 23275, 23276, 23277,
  23278, 23279, 23280, 23288, 23299];

const initialValue = {
  bankname: '',
  bankemail: '',
  bankmobileCode: '',
  bankphone: '',
  bankPhoneWithCC: '',
  cbin: '',
  itemail: '',
  itmobileCode: '',
  itPhone: '',
  itPhoneWithCC: '',
  address: '',
  collectionAccName: '',
  collectionAccNo: '',
  attachFile: null,
  accountType: bankType[0].key,
  isApiAvailable: false,
  commissionAccName: '',
  commissionAccNo: '',
  commissionId: '',
  masterBankId: ''
}

const Bank = (props) => {

  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [commissions, setCommission] = React.useState([]);
  const [banks, setBanks] = React.useState([]);
  const { addToast } = useToasts();


  React.useEffect(() => {
    fetchCommission();

  }, []);

  React.useEffect(() => {
    fetchMasterBank();
  }, [values.accountType]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      if (name === 'bankphone' || name === 'itPhone') {
        if (checkMobValidation(value)) {
          changeState({
            ...values,
            [name]: value,
          });
        }
      } else if (name === 'collectionAccNo' || name === 'commissionAccNo') {
        // if (checkBankAccNo(value)) {
          changeState({
            ...values,
            [name]: value,
          });
        // }
      } else if (name === 'commissionAccName' || name === 'collectionAccName') {
        if (checkName(value)) {
          changeState({
            ...values,
            [name]: value,
          });
        }
      } else {
        changeState({
          ...values,
          [name]: value,
        });
      }
    }
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      changeState({
        ...values, bankPhoneWithCC: value, bankmobileCode: data.dialCode,
        bankphone: value.substring(data.dialCode.length)
      });
    }
    return;
  }


  const itPhoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      changeState({
        ...values, itPhoneWithCC: value, itmobileCode: data.dialCode,
        itPhone: value.substring(data.dialCode.length)
      });
    }
    return;
  }

  const changeState = (obj) => {
    setValues(obj);
  };

  const changeBank = (e) => {
    const { name, value } = e.target;
    const v = getBankName(value);
    changeState({
      ...values,
      bankname: v && v.value ? v.value : '',
      [name]: value ? value : ''
    });
  }

  const getBankName = (id) => {
    let bank = banks.find((m) => m.key == id);
    if (bank) {
      return bank;
    } else {
      return {};
    }
  };

  const checkDigitOnly = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 6) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const closeModal = () => {
    props.closeModal();
  }

  const validate = (inputs) => {
    let errors = {};
    let emailRegEx = /\S+@\S+\.\S+/
    if (!inputs.bankname) {
      errors.bankname = 'Bank Name is Required';
    }
    if (!inputs.masterBankId) {
      errors.masterBankId = 'Bank name is Required';
    }
    if (!inputs.bankemail) {
      errors.bankemail = 'Email is Required';
    } else if (!emailRegEx.test(inputs.bankemail)) {
      errors.bankemail = 'Enter valid email';
    }
    if (!inputs.cbin) {
      errors.cbin = 'Central Bank Identification Number is Required';
    }
    if (!inputs.bankmobileCode) {
      errors.bankmobileCode = 'Please select mobile code';
    }
    if (!inputs.bankphone) {
      errors.bankphone = 'Phone Number is Required';
    }
    if (!inputs.collectionAccName) {
      errors.collectionAccName = 'Collection Account Name is Required';
    }
    if (!inputs.collectionAccNo) {
      errors.collectionAccNo = 'Collection Account Number is Required';
    } 
    // else if (inputs.collectionAccNo.length != 13) {
    //   errors.collectionAccNo = 'Please enter collection account number of 13 digits';
    // }
    if (!inputs.address) {
      errors.address = 'Address is Required';
    }
    if (!inputs.itPhone) {
      errors.itPhone = 'Phone Number is Required';
    }
    if (!inputs.itmobileCode) {
      errors.itmobileCode = 'Please select mobile code';
    }
    if (!inputs.itemail) {
      errors.itemail = 'Email is Required';
    } else if (!emailRegEx.test(inputs.itemail)) {
      errors.itemail = 'Enter valid email';
    }
    if (!file) {
      errors.attachFile = 'Attach file is required';
    }
    if (!inputs.accountType) {
      errors.accountType = 'Account type is required';
    }
    if (inputs.accountType === 'Collection') {
      if (!inputs.commissionAccName) {
        errors.commissionAccName = 'Commission Account Name is Required';
      }
      if (!inputs.commissionAccNo) {
        errors.commissionAccNo = 'Commission Account Number is Required';
      }
      // else if (inputs.commissionAccNo.length != 13) {
      //   errors.commissionAccNo = 'Please enter commission account number of 13 digits';
      // }
      if (!inputs.commissionId) {
        errors.commissionId = 'Please select commission';
      }
    }

    return errors;
  }

  const fileChanger = (event) => {
    setFile(event.target.files[0]);
    setErrors({...errors, attachFile: ''});
  }

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      var data = new FormData();
      data.append('bankName', values.bankname);
      data.append('contactAddress', values.address);
      data.append('centralBankIdentificationNo', values.cbin);
      data.append('contactMobileNumber', values.bankphone);
      data.append('contactMobileCode', values.bankmobileCode);
      data.append('contactEmail', values.bankemail);
      data.append('itContactEmail', values.itemail);
      data.append('itContactMobileNumber', values.itPhone);
      data.append('itContactMobileCode', values.itmobileCode);
      data.append('AccountName', values.collectionAccName.trim());
      data.append('AccountNo', values.collectionAccNo);
      // data.append('AttachedDocumentPath', 'attachedDocuments');
      data.append('attachment', file);
      data.append('AccountType', values.accountType);
      data.append('isApiAvailable', values.isApiAvailable);
      data.append('CommissionAccName',
        values.accountType == 'Collection' ? values.commissionAccName.trim() : ''
      );
      data.append('CommissionAccNo',
        values.accountType == 'Collection' ? values.commissionAccNo : '');
      data.append('CommissionId',
        values.accountType == 'Collection' ? values.commissionId : 0);

      data.append('MasterBankId', values.masterBankId);

      let errorMsg = '';
      setLoading(true);
      postMethod('/api/BankProfile/save', data, 'post')
        .then(res => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess('Saved Successfuly');
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || 'Error while saving';
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in saving';
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);
          }
        }).catch(err => {
          setLoading(false);
          errorMsg = 'Internal Server Error';
          notifyFailed(errorMsg);
        });
    }
  }

  const fetchCommission = () => {
    let errorMsg = '';
    getMethod('/api/commission/ddllist?CommissionType=WalletTopUp', 'get')
      .then(res => {
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            if (
              !res.data.result ||
              !res.data.result.length
            ) {
              res.data.result = [];
            }
            setCommission(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching commission';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        notifyFailed('Internal Server Error');
      })
  }

  const fetchMasterBank = () => {
    let errorMsg = '';
    let url = '/api/bankProfile/ddlMasterBank?';
    if (values.accountType === 'Collection') {
      url = url + 'Accounttype=Collection';
    } else if (values.accountType === 'Settlement') {
      url = url + 'Accounttype=Settlement';
    }
    setBanks([]);
    getMethod(url, 'get')
      .then(res => {
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            if (
              !res.data.result ||
              !res.data.result.length
            ) {
              res.data.result = [];
            }
            setBanks(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching master bank';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching bank';
            notifyFailed(errorMsg);

          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);

        }
      }).catch(err => {
        notifyFailed('Internal Server Error');
      })
  }
  return (
    <>
      {/* Page content */}
      <Container className="p-0">
        <Row >
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0 text-white">Bank information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="sm"
                      close
                      style={{ cursor: 'pointer', fontSize: '25px', color: '#fff' }}
                    >
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} autoComplete="off">

                  <div className="pl-lg-4">

                    <FormGroup tag="fieldset">
                      <label><strong>Account Type</strong></label>
                      <Row>
                        <Col lg="3">
                          <FormGroup check>
                            <Label check>
                              <Input type="radio" name="accountType" value="Collection"
                                checked={values.accountType === "Collection"}
                                onChange={handleInputChange} />{' '}
                              Collection Account
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup check>
                            <Label check>
                              <Input type="radio" name="accountType" value="Settlement"
                                checked={values.accountType === "Settlement"}
                                onChange={handleInputChange} />{' '}
                              Settlement Account
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </FormGroup>

                    <Row>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bankname"
                          >
                            Bank Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bankname"
                            placeholder="Bank Name"
                            type="text"
                            name="bankname"
                            value={values.bankname}
                            onChange={handleInputChange}
                          />
                          {errors.bankname && <div className="text-left text-danger">
                            <small>* {errors.bankname}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                       */}

                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-bankname"
                            className="form-control-label">
                            Bank
                          </label>
                          <Input
                            type="select"
                            id="input-bankname"
                            className="form-control-alternative"
                            name="masterBankId"
                            value={values.masterBankId}
                            onChange={changeBank}
                          >
                            <option key="" value="">-----Select Bank ------</option>
                            {banks.map(c => <option key={c.key} value={c.key}>{c.value}</option>)}
                          </Input>
                          {errors.masterBankId && <div className="text-left text-danger">
                            <small>* {errors.masterBankId}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bankemail"
                          >
                            Contact Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bankemail"
                            placeholder="jesse@example.com"
                            type="text"
                            name="bankemail"
                            value={values.bankemail}
                            onChange={handleInputChange}
                          />
                          {errors.bankemail && <div className="text-left text-danger">
                            <small>* {errors.bankemail}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="mmomobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="bankmobileCode"
                            className="form-control-alternative"
                            name="bankmobileCode"
                            value={values.bankmobileCode}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bankphone"
                          >
                            Contact Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bankphone"
                            placeholder="Phone Number"
                            type="text"
                            name="bankphone"
                            value={values.bankphone}
                            onChange={handleInputChange}
                          />
                          {errors.bankphone && <div className="text-left text-danger">
                            <small>* {errors.bankphone}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                       */}

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bankphone"
                          >
                            Contact Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={'sl'}
                            name="bankPhoneWithCC"
                            value={values.bankPhoneWithCC}
                            // onlyCountries={MOBILE_ONLY_COUNTRY}
                            countryCodeEditable={false}
                            onChange={phoneChange}
                          />
                          {errors.bankphone && <div className="text-left text-danger">
                            <small>* {errors.bankphone}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cbin"
                          >
                            Central Bank Identification Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-cbin"
                            placeholder="Central Bank Identification No."
                            type="text"
                            name="cbin"
                            value={values.cbin}
                            onChange={handleInputChange}
                          />
                          {errors.cbin && <div className="text-left text-danger">
                            <small>* {errors.cbin}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="itmobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="itmobileCode"
                            className="form-control-alternative"
                            name="itmobileCode"
                            value={values.itmobileCode}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-itphone"
                          >
                            IT Contact Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-itphone"
                            placeholder="IT Contact Phone Number"
                            type="text"
                            name="itPhone"
                            value={values.itPhone}
                            onChange={handleInputChange}
                          />
                          {errors.itPhone && <div className="text-left text-danger">
                            <small>* {errors.itPhone}</small>
                          </div>}
                        </FormGroup>
                      </Col>
 */}

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-itphone"
                          >
                            IT Contact Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={'sl'}
                            name="itPhoneWithCC"
                            value={values.itPhoneWithCC}
                            // onlyCountries={MOBILE_ONLY_COUNTRY}
                            countryCodeEditable={false}
                            onChange={itPhoneChange}
                          />
                          {errors.itPhone && <div className="text-left text-danger">
                            <small>* {errors.itPhone}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-itemail"
                          >
                            IT Contact Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-itemail"
                            placeholder="it@example.com"
                            type="text"
                            name="itemail"
                            value={values.itemail}
                            onChange={handleInputChange}
                          />
                          {errors.itemail && <div className="text-left text-danger">
                            <small>* {errors.itemail}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            for="accountType-id"
                            className="form-control-label">
                            Account Type
                          </label>
                          <Input
                            type="select"
                            id="accountType-id"
                            className="form-control-alternative"
                            name="accountType"
                            value={values.accountType}
                            onChange={handleInputChange}
                          >
                            {bankType.map(c => <option key={c.key} value={c.key}>{c.value}</option>)}
                          </Input>
                          {errors.accountType && <div className="text-left text-danger">
                            <small>* {errors.accountType}</small>
                          </div>}
                        </FormGroup>
                      </Col> */}

                      <Col>
                        <FormGroup>
                          <label
                            for="api-id"
                            className="form-control-label">
                            Integrated with api
                          </label>
                          <Input
                            type="select"
                            id="api-id"
                            className="form-control-alternative"
                            name="isApiAvailable"
                            value={values.isApiAvailable}
                            onChange={handleInputChange}
                          >
                            <option key="yes" value="true">Yes</option>
                            <option key="no" value="false">No</option>
                          </Input>
                          {errors.isApiAvailable && <div className="text-left text-danger">
                            <small>* {errors.isApiAvailable}</small>
                          </div>}
                        </FormGroup>

                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-address">
                          Address
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-address"
                          placeholder="Addess"
                          type="textarea"
                          rows="4"
                          name="address"
                          value={values.address}
                          onChange={handleInputChange}
                        />
                        {errors.address && <div className="text-left text-danger">
                          <small>* {errors.address}</small>
                        </div>}
                      </Col>

                    </Row>
                  </div>

                  {values.accountType != 'Settlement' &&
                    <>
                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small text-muted mb-4">
                        Commission Information
                      </h6>

                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-commissionAccName"
                              >
                                Commission Account Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-commissionAccName"
                                placeholder="Commission Account Name"
                                type="text"
                                name="commissionAccName"
                                value={values.commissionAccName}
                                onChange={handleInputChange}
                              />
                              {errors.commissionAccName && <div className="text-left text-danger">
                                <small>* {errors.commissionAccName}</small>
                              </div>}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-commissionAccNumber"
                              >
                                Commission Account Number
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-commissionAccNumber"
                                placeholder="Commission Account Number"
                                type="text"
                                name="commissionAccNo"
                                value={values.commissionAccNo}
                                onChange={handleInputChange}
                                valid={false}
                              />
                              {errors.commissionAccNo && <div className="text-left text-danger">
                                <small>* {errors.commissionAccNo}</small>
                              </div>}
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                for="commission"
                                className="form-control-label">
                                Commission
                              </label>
                              <Input
                                type="select"
                                id="commission"
                                className="form-control-alternative"
                                name="commissionId"
                                value={values.commissionId}
                                onChange={handleInputChange}
                              >
                                <option key="" value="">-----Select Commission ------</option>
                                {commissions.map(c => <option key={c.key} value={c.key}>{c.value}</option>)}
                              </Input>
                              {errors.commissionId && <div className="text-left text-danger">
                                <small>* {errors.commissionId}</small>
                              </div>}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  }

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Collection Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-collectAccName"
                          >
                            Account Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-collectAccName"
                            placeholder="Account Name"
                            type="text"
                            name="collectionAccName"
                            value={values.collectionAccName}
                            onChange={handleInputChange}
                          />
                          {errors.collectionAccName && <div className="text-left text-danger">
                            <small>* {errors.collectionAccName}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-collectionAccNumber"
                          >
                            Account Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-collectionAccNumber"
                            placeholder="Account Number"
                            type="text"
                            name="collectionAccNo"
                            value={values.collectionAccNo}
                            onChange={handleInputChange}
                            valid={false}
                          />
                          {errors.collectionAccNo && <div className="text-left text-danger">
                            <small>* {errors.collectionAccNo}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" for="attachFile">
                            Attachment
                          </label>
                          <Input
                            className="form-control-file"
                            type="file"
                            name="file" id="attachFile"
                            accept="application/pdf"
                            onChange={fileChanger}
                          />
                          <span>* pdf only</span>
                          {errors.attachFile && <div className="text-left text-danger">
                            <small>* {errors.attachFile}</small>
                          </div>}
                          {/* <FormText color="muted">
                            This is some placeholder block-level help text for the above input.
                            It's a bit lighter and easily wraps to a new line.
                          </FormText> */}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg-12>
                        <div>
                          <Button className="btn float-right" color="primary" type="submit"
                            disabled={loading}>
                            Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Bank;
