import React, { useEffect, useState } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { getUserId, postMethod } from "services/httpServices";
import { daysInMonth } from "services/util";
import { daysInArray } from "services/util";
import { subsMTDWalletTrans } from "variables/charts";

const MasterAgentHeader = (props) => {
  const [totalAmt, setAmount] = React.useState("");
  const [balanceTransferred, setBalanceTransferred] = React.useState("");
  const [monthlyCashTopup, setMonthlyCashTopup] = React.useState("");
  const [monthlyReceived, setMonthlyReceived] = React.useState("");
  const [totalCashTopup, setTotalCashTopup] = React.useState("");
  const [transferAmt, setTransferAmt] = React.useState("");
  const [balanceLoading, setBalLoading] = React.useState(false);
  const [totalSuperAgentBalance, setTotalSuperAgentBalance] = React.useState(0);
  const [totalSubAgentBalance, setTotalSubAgentBalance] = React.useState(0);
  const [subAgentCommissionCumulative, setSubAgentCommissionCumulative] = React.useState(0);
  const [subagentList, setSubagentList] = useState([])
  const [superagentList, setSuperagentList] = useState([])
  const [showSuperAgentsList, setShowSuperAgentsList] = useState(false)
  const [showSubAgentsList, setShowSubAgentsList] = useState(false)
  const { addToast } = useToasts();

  useEffect(() => {
    fetchBalance();
  }, []);

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const fetchBalance = () => {
    let errorMsg = "";
    let id = getUserId();
    setBalLoading(true);
    postMethod("/api/Dashboard/MasterAgent", {}, "post")
      .then((res) => {
        setBalLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (res.data.result.hasOwnProperty("totalWalletBalance")) {
              setAmount(res.data.result.totalWalletBalance);
            } else {
              setAmount(0);
            }

            if (res.data.result.hasOwnProperty("balanceTransferred")) {
              setBalanceTransferred(res.data.result.balanceTransferred);
            } else {
              setBalanceTransferred(0);
            }

            if (res.data.result.hasOwnProperty("monthlyCashTopup")) {
              setMonthlyCashTopup(res.data.result.monthlyCashTopup);
            } else {
              setMonthlyCashTopup(0);
            }

            if (res.data.result.hasOwnProperty("totalSuperAgentBalance")) {
              setTotalSuperAgentBalance(res.data.result.totalSuperAgentBalance);
            }
            if (res.data.result.hasOwnProperty("totalSubAgentBalance")) {
              setTotalSubAgentBalance(res.data.result.totalSubAgentBalance);
            }
            if (res.data.result.hasOwnProperty("monthlyReceived")) {
              setMonthlyReceived(res.data.result.monthlyReceived);
            } else {
              setMonthlyReceived(0);
            }

            if (res.data.result.hasOwnProperty("totalCashTopup")) {
              setTotalCashTopup(res.data.result.totalCashTopup);
            } else {
              setTotalCashTopup(0);
            }

            if (res.data.result.hasOwnProperty("totalTransfers")) {
              setTransferAmt(res.data.result.totalTransfers);
            } else {
              setTransferAmt(0);
            }

            if (
              res.data.result.hasOwnProperty("subAgentCommissionCumulative")
            ) {
              setSubAgentCommissionCumulative(
                res.data.result?.subAgentCommissionCumulative[0]
                  ?.commissionAmount
              );
            } else {
              setSubAgentCommissionCumulative(0);
            }

            if (res.data.result.hasOwnProperty("subagentList")) {
              setSubagentList(res.data.result.subagentList);
            } else {
              setSubagentList([]);
            }

            if (res.data.result.hasOwnProperty("superagentList")) {
              setSuperagentList(res.data.result.superagentList);
            } else {
              setSuperagentList([]);
            }
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setBalLoading(false);
        notifyFailed("Internal Server Error");
      });
  };

  const getAmt = (amt) => {
    return amt.toLocaleString();
  };

  /**========================= MTD Wallet Transfer ===================================== */

  useEffect(() => {
    props.showAdminMTDWalletTransLoader(true);
    setAdminMTDWalletTrans();
  }, [props.adminMTDWalletTransMonth]);

  useEffect(() => {
    props.showAdminMTDWalletTransLoader(true);
    setAdminMTDWalletTrans();
  }, [props.adminMTDWalletTransYear]);

  useEffect(() => {
    props.showSubAgentMTDWalletTransLoader(true);
    setSubAgentMTDWalletTrans();
  }, [props.subAgentMTDWalletTransMonth]);

  useEffect(() => {
    props.showSubAgentMTDWalletTransLoader(true);
    setSubAgentMTDWalletTrans();
  }, [props.subAgentMTDWalletTransYear]);

  const setAdminMTDWalletTrans = () => {
    var errorMsg = "";
    var data = {
      Year: Number(props.adminMTDWalletTransYear),
      Month: Number(props.adminMTDWalletTransMonth) + 1,
      Status: "Paid",
    };
    if (!data.Month || !data.Year) {
      return;
    }
    postMethod("/api/dashboard/MonthlyCustomerCashTopup", data, "post")
      .then((res) => {
        if (res && res.data) {
          if (
            res.data.responseCode === "200" &&
            res.data.result &&
            res.data.result.list
          ) {
            // Success
            let month = Number(props.adminMTDWalletTransMonth);
            let year = Number(props.adminMTDWalletTransYear);
            let totalDays = daysInMonth(month + 1, year);
            let arr = daysInArray(totalDays);
            let sendArr = [];
            let getArr = [];

            for (let i = 0; i < arr.length; i++) {
              let val = res.data.result.list.find((l) => l.day == arr[i]);
              if (val && val.hasOwnProperty("transferFrom")) {
                getArr.push(Number(val.transferFrom));
              } else {
                getArr.push(0);
              }
              if (val && val.hasOwnProperty("transferTo")) {
                sendArr.push(Number(val.transferTo));
              } else {
                sendArr.push(0);
              }
            }
            let mtdChart = subsMTDWalletTrans({
              daysArr: arr,
              getArr: getArr,
              sendArr: sendArr,
            });
            props.showAdminMTDWalletTrans(mtdChart);
            props.showAdminMTDWalletTransLoader(false);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching charts";
            notifyFailed(errorMsg);
            props.showAdminMTDWalletTransLoader(false);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching charts";
            notifyFailed(errorMsg);
            props.showAdminMTDWalletTransLoader(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          props.showAdminMTDWalletTransLoader(false);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
        props.showAdminMTDWalletTransLoader(false);
      });
  };

  const setSubAgentMTDWalletTrans = () => {
    var errorMsg = "";
    var data = {
      Year: Number(props.subAgentMTDWalletTransYear),
      Month: Number(props.subAgentMTDWalletTransMonth) + 1,
      Status: "Paid",
    };
    if (!data.Month || !data.Year) {
      return;
    }
    postMethod("/api/dashboard/MonthlySubAgentCommissionMTD", data, "post")
      .then((res) => {
        if (res && res.data) {
          if (
            res.data.responseCode === "200" &&
            res.data.result &&
            res.data.result.list
          ) {
            // Success
            let month = Number(props.subAgentMTDWalletTransMonth);
            let year = Number(props.subAgentMTDWalletTransYear);
            let totalDays = daysInMonth(month + 1, year);
            let arr = daysInArray(totalDays);
            let sendArr = [];
            let getArr = [];

            for (let i = 0; i < arr.length; i++) {
              let val = res.data.result.list.find((l) => l.day == arr[i]);
              if (val && val.hasOwnProperty("transferFrom")) {
                getArr.push(Number(val.transferFrom));
              } else {
                getArr.push(0);
              }
              if (val && val.hasOwnProperty("transferTo")) {
                sendArr.push(Number(val.transferTo));
              } else {
                sendArr.push(0);
              }
            }
            let mtdChart = subsMTDWalletTrans({
              daysArr: arr,
              getArr: getArr,
              sendArr: sendArr,
            });
            props.showSubAgentMTDWalletTrans(mtdChart);
            props.showSubAgentMTDWalletTransLoader(false);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching charts";
            notifyFailed(errorMsg);
            props.showSubAgentMTDWalletTransLoader(false);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching charts";
            notifyFailed(errorMsg);
            props.showSubAgentMTDWalletTransLoader(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          props.showSubAgentMTDWalletTransLoader(false);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
        props.showSubAgentMTDWalletTransLoader(false);
      });
  };

  const showAgentsList = (list) => {
    return list.map((item, idx) => (
      <div className="d-flex justify-content-between w-100">
        <h5>{item?.firstName} {item?.lastName}</h5>
        <h5>{getAmt(item?.balance)}</h5>
      </div>
    ))
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Available Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalAmt)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Balance Transferred</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i class="fas fa-university"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(balanceTransferred)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Monthly Cash Topup</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i class="fas fa-money-check-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(monthlyCashTopup)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Monthly Received</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-wave"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(monthlyReceived)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>

              {/* <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Wallet Transfer</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i class="fas fa-envelope-open-text"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? 
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> : 
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(transferAmt)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>
              </Col>
             */}

              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Cash Topup</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i class="fas fa-wallet"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalCashTopup)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Sub Agent Commission Cumulative</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(subAgentCommissionCumulative)}
                        </span>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0" style={{cursor:"pointer"}} onClick={() => setShowSuperAgentsList(!showSuperAgentsList)}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Super Agent Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i class="ni ni-circle-08 text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalSuperAgentBalance)}
                        </span>
                      )}
                    </p>
                    {
                      showSuperAgentsList && <Row className="border-top border-gray mt-4 p-2">
                      {showAgentsList(superagentList)}
                    </Row>
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4" md="6">
                <Card className="card-stats mb-4 mb-xl-0" style={{cursor:"pointer"}} onClick={() => setShowSubAgentsList(!showSubAgentsList)}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Sub Agent Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="ni ni-circle-08 text-white"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {balanceLoading ? (
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div>
                      ) : (
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(totalSubAgentBalance)}
                        </span>
                      )}
                    </p>
                    {
                      showSubAgentsList && <Row className="border-top border-gray mt-4 p-2">
                      {showAgentsList(subagentList)}
                    </Row>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MasterAgentHeader;
