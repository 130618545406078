import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Col,
  Badge
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { getLoginType, postMethod } from "../../services/httpServices";
import { useToasts } from 'react-toast-notifications';
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { btnCss, iconCss } from '../../appConfig';
import ReactPaginate from 'react-paginate';
import { getSortCls, getAmt } from "services/util";
import moment from "moment";
import { SettleBankUserStatus } from "appConfig";
import CashWithDrawals from "./cash-withdrawal";
import AddTopUpSubscriber from "./add-top-up-subscriber";

const STATUS = SettleBankUserStatus;

const sortIS = {
  sortName: 0,
  sortPhone: 0,
  sortDate: 0,
  sortAmount: 0,
  sortFees: 0,
  sortStatus: 0
};

const TopUpSubscriberList = () => {

  const [loading, setLoading] = React.useState(false);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [pagesCount, setPageCount] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [totalRec, setTotalRec] = React.useState(0);
  const [sort, setSort] = React.useState(sortIS);
  const [refresh, setRefresh] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);
  let loginType = getLoginType()

  const { addToast } = useToasts();

  /**======================== React Hooks ==========================*/

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        if (currentPage != 0) {
          setCurrentPage(0);
        } else {
          fetch();
        }
      }
    }
  }, [searchText]);


  React.useEffect(() => {
    fetch();
  }, [currentPage]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetch();
      }
    }
  }, [sort]);

  React.useEffect(()=> {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetch();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);


  /**======================== File Functions ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const getSearch = (val) => {
    setSearchText(val);
  };

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  }

  const closeModal = () => {
    setOpen(false);
    fetch();
  }

  const openModal = () => {
    setOpen(true);
  }


  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }

  const statusFormat = (val) => {
    let ob = STATUS.find((s)=> s.key === val);
    return ob && ob.value ? (
      <Badge color="" className="badge-dot mr-4">
        <i className={ob.className}/>
        {ob.value}
      </Badge>
    ) : val
  };


  /**======================== Validation Functions ==========================*/











  /**======================== API Calls ==========================*/

  const fetch = async () => {
    let errorMsg = '';
    setLoading(true);

    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortName == "1") {
      data['SortingOn'] = 'PaymentTo';
      data['IsDescending'] = false;
    } else if (sort.sortName == "2") {
      data['SortingOn'] = 'PaymentTo';
      data['IsDescending'] = true;
    } else if (sort.sortPhone == "1") {
      data['SortingOn'] = 'SubMobNumber';
      data['IsDescending'] = false;
    } else if (sort.sortPhone == "2") {
      data['SortingOn'] = 'SubMobNumber';
      data['IsDescending'] = true;
    } else if (sort.sortDate == "1") {
      data['SortingOn'] = 'CreatedAt';
      data['IsDescending'] = false;
    } else if (sort.sortDate == "2") {
      data['SortingOn'] = 'CreatedAt';
      data['IsDescending'] = true;
    } else if (sort.sortAmount == "1") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = false;
    } else if (sort.sortAmount == "2") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = true;
    } else if (sort.sortFees == "1") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = false;
    } else if (sort.sortFees == "2") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = true;
    } else if (sort.sortStatus == "1") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = false;
    } else if (sort.sortStatus == "2") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = true;
    }
    let url = "";
    if(loginType === "Agent") {
      url = "/api/Settlement/SubAgentTopUpList"
    } else {
      url = '/api/Settlement/MerchantTopUpList';
    }

    postMethod(url, data,  'post')
      .then(res => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (
          res && res.data
        ) {

          if (res.data.responseCode === "200" &&
            res.data.result) {
            // Success
            if (
              !res.data.result.list ||
              !res.data.result.list.length
            ) {
              res.data.result.list = [];
            }
            setFilteredList(res.data.result.list)
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed('Internal Server Error');
      });
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={e => { e.preventDefault(); }}>
                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search"
                        type="text"
                        value={searchText || ""}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  &nbsp;&nbsp;

                  <Button className="my-4" color="primary"
                    onClick={openModal}>
                    {loginType === "Merchant" ? "Cash withdrawal" : loginType === "Agent" ? "Cash Topup" : null }
                  </Button>

                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh">
                    <i className="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>


                </Form>


              </CardHeader>


              <Modal isOpen={open}
                // style={{ width: "80vw", maxWidth: "80vw" }}
              >
                <ModalBody style={{ padding: 0 }}>
                  {
                    loginType === "Merchant" ? <CashWithDrawals  closeModal={closeModal}/> : loginType === "Agent" ? <AddTopUpSubscriber closeModal={closeModal} /> : null
                  }
                  
                </ModalBody>
              </Modal>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortName, 'sortName')}>
                      Name
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortName)}
                          onClick={() => sortHandle(sort.sortName, 'sortName')}
                        />
                      </button>
                    </th> 
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortPhone, 'sortPhone')}>
                      Phone
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortPhone)}
                          onClick={() => sortHandle(sort.sortPhone, 'sortPhone')}
                        />
                      </button>
                    </th>
                    <th scope="col" onClick={() => sortHandle(sort.sortDate, 'sortDate')}>
                      Date
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortDate)}
                          onClick={() => sortHandle(sort.sortDate, 'sortDate')}
                        />
                      </button>
                    </th>
                    <th scope="col" onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}>
                      Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortAmount)}
                          onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}
                        />
                      </button>
                    </th>
                    <th scope="col" onClick={() => sortHandle(sort.sortFees, 'sortFees')}>
                      Commission Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortFees)}
                          onClick={() => sortHandle(sort.sortFees, 'sortFees')}
                        />
                      </button>
                    </th>
                    {/* <th scope="col" onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}>
                      Status
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortStatus)}
                          onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}
                        />
                      </button>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                {filteredList.length > 0 && filteredList.map((mmo, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">
                        {mmo.paymentTo}
                      </th>
                      <td>({mmo.subMobileCode}) - {mmo.subMobileNumber}</td>
                      <td>{moment(mmo.createdAt).format('lll')}</td>
                      <td>{getAmt(mmo.amount)}</td>
                      <td>{getAmt(mmo.commissionAmount)}</td>
                      {/* <td>{statusFormat(mmo.status)}</td> */}
                    </tr>

                  )
                })}
                </tbody>
              </Table>
              {loading && <FullPageLoader label={"Fetching..."} />}
              {!loading && !filteredList.length && <NoRecordFound />}

              <CardFooter className="py-4">
              {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )

}

export default TopUpSubscriberList;