import Index from "views/Index.js";
import Login from "views/examples/Login.js";
// import MobileMoneyList from 'views/mobile-money-list';
import BankList from "views/bank/bank-list";
// import MerchantList from "views/merchant/merchant-list";
import AddSubscriber from "views/add-subscriber";
import ChangePassword from "views/change-password";
import ForgotPassword from "views/examples/forgot-pswd";
import SubscriberList from "views/subscriber/subscriber-list";
import UserList from "views/users/user-list";
import BankPaymentList from "views/bank/payment-list";
import TopUpWallet from "views/subscriber/top-up";
import SubsTransaction from "views/subscriber/subs-trans";
// import MakePayment from "views/subscriber/make-payment";
// import AgentMakePayment from "views/sub-agent/make-payment";
import CollectionList from "views/finance-user/collection-list";
// import SettlementList from "views/settlement-bank/settle-merchant-payment";
import PayWithVoucher from "views/examples/pay-with-voucher";
import TopUpHistory from "views/subscriber/topup-history-list";
import SHOWMSG from "views/examples/show-msg";
import MerchantPaymentList from "views/merchant/payments";
import SearchTransaction from "views/examples/search-transaction";
import DayEndReport from "views/merchant/day-end-report";
import Reports from "views/report/reports";
import SignupSubscriber from "views/sign-up/signup-subscriber";
import ChangePin from "views/subscriber/change-pin";
// import PosTerminalList from "views/pos-terminal/pos-terminal-list";
import CommissionList from "views/commission/commission-list";
import BankCommission from "views/commission/bank-commission-list";
// import MMOCommission from "views/commission/mmo-commission-list";
// import MerchantCommission from "views/commission/merchant-commission";
import CollectionCommission from "views/finance-user/commission-list";
import BankCollectionCommission from "views/bank/commission-list";
import SubscriberVerificationList from "views/bank/subscriber-verification";
// import CheckBal from "views/merchant/check-balance";
import TransferMoney from "views/subscriber/transfer-money";
import TopUpSubscriberList from "views/top-up-subscriber/top-up-subscriber-list";
import Logs from "views/logs/logs";
import privacyPolicy from "views/examples/privacy-policy";
import PaymentInfo from "views/payment-info";
import AddSuperAgent from "views/super-agent/add-super-agent";
import SuperAgentList from "views/super-agent/super-agent-list";
import AddSubAgent from "views/sub-agent/add-sub-agent";
import SubAgentsList from "views/sub-agent/sub-agent-list";
import MasterAgentsList from "views/master-agent/master-agent-list";
import AddMasterAgent from "views/master-agent/add-master-agent";
import topUp from "views/subscriber/top-up";
import EDSATransactionsMerchant from "views/merchant/EDSATransactionsMerchant";

const routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: Index,
    layout: "/admin",
    allow: [
      "Admin",
      "Finance",
      "Bank",
      "SettlementBank",
      "Subscriber",
      "Super Agent",
      "Agent",
      "Master Agent",
    ],
  },
  {
    path: "/user-list",
    name: "Users",
    icon: "fa fa-users text-grey",
    component: UserList,
    layout: "/admin",
    allow: [
      "Admin",
      "Finance",
      "Operations Officer",
      "Customer Support Supervisor",
      "Audits and Compliance",
      "Sales Supervisor",
      "Sales Representative",
      "Customer Support Representative",
      "Operation supervisor",
    ],
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-grey",
    component: Login,
    layout: "/auth",
  },
  {
    //Privay-policy
    path: "/privacy-policy",
    name: "Privacy policy",
    layout: "/auth",
    icon: "ni ni-key-25 text-grey",
    component: privacyPolicy,
  },
  // {
  //   path: "/mobile-money",
  //   name: "MM Operators",
  //   icon: "ni ni-bullet-list-67 text-grey",
  //   component: MobileMoneyList,
  //   layout: "/admin",
  //   allow: [
  //     'Admin', 'Finance', 'Operations Officer',
  //     'Customer Support Supervisor',
  //     'Audits and Compliance',
  //     'Sales Supervisor',
  //     'Sales Representative',
  //     'Customer Support Representative',
  //     'Operation supervisor'
  //   ]
  // },
  {
    path: "/banks",
    name: "Banks",
    icon: "ni ni-bag-17 text-grey",
    component: BankList,
    layout: "/admin",
    allow: [
      "Admin",
      "Finance",
      "Operations Officer",
      "Customer Support Supervisor",
      "Audits and Compliance",
      "Sales Supervisor",
      "Sales Representative",
      "Customer Support Representative",
      "Operation supervisor",
    ],
  },
  // {
  //   path: "/merchant",
  //   name: "Merchants",
  //   icon: "ni ni-single-02 text-grey",
  //   component: MerchantList,
  //   layout: "/admin",
  //   allow: [
  //     'Admin', 'Finance', 'Operations Officer',
  //     'Customer Support Supervisor',
  //     'Audits and Compliance',
  //     'Sales Supervisor',
  //     'Sales Representative',
  //     'Customer Support Representative',
  //     'Operation supervisor'
  //   ]
  // },
  // {
  //   path: "/pos-terminals",
  //   name: "POS Terminals",
  //   icon: "fas fa-mobile-alt text-grey",
  //   component: PosTerminalList,
  //   layout: "/admin",
  // },
  {
    path: "/commissions",
    name: "Commissions",
    icon: "fas fa-wallet text-grey",
    component: CommissionList,
    layout: "/admin",
    allow: [
      "Admin",
      "Finance",
      "Operations Officer",
      "Customer Support Supervisor",
      "Audits and Compliance",
      "Sales Supervisor",
      "Sales Representative",
      "Customer Support Representative",
      "Operation supervisor",
    ],
  },
  {
    path: "/bank-commissions",
    name: "Bank Commission",
    icon: "fas fa-university text-grey",
    component: BankCommission,
    layout: "/admin",
    allow: [
      "Finance",
      "Operations Officer",
      "Customer Support Supervisor",
      "Audits and Compliance",
      "Sales Supervisor",
      "Customer Support Representative",
      "Operation supervisor",
    ],
  },
  // {
  //   path: "/mmo-commissions",
  //   name: "MMO Commission",
  //   icon: "fas fa-money-check text-grey",
  //   component: MMOCommission,
  //   layout: "/admin",
  //   allow: ['Finance', 'Operations Officer',
  //     'Customer Support Supervisor',
  //     'Audits and Compliance','Sales Supervisor','Customer Support Representative',
  //     'Operation supervisor']
  // },
  // {
  //   path: "/merchant-commissions",
  //   name: "Merchant Commission",
  //   icon: "fas fa-money-check-alt text-grey",
  //   component: MerchantCommission,
  //   layout: "/admin",
  //   allow: [
  //     "Finance",
  //     "Operations Officer",
  //     "Audits and Compliance",
  //     "Customer Support Supervisor",
  //     "Sales Supervisor",
  //     "Customer Support Representative",
  //     "Operation supervisor",
  //   ],
  // },
  {
    path: "/subscriber",
    name: "Register Subscriber",
    icon: "ni ni-circle-08 text-grey",
    component: AddSubscriber,
    layout: "/admin",
    allow: [
      "Operations Officer",
      "Customer Support Supervisor",
      "Sales Supervisor",
      "Customer Support Representative",
      "Operation supervisor",
    ],
  },
  {
    path: "/master-agent",
    name: "Register Master Agent",
    icon: "ni ni-circle-08 text-grey",
    component: AddMasterAgent,
    layout: "/admin",
    allow: ["Admin"],
  },
  {
    path: "/super-agent",
    name: "Register Super Agent",
    icon: "ni ni-circle-08 text-grey",
    component: AddSuperAgent,
    layout: "/admin",
    allow: ["Admin"],
  },
  {
    path: "/sub-agent",
    name: "Register Sub Agent",
    icon: "ni ni-circle-08 text-grey",
    component: AddSubAgent,
    layout: "/super-agent",
    allow: ["Super Agent"],
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "ni ni-lock-circle-open",
    component: ChangePassword,
    layout: "/admin",
    common: true,
    hideFromMenu: true,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    icon: "ni ni-lock-circle-open",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/subscriber-list",
    name: "Subscribers",
    icon: "ni ni-chart-pie-35 text-grey",
    component: SubscriberList,
    layout: "/admin",
    allow: [
      "Admin",
      "Finance",
      "Operations Officer",
      "Customer Support Supervisor",
      "Audits and Compliance",
      "Sales Supervisor",
      "Sales Representative",
      "Customer Support Representative",
      "Operation supervisor",
    ],
  },
  {
    path: "/master-agent-list",
    name: "Master Agents",
    icon: "ni ni-chart-pie-35 text-grey",
    component: MasterAgentsList,
    layout: "/admin",
    allow: ["Admin"],
  },
  {
    path: "/super-agent-list",
    name: "Super Agents",
    icon: "ni ni-chart-pie-35 text-grey",
    component: SuperAgentList,
    layout: "/admin",
    allow: ["Admin"],
  },
  {
    path: "/sub-agent-list",
    name: "Sub Agents",
    icon: "ni ni-chart-pie-35 text-grey",
    component: SubAgentsList,
    layout: "/super-agent",
    allow: ["Super Agent", "Admin"],
  },
  {
    path: "/bank-payment-list",
    name: "Payments",
    icon: "fa fa-money-check text-black",
    component: BankPaymentList,
    layout: "/bank",
    allow: ['Admin', 'Finance', 'Bank']
  },
  {
    path: "/bank-commission-list",
    name: "Commission Collection",
    icon: "fa fa-money-check-alt text-grey",
    component: BankCollectionCommission,
    layout: "/bank",
    allow: ["Admin", "Finance", "Bank"],
  },
  {
    path: "/bank-subscriber-list",
    name: "Subscribers",
    icon: "fa fa-user-friends text-grey",
    component: SubscriberVerificationList,
    layout: "/bank",
  },
  {
    path: "/top-up",
    name: "Top-Up",
    icon: "fa fa-money-check text-grey",
    component: TopUpWallet,
    layout: "/subscriber",
  },
  // {
  //   path: "/top-up-old",
  //   name: "Top-Up old",
  //   icon: "fa fa-money-check text-grey",
  //   component: topUp,
  //   layout: "/subscriber",
  // },
  {
    path: "/top-up",
    name: "Top-Up",
    icon: "fa fa-money-check text-grey",
    component: topUp,
    layout: "/master-agent",
  },
  {
    path: "/subscriber-transaction",
    name: "Transactions",
    icon: "fa fa-address-card text-grey",
    component: SubsTransaction,
    layout: "/subscriber",
  },
  {
    path: "/transfer-money",
    name: "Transfer Money",
    icon: "fa fa-money-check-alt text-grey",
    component: TransferMoney,
    layout: "/subscriber",
    allow: ["Super Agent", "Subscriber", "Master Agent", "Agent"],
  },
  {
    path: "/cash-out",
    name: "Cash out",
    icon: "fa fa-money-check-alt text-grey",
    component: TransferMoney,
    layout: "/subscriber",
    allow: ["Subscriber"],
  },
  {
    path: "/agent-transaction",
    name: "Transactions",
    icon: "fa fa-address-card text-grey",
    component: SubsTransaction,
    layout: "/master-agent",
  },
  // {
  //   path: "/make-payment",
  //   name: "Make Payment",
  //   icon: "fa fa-money-bill text-grey",
  //   component: MakePayment,
  //   layout: "/subscriber",
  // },
  // {
  //   path: "/make-payment",
  //   name: "Make Payment",
  //   icon: "fa fa-money-bill text-grey",
  //   component: AgentMakePayment,
  //   layout: "/sub-agent",
  // },
  {
    path: "/collection-list",
    name: "Collection",
    icon: "fa fa-money-bill text-grey",
    component: CollectionList,
    layout: "/finance",
  },
  {
    path: "/commision-collection-list",
    name: "Commission",
    icon: "fa fa-money-check-alt text-grey",
    component: CollectionCommission,
    layout: "/finance",
    allow: ["Finance", "Admin"],
  },
  // {
  //   path: "/merchant-settlement",
  //   name: "Merchant Settlements",
  //   icon: "fa fa-money-bill text-grey",
  //   component: SettlementList,
  //   layout: "/settlementBank",
  //   allow: ["Finance", "Admin", "SettlementBank"],
  // },
  {
    path: "/paywith-voucher/:id",
    name: "Paywith Voucher",
    icon: "ni ni-key-25 text-info",
    component: PayWithVoucher,
    layout: "/auth",
  },
  {
    path: "/topup-history",
    name: "Top-up History",
    icon: "fa fa-money-bill text-grey",
    component: TopUpHistory,
    layout: "/subscriber",
  },
  {
    path: "/show-msg",
    name: "Show message",
    icon: "fa fa-money-bill text-grey",
    component: SHOWMSG,
    layout: "/auth",
  },
  {
    path: "/top-up-list",
    name: "Withdraw cash",
    icon: "fa fa-money-bill text-grey",
    component: TopUpSubscriberList,
    layout: "/merchant",
  },
  {
    path: "/top-up-agent",
    name: "Topup List",
    icon: "fa fa-money-bill text-grey",
    component: TopUpSubscriberList,
    layout: "/sub-agent",
  },
  {
    path: "/agent-transaction",
    name: "Transactions",
    icon: "fa fa-address-card text-grey",
    component: SubsTransaction,
    layout: "/sub-agent",
  },
  {
    path: "/super-agent-transaction",
    name: "Transactions",
    icon: "fa fa-address-card text-grey",
    component: SubsTransaction,
    layout: "/super-agent",
  },
  {
    path: "/merchant-payment",
    name: "Payment List",
    icon: "fa fa-money-bill text-grey",
    component: MerchantPaymentList,
    layout: "/merchant",
  },
  // {
  //   path: "/check-balance",
  //   name: "Check Balance",
  //   icon: "fa fa-wallet text-grey",
  //   component: CheckBal,
  //   layout: "/merchant",
  // },
  {
    path: "/search-transaction",
    name: "Search Transaction",
    icon: "fas fa-address-card text-grey",
    component: SearchTransaction,
    layout: "/admin",
    allow: [
      "Admin",
      "Merchant",
      "Bank",
      "SettlementBank",
      "Operations Officer",
      "Finance",
      "Customer Support Supervisor",
      "Audits and Compliance",
      "Customer Support Representative",
      "Operation supervisor",
    ],
  },
  {
    path: "/EDSATransactionsMerchant",
    name: "Search EDSA Transaction",
    icon: "fas fa-address-card text-grey",
    component: EDSATransactionsMerchant,
    layout: "/merchant",
  },
  {
    path: "/day-end-report",
    name: "Day End Report",
    icon: "fas fa-archive text-grey",
    component: DayEndReport,
    layout: "/merchant",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "fas fa-archive text-grey",
    component: Reports,
    layout: "/admin",
    allow: ["Finance", "Audits and Compliance", "Operation supervisor"],
  },
  {
    path: "/sign-up",
    name: "Register Subscriber",
    icon: "ni ni-key-25 text-info",
    component: SignupSubscriber,
    layout: "/auth",
  },
  {
    path: "/change-Pin",
    name: "Change Pin",
    icon: "ni ni-lock-circle-open",
    component: ChangePin,
    layout: "/admin",
    common: true,
    hideFromMenu: true,
  },
  {
    path: "/logs",
    name: "Logs",
    icon: "fas fa-book text-grey",
    component: Logs,
    layout: "/admin",
    allow: ["Audits and Compliance", "Finance", "Operation supervisor"],
  },
  {
    path: "/payment-status",
    name: "Payment status",
    icon: "ni ni-key-25 text-info",
    component: PaymentInfo,
    layout: "/auth",
  },
];

export default routes;
