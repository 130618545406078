import React, { useEffect } from "react";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { Table, Badge } from "reactstrap";
import moment from "moment";
import { getSortCls, reportloader } from "services/util";
import { btnCss, subsStatus } from "../../appConfig";
import FullPageLoader from "components/FullPageLoader/fullpageloader";

const sortIS = {
  sortPaymentTo: 0,
  sortPaymentFrom: 0,
  sortReferenceId: 0,
  sortProductId: 0,
  sortCreatedAt: 0,
  sortAmount: 0,
  sortFeesAmount: 0,
  sortStatus: 0,
  sortPaymentMethod: 0,
  sortPaymentType: 0,
};

const reportloaderContainer = {
  position: "absolute",
  top: "240px",
  left: 0,
  width: "100%",
  heigth: "100%",
  backgroundColor: "#F8F8F8AD",
  // display: 'none'
};

const STATUS = subsStatus;

const AgentCommissionsReportTable = (props) => {
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [sort, setSort] = React.useState(sortIS);

  useEffect(() => {
    setFilteredList(props.datalist);
  }, [props.datalist]);

  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  useEffect(() => {
    setPageSize(props.pageSize);
  }, [props.pageSize]);

  useEffect(() => {
    props.setParentSort(sort);
  }, [sort]);

  const getAmt = (amt) => {
    return amt.toLocaleString();
  };

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = "1";
    } else if (fieldValue == "1") {
      newValue = "2";
    } else if (fieldValue == "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  };

  const getPaymentType = (type) => {
    let str = type;
    if (type == "topup") {
      str = "Top Up";
    } else if (type == "BalanceTransfer") {
      str = "Balance Transfer";
    } else if (type == "Merchant") {
      str = "Merchant Payment";
    } else if (type == "WalletPayment") {
      str = "Wallet Payment";
    } else if (type == "CashTopUp") {
      str = "Cash Top Up";
    }
    return str;
  };

  const statusFormat = (val) => {
    let ob = STATUS.find((s) => s.key === val);
    // return ob && ob.value ? ob.value : val;
    return ob && ob.value ? (
      <Badge color="" className="badge-dot mr-4">
        <i className={ob.className} />
        {ob.value}
      </Badge>
    ) : (
      val
    );
  };

  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th
              scope="col"
              onClick={() =>
                sortHandle(sort.sortPaymentMethod, "sortPaymentMethod")
              }
            >
              Sub-Agent Name
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortPaymentMethod)}
                  onClick={() =>
                    sortHandle(sort.sortPaymentMethod, "sortPaymentMethod")
                  }
                />
              </button>
            </th>

            <th
              scope="col"
              onClick={() => sortHandle(sort.sortPaymentTo, "sortPaymentTo")}
            >
              Period Commission
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortPaymentTo)}
                  onClick={() =>
                    sortHandle(sort.sortPaymentTo, "sortPaymentTo")
                  }
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() =>
                sortHandle(sort.sortPaymentFrom, "sortPaymentFrom")
              }
            >
              Period % Share
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortPaymentFrom)}
                  onClick={() =>
                    sortHandle(sort.sortPaymentFrom, "sortPaymentFrom")
                  }
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() =>
                sortHandle(sort.sortPaymentMethod, "sortPaymentMethod")
              }
            >
              Cumulative % Share
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortPaymentMethod)}
                  onClick={() =>
                    sortHandle(sort.sortPaymentMethod, "sortPaymentMethod")
                  }
                />
              </button>
            </th>

            {/* <th
              scope="col"
              onClick={() =>
                sortHandle(sort.sortReferenceId, "sortReferenceId")
              }
            >
              Smart Pay Id
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortReferenceId)}
                  onClick={() =>
                    sortHandle(sort.sortReferenceId, "sortReferenceId")
                  }
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() => sortHandle(sort.sortProductId, "sortProductId")}
            >
              Product Id
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortProductId)}
                  onClick={() =>
                    sortHandle(sort.sortProductId, "sortProductId")
                  }
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() => sortHandle(sort.sortCreatedAt, "sortCreatedAt")}
            >
              Date
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortCreatedAt)}
                  onClick={() =>
                    sortHandle(sort.sortCreatedAt, "sortCreatedAt")
                  }
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() => sortHandle(sort.sortAmount, "sortAmount")}
            >
              Amount
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortAmount)}
                  onClick={() => sortHandle(sort.sortAmount, "sortAmount")}
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() => sortHandle(sort.sortFeesAmount, "sortFeesAmount")}
            >
              Fees Amount
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortFeesAmount)}
                  onClick={() =>
                    sortHandle(sort.sortFeesAmount, "sortFeesAmount")
                  }
                />
              </button>
            </th>
            <th scope="col">Description</th>
            <th
              scope="col"
              onClick={() => sortHandle(sort.sortStatus, "sortStatus")}
            >
              Status
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortStatus)}
                  onClick={() => sortHandle(sort.sortStatus, "sortStatus")}
                />
              </button>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {filteredList.length > 0 &&
            filteredList.map((mmo, i) => {
              return (
                <tr>
                  <th>{getPaymentType(mmo.paymentType)}</th>
                  <td scope="row">{mmo.paymentTo}</td>
                  <td>{mmo.paymentFrom}</td>
                  <td>{mmo.paymentMethod}</td>
                  {/* <td>{mmo.referenceId}</td>
                  <td>{mmo.productId ? mmo.productId : "--"}</td>
                  <td>{moment(mmo.createdAt).format("lll")}</td>
                  <td>{getAmt(mmo.amount)}</td>
                  <td>{getAmt(mmo.feesAmount)}</td>
                  <td>{mmo.description}</td>
                  <td>{statusFormat(mmo.status)}</td> */}
                </tr>
              );
            })}
        </tbody>
        {props.loading && (
          <FullPageLoader
            label={"Fetching..."}
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        )}
        {!props.loading && !filteredList.length && (
          <NoRecordFound
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        )}
      </Table>
    </>
  );
};
export default AgentCommissionsReportTable;
