import React, { useEffect } from "react";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { Table } from "reactstrap";
import moment from "moment";
import { getSortCls, reportloader } from "services/util";
import { btnCss } from "../../appConfig";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import { getLoginType } from "services/httpServices";
import { getAmt } from "services/util";

//change props
const sortIS = {
  sortMerchantName: 0,
  sortBankName: 0,
  sortAccountNo: 0,
  sortAmountDue: 0,
  sortCreatedAt: 0,
  sortStatus: 0,
};

const reportloaderContainer = {
  position: "absolute",
  top: "240px",
  left: 0,
  width: "100%",
  heigth: "100%",
  backgroundColor: "#F8F8F8AD",
  // display: 'none'
};

const PendingSettlementsReportTable = (props) => {
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [sort, setSort] = React.useState(sortIS);

  useEffect(() => {
    setFilteredList(props.datalist);
  }, [props.datalist]);

  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  useEffect(() => {
    setPageSize(props.pageSize);
  }, [props.pageSize]);

  useEffect(() => {
    props.setParentSort(sort);
  }, [sort]);

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = "1";
    } else if (fieldValue == "1") {
      newValue = "2";
    } else if (fieldValue == "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          padding: 24,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <strong>Report Date:</strong> {moment().format("Do MMMM, YYYY")}
          </div>
          <div style={{ width: "50%" }}>
            <strong>Time Generated:</strong> {moment().format("HH:mm")}
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <strong>No. of merchants:</strong>{" "}
            {props?.headings?.numberofMerchants}
          </div>
          <div style={{ width: "50%" }}>
            <strong>Activity Ratio:</strong>{" "}
            {props?.headings?.activityRatio &&
              props?.headings?.activityRatio?.toFixed(2)}%
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <strong>Total Amount Due:</strong>{" "}
            {getAmt(props?.headings?.overallDueAmount || 0)}
          </div>
          <div style={{ width: "50%" }}>
            <strong>Generated By:</strong> {getLoginType()}
          </div>
        </div>
      </div>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th
              scope="col"
              onClick={() =>
                sortHandle(sort.sortMerchantName, "sortMerchantName")
              }
            >
              Merchant Name
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortMerchantName)}
                  onClick={() =>
                    sortHandle(sort.sortMerchantName, "sortMerchantName")
                  }
                />
              </button>
            </th>

            <th
              scope="col"
              onClick={() => sortHandle(sort.sortBankName, "sortBankName")}
            >
              Bank Name
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortBankName)}
                  onClick={() => sortHandle(sort.sortBankName, "sortBankName")}
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() => sortHandle(sort.sortAccountNo, "sortAccountNo")}
            >
              Account No.
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortAccountNo)}
                  onClick={() =>
                    sortHandle(sort.sortAccountNo, "sortAccountNo")
                  }
                />
              </button>
            </th>
            <th
              scope="col"
              onClick={() => sortHandle(sort.sortAmountDue, "sortAmountDue")}
            >
              Amount Due
              <button style={btnCss}>
                <i
                  className={getSortCls(sort.sortAmountDue)}
                  onClick={() =>
                    sortHandle(sort.sortAmountDue, "sortAmountDue")
                  }
                />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length > 0 &&
            filteredList.map((mmo, i) => {
              return (
                <tr key={i}>
                  <th>{mmo?.merchantName}</th>
                  <td>{mmo?.merchantBankName}</td>
                  <td>{mmo?.merchantAccountNo}</td>
                  <td>{getAmt(mmo?.amount + mmo?.commissionAmount || 0)}</td>
                </tr>
              );
            })}
        </tbody>
        {props.loading && (
          <FullPageLoader
            label={"Fetching..."}
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        )}
        {!props.loading && !filteredList.length && (
          <NoRecordFound
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        )}
      </Table>
    </>
  );
};
export default PendingSettlementsReportTable;
