
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Badge,
  ModalHeader,
  Collapse,
  Col
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useToasts } from 'react-toast-notifications';
import { getMethod, postMethod } from "../../services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { connect } from "react-redux";
import { paymentList } from "./action";
import { collBankStatus, btnCss, iconCss } from "../../appConfig";
import moment from "moment";
import "bootstrap/js/src/collapse.js";
import { getAmt, getSortCls } from "services/util";
import ReactPaginate from 'react-paginate';


const STATUS = collBankStatus;

const sortIS = {
  sortReferenceId: 0,
  sortSubsName: 0,
  sortReferenceId: 0,
  sortAccNumber: 0,
  sortCreatedAt: 0,
  sortAmount: 0,
  sortFeesAmount: 0
};

const BankPaymentList = (props) => {


  const [banklist, setBankList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [openApproveModal, setOpenApproveModal] = React.useState(false);
  const [openRejectModal, setOpenRejectModal] = React.useState(false);
  const [approveObj, setApproveObj] = React.useState(null);
  const [rejectObj, setRejectObj] = React.useState(null);
  const [approveReason, setApproveReason] = React.useState(null);
  const [rejectReason, setRejectReason] = React.useState(null);
  const [errors, setError] = React.useState({});
  const [modalLoading, setModalLoading] = React.useState(false);
  const [statusFilter, setStatusFilter] = React.useState(STATUS[1].key);
  const [totalList, setTotalList] = React.useState([]);
  const [coreTransId, setCoreTransId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);
  const [totalRec, setTotalRec] = React.useState(0);
  const [sort, setSort] = React.useState(sortIS);
  const [isMounted, setIsMounted] = React.useState(false);

  const { addToast } = useToasts();


  React.useEffect(() => {
    fetchHandle();
  }, [currentPage]);

  React.useEffect(() => {
    setLoading(props.bank.paymentList.loading);
  }, [props.bank.paymentList.loading]);

  React.useEffect(() => {
    if (
      props.bank.paymentList.list
    ) {
      setTotalList(props.bank.paymentList.list);
      setFilteredList(props.bank.paymentList.list);
      setTotalRec(props.bank.paymentList.totalRec);
      let pc = Math.ceil(props.bank.paymentList.totalRec / pageSize);
      setPageCount(pc);
      // setListDataWrapper(props.bank.paymentList.list);
    } 
    setIsMounted(true);
    setRefresh(false);
  }, [props.bank.paymentList.list]);

  React.useEffect(() => {
    if (props.bank.paymentList.error) {
      setIsMounted(true);
      notifyFailed(props.bank.paymentList.errorMsg);
      setRefresh(false);
    }
  }, [props.bank.paymentList.error]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchHandle();
      }
    }
  }, [statusFilter]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchHandle();
      }
    }
  }, [sort]);

  React.useEffect(()=> {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchHandle();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        if (currentPage != 0) {
          setCurrentPage(0);
        } else {
          fetchHandle();
        }
      }
    }
  }, [searchText]);

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const handleClick = (e, index) => {

    e.preventDefault();
    setCurrentPage(index);

  }

  const getSearchHandle = (val, blist) => {
    let filteredList = [];
    let banklist = blist;
    for (let i = 0; i < banklist.length; i++) {
      if ((banklist[i].subscribers) &&
        (banklist[i].subscribers.firstName
          .toString()
          .trim()
          .toLowerCase()
          .includes(val.trim().toLowerCase())) ||
        ((banklist[i].subscribers) &&
          banklist[i].subscribers.lastName
            .toString()
            .trim()
            .toLowerCase()
            .includes(val.trim().toLowerCase())) ||
        ((banklist[i].subscribers) &&
          banklist[i].subscribers.bban.toString()
            .trim()
            .toLowerCase()
            .includes(val.trim().toLowerCase())) ||
        (banklist[i].paymentTo.toString()
          .trim()
          .toLowerCase()
          .includes(val.trim().toLowerCase())) ||
        (banklist[i].referenceId &&
          banklist[i].referenceId.toString()
            .trim()
            .toLowerCase()
            .includes(val.trim().toLowerCase()))
      ) {
        filteredList.push(banklist[i]);
      }
    }
    return filteredList;
  }

  const getSearch = (val) => {
    setSearchText(val);
  };

  const approve = (trans) => {
    setOpenApproveModal(true);
    setCoreTransId(null);
    setApproveObj(trans);
  }

  const reject = (trans) => {
    setOpenRejectModal(true);
    setCoreTransId(null);
    setRejectObj(trans);
  }

  const closeApproveModal = () => {
    setApproveObj(null);
    setError({});
    setApproveReason(null);
    setOpenApproveModal(false);
    setCoreTransId(null);
  }

  const closeRejectModal = () => {
    setModalLoading(false);
    setRejectObj(null);
    setError({});
    setRejectReason(null);
    setOpenRejectModal(false);
    setCoreTransId(null);
  }

  const approveValidate = () => {
    var errors = {};
    if (!approveReason) {
      errors.approveReason = 'Please provide notes';
    }
    if (!coreTransId) {
      errors.coreTransId = 'Please provide core banking transaction id';
    }
    return errors;
  }

  const rejectValidate = () => {
    var errors = {};
    if (!rejectReason) {
      errors.rejectReason = 'Please provide notes';
    }
    if (!coreTransId) {
      errors.coreTransId = 'Please provide core banking transaction id';
    }
    return errors;
  }

  const approvePayment = () => {
    let errorMsg = '';
    const validationErrors = approveValidate();
    setError(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      setModalLoading(true);
      var data = {
        'TransactionId': approveObj.transactionId,
        'Status': 'Approved',
        'Description': approveReason,
        'PaymentMethodTransactionId': coreTransId
      };
      let url = '/api/Transactions/ApproveRejectforMasterAgent/';
      postMethod(url, data, 'post')
      .then(res => {
        // setModalLoading(false);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            if (approveObj.paymentType == 'Merchant') {
              updateMerchant(approveObj, 'Paid');
            } else {
              setModalLoading(false);
              notifySuccess('Approved Successfuly');
              closeApproveModal();
              fetchHandle();
            }
          } else if (res.data.responseCode === "400") {
            // Error
            setModalLoading(false);
            errorMsg = res.data.responseMessage || 'Error in approving';
            closeApproveModal();
            notifyFailed(errorMsg);
            fetchHandle();
          } else {
            // Unknown Error
            setModalLoading(false);
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
            closeApproveModal();
            fetchHandle();
          }
        } else {
          setModalLoading(false);
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
          closeApproveModal();
          fetchHandle();
        }
      }).catch(err => {
        setModalLoading(false);
        errorMsg = 'Internal Server Error';
        notifyFailed(errorMsg);
        closeApproveModal();
        fetchHandle();
      });
    }
  }

  const rejectPayment = () => {

    let errorMsg = '';
    const validationErrors = rejectValidate();
    setError(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      setModalLoading(true);
      var data = {
        'TransactionId': rejectObj.transactionId,
        'Status': 'Rejected',
        'Description': rejectReason,
        'PaymentMethodTransactionId': coreTransId
      };
      let url = '/api/Transactions/approvereject/';
      postMethod(url, data, 'post')
        .then(res => {
          if (
            res && res.data
          ) {
            if (res.data.responseCode === "200") {
              // Success

              if (rejectObj.paymentType == 'Merchant') {
                updateMerchant(rejectObj, 'Rejected');
              } else {
                notifySuccess('Rejected Successfuly');
                closeRejectModal();
                fetchHandle();
              }
            } else if (res.data.responseCode === "400") {
              // Error
              errorMsg = res.data.responseMessage || 'Error in approving';
              closeRejectModal();
              notifyFailed(errorMsg);
              fetchHandle();
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in fetching';
              notifyFailed(errorMsg);
              closeRejectModal();
              fetchHandle();
            }
          } else {
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);
            closeRejectModal();
            fetchHandle();
          }
        }).catch(err => {
          setModalLoading(false);
          errorMsg = 'Internal Server Error';
          notifyFailed(errorMsg);
          closeRejectModal();
          fetchHandle();
        });
    }
    // let errorMsg = '';
    // if (!rejectReason) {
    //   setError({rejectReason: 'Please provide reject reason'});
    // } else {

    // }
  }


  const updateMerchant = (approveObj, status) => {
    var data = JSON.stringify({
      "MerchantId": Number(approveObj.merchantId),
      "ProductId": approveObj.productId,
      "Amount": approveObj.amount,
      "TransactRefId": approveObj.referenceId.toString(),
      "Status": status
    });
    let errorMsg = '';
    postMethod('/api/Transactions/PaymentUpdateTOMerchant', data, 'post')
    .then(res => {
      setModalLoading(false);
      if (res && res.data) {
        if (res.data.responseCode === "200") {
          let showmsg = 'Reject Successfuly';
          if (status == 'Paid') {
            showmsg = 'Approved Successfuly';
          }
          notifySuccess(showmsg);
          close(status);
        } else if (res.data.responseCode === "400") {
          // Error while saving
          errorMsg = res.data.responseMessage || 'Error while saving';
          notifyFailed(errorMsg);
          close(status);
        } else if (res.data.responseCode === "404") {
          // Error while saving
          errorMsg = res.data.responseMessage || 'Error while saving';
          notifyFailed(errorMsg);
          close(status);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in saving';
          notifyFailed(errorMsg);
          close(status);
        }
      } else {
        // Not valid response from server
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        close(status);
      }
    }).catch(err => {
      setLoading(false);
      setModalLoading(false);
      errorMsg = 'Internal Server Error';
      notifyFailed(errorMsg);
      close(status);
    });
  }

  const close = (status) => {
    if (status == 'Paid') {
      closeApproveModal();
      reloadList();
    } else if (status == 'Rejected') {
      closeRejectModal();
      reloadList();
    }
  }

  const getAmtHandle = (amt, feeAmt) => {
    if (!feeAmt) {
      feeAmt = 0;
    }
    let amount = Number(amt) + Number(feeAmt);
    return amount.toLocaleString();
  }

  const statusFormat = (val) => {
    let ob = STATUS.find((s) => s.key === val);
    // return ob && ob.value ? ob.value : val;
    return ob && ob.value ? (
      <Badge color="" className="badge-dot mr-4">
        <i className={ob.className} />
        {ob.value}
      </Badge>
    ) : val
  };

  const changeStatus = (val) => {
    var value = val;
    // setCurrentPage(0);
    setStatusFilter(value);
  }

  const setListDataWrapper = (resp) => {
    let arr = setListData(resp);
    if (arr && arr.length) {
      setLoading(false);
    }
    if (searchText) {
      arr = getSearchHandle(searchText, arr);
    }
    setBankList(arr);
    setFilteredList(arr);
    let pc = Math.ceil(arr.length / pageSize);
    setPageCount(pc);
  }

  const setListData = (resp) => {
    let arr = [];
    for (let i = 0; i < resp.length; i++) {
      if (resp[i].status === statusFilter) {
        arr.push(resp[i]);
      }
    }
    return arr;
  }

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }

  const fetchHandle = () => {
    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize,
      'Status': statusFilter
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortReferenceId == "1") {
      data['SortingOn'] = 'ReferenceId';
      data['IsDescending'] = false;
    } else if (sort.sortReferenceId == "2") {
      data['SortingOn'] = 'ReferenceId';
      data['IsDescending'] = true;
    } else if (sort.sortSubsName == "1") {
      data['SortingOn'] = 'SubscriberName';
      data['IsDescending'] = false;
    } else if (sort.sortSubsName == "2") {
      data['SortingOn'] = 'SubscriberName';
      data['IsDescending'] = true;
    } else if (sort.sortAccNumber == "1") {
      data['SortingOn'] = 'SubscriberAccountNo';
      data['IsDescending'] = false;
    } else if (sort.sortAccNumber == "2") {
      data['SortingOn'] = 'SubscriberAccountNo';
      data['IsDescending'] = true;
    } else if (sort.sortCreatedAt == "1") {
      data['SortingOn'] = 'CreatedAt';
      data['IsDescending'] = false;
    } else if (sort.sortCreatedAt == "2") {
      data['SortingOn'] = 'CreatedAt';
      data['IsDescending'] = true;
    } else if (sort.sortAmount == "1") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = false;
    } else if (sort.sortAmount == "2") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = true;
    } else if (sort.sortFeesAmount == "1") {
      data['SortingOn'] = 'FeesAmount';
      data['IsDescending'] = false;
    } else if (sort.sortFeesAmount == "2") {
      data['SortingOn'] = 'FeesAmount';
      data['IsDescending'] = true;
    }

    props.fetch(data);
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={e => { e.preventDefault(); }}>

                  <FormGroup>
                    <label
                      for="status"
                      className="form-control-label">
                      Status
                    </label>
                    &nbsp; &nbsp;
                    <div class="dropdown-border">
                      <Input
                        type="select"
                        id="status"
                        className="form-control-alternative status-filter"
                        name="statusFilter"
                        value={statusFilter}
                        onChange={(e) => changeStatus(e.target.value)}
                      >
                        {STATUS.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )}
                      </Input>
                    </div>
                  </FormGroup>

                  &nbsp; &nbsp;
                  <FormGroup className="mb-0 my-4 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  &nbsp;&nbsp;
                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh">
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>

                </Form>
              </CardHeader>

              <Modal isOpen={openApproveModal} className="modal-md">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    Approve Payment
                  </h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {approveObj && approveObj.status ?
                      <div>
                        <Row className="mb-3">
                          <Col>
                            <strong>Transaction Details</strong>
                            <div className="mt-2">
                              <div>
                                <small>Master Agent Name - {approveObj.subscriberName}</small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Amount - {getAmtHandle(approveObj.amount, approveObj.feesAmount)}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Master Agent Account No - {approveObj.subscriberAccountNo}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Collection Account No - {approveObj.collectionAccountNo}
                                </small>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        
                        <Input
                          className="form-control-alternative mt-4"
                          id="approve-reason"
                          placeholder="Notes"
                          type="text"
                          name="approveReason"
                          onChange={(e) => setApproveReason(e.target.value)}
                        />
                        {errors.approveReason && <div className="text-left text-danger">
                          <small>* {errors.approveReason}</small>
                        </div>}
                        &nbsp;&nbsp;
                        <Input
                          className="form-control-alternative"
                          id="approve-reason"
                          placeholder="Enter core banking transaction id"
                          type="text"
                          name="coreTransId"
                          onChange={(e) => setCoreTransId(e.target.value)}
                        />
                        {errors.coreTransId && <div className="text-left text-danger">
                          <small>* {errors.coreTransId}</small>
                        </div>}
                        <div className="text-right mt-4">
                          <Button color="primary" onClick={approvePayment} disabled={modalLoading}>
                            Yes {modalLoading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>{' '}
                          <Button color="secondary" onClick={closeApproveModal}
                            disabled={modalLoading}>No</Button>
                        </div>
                      </div> : <> </>
                    }
                  </div>
                </ModalBody>
              </Modal>

              <Modal isOpen={openRejectModal} className="modal-md">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    Reject Payment
                  </h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {rejectObj && rejectObj.status ?
                      <div>
                         <Row className="mb-3">
                          <Col>
                            <strong>Transaction Details</strong>
                            <div className="mt-2">
                              <div>
                                <small>Subscriber Name - {rejectObj.subscriberName}</small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Amount - {getAmtHandle(rejectObj.amount, rejectObj.feesAmount)}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Subscriber Account No - {rejectObj.subscriberAccountNo}
                                </small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Collection Account No - {rejectObj.collectionAccountNo}
                                </small>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        
                        <Input
                          className="form-control-alternative"
                          id="reject-reason"
                          placeholder="Notes"
                          type="text"
                          name="rejectReason"
                          onChange={(e) => setRejectReason(e.target.value)}
                        />
                        {errors.rejectReason && <div className="text-left text-danger">
                          <small>* {errors.rejectReason}</small>
                        </div>}
                        &nbsp;&nbsp;
                        <Input
                          className="form-control-alternative"
                          id="approve-reason"
                          placeholder="Enter core banking transaction id"
                          type="text"
                          name="coreTransId"
                          onChange={(e) => setCoreTransId(e.target.value)}
                        />
                        {errors.coreTransId && <div className="text-left text-danger">
                          <small>* {errors.coreTransId}</small>
                        </div>}
                        <div className="text-right mt-4">
                          <Button color="primary" onClick={rejectPayment}
                            disabled={modalLoading}>
                            Yes {modalLoading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>{' '}
                          <Button color="secondary" onClick={closeRejectModal} disabled={modalLoading}>
                            No
                          </Button>
                        </div>
                      </div> : <> </>
                    }
                  </div>
                </ModalBody>
              </Modal>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortReferenceId, 'sortReferenceId')}>
                      Reference Id
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortReferenceId)}
                          onClick={() => sortHandle(sort.sortReferenceId, 'sortReferenceId')}
                        />
                      </button>
                    </th>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortSubsName, 'sortSubsName')}>
                      Name
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortSubsName)}
                          onClick={() => sortHandle(sort.sortSubsName, 'sortSubsName')}
                        />
                      </button>
                    </th>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortAccNumber, 'sortAccNumber')}>
                      Account Number
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortAccNumber)}
                          onClick={() => sortHandle(sort.sortAccNumber, 'sortAccNumber')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortCreatedAt, 'sortCreatedAt')}>
                      Date
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCreatedAt)}
                          onClick={() => sortHandle(sort.sortCreatedAt, 'sortCreatedAt')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortAmount, 'sortAmount')} >
                      Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortAmount)}
                          onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}
                        />
                      </button>
                    </th>

                    <th scope="col"
                      onClick={() => sortHandle(sort.sortFeesAmount, 'sortFeesAmount')} >
                      Fees Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortFeesAmount)}
                          onClick={() => sortHandle(sort.sortFeesAmount, 'sortFeesAmount')}
                        />
                      </button>
                    </th>
                    {/* <th scope="col">Status</th> */}
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {filteredList.length > 0 && filteredList.map((mmo, i)  => {
                      return (
                        <>
                          <tr>
                            <th scope="row">
                              {mmo.referenceId}
                            </th>
                            <th>
                              {mmo.subscriberName}
                            </th>
                            <th>{mmo.subscriberAccountNo === "null" ? "N/A" : mmo.subscriberAccountNo === null ? "N/A" : mmo.subscriberAccountNo}</th>
                            <td>{moment(mmo.createdAt).format('lll')}</td>
                            <td>{getAmt(mmo.amount)}</td>
                            <td>{getAmt(mmo.feesAmount)}</td>
                            <td>
                              {
                                mmo.status === "Pending" ?
                                  <div>
                                    <Button
                                      color="primary"
                                      onClick={() => approve(mmo)}
                                      size="sm"
                                      style={btnCss}
                                      data-toggle="tooltip"
                                      title="Approve">
                                      <i class="fas fa-check fa-lg"
                                        style={{ color: "#008000" }}></i>
                                    </Button>

                                    <Button
                                      color="primary"
                                      onClick={() => reject(mmo)}
                                      size="sm"
                                      style={btnCss}
                                      data-toggle="tooltip"
                                      title="Reject">
                                      <i class="fas fa-times fa-lg"
                                        style={{ color: "#FF0000" }}></i>
                                    </Button>

                                    <Button
                                      onClick={() => setOpen(!open)}
                                      data-toggle="collapse"
                                      data-target={`.multi-collapse${i}`}
                                      aria-controls={`multiCollapseExample${i}`}
                                      style={btnCss}>
                                      <i class="fas fa-lg"
                                        aria-hidden="true">

                                      </i>
                                    </Button>
                                  </div>
                                  :
                                  <Button
                                    onClick={() => setOpen(!open)}
                                    data-toggle="collapse"
                                    data-target={`.multi-collapse${i}`}
                                    aria-controls={`multiCollapseExample${i}`}
                                    aria-expanded="false"
                                    style={btnCss}>
                                    <i class="fas fa-lg">
                                    </i>
                                  </Button>
                              }
                            </td>
                          </tr>

                          <tr class={`collapse multi-collapse${i}`} id={`multiCollapseExample${i}`}>
                            <td scope="col" className="borderless">
                              <strong>PAYMENT TO  :</strong>
                            </td>
                            <td scope="col" className="borderless">
                              {mmo.paymentTo}
                            </td>
                            <td scope="col" className="borderless">
                              <strong>STATUS : </strong>
                            </td>
                            <td scope="col" className="borderless">
                              {statusFormat(mmo.status)}
                            </td>
                          </tr>
                        </>
                      )
                    }
                    )}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>

              <CardFooter className="py-4">
              {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state }
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetch: (data) => dispatch(paymentList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BankPaymentList);
