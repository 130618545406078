import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { postMethod } from "../../services/httpServices";
import { useToasts } from 'react-toast-notifications';
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { btnCss, iconCss } from '../../appConfig';
import { getAmt, getPaymentType, getSortCls } from "services/util";
import moment from "moment";
import ReactPaginate from 'react-paginate';

const sortIS = {
  sortCommissionAccName: 0,
  sortCommissionAccNo: 0,
  sortCommissionAmount: 0,
  sortCollectionAccountNo: 0,
  sortStatus: 0
};

const BankCollectionCommission = () => {
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openApproveModal, setApproveModal] = React.useState(false);
  const [approveObj, setApproveObj] = React.useState(null);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [coreTransId, setCoreTransId] = React.useState(null);
  const [approveReason, setApproveReason] = React.useState(null);
  const [errors, setError] = React.useState({});
  const [refresh, setRefresh] = React.useState(false);
  const [totalRec, setTotalRec] = React.useState(0);
  const [sort, setSort] = React.useState(sortIS);
  const [isMounted, setIsMounted] = React.useState(false);


  const { addToast } = useToasts();

  /**======================== React Hooks ==========================*/

  // React.useEffect(() => {
  //   fetchCommission();
  // }, []);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        if (currentPage != 0) {
          setCurrentPage(0);
        } else {
          fetchCommission();
        }
      }
    }
  }, [searchText]);


  React.useEffect(() => {
    fetchCommission();
  }, [currentPage]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchCommission();
      }
    }
  }, [sort]);

  React.useEffect(()=> {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchCommission();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);


  /**======================== File Functions ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const getSearch = (val) => {
    setSearchText(val);
  };

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  }

  const handleClick = (e, index) => {

    e.preventDefault();
    setCurrentPage(index);

  }

  const approve = (trans) => {
    setApproveModal(true);
    setApproveObj(trans);
  }

  const closeApproveModal = () => {
    setApproveObj(null);
    setApproveReason(null);
    setCoreTransId(null);
    setError({});
    setApproveModal(false);
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const approveValidate = () => {
    var errors = {};
    if (!approveReason) {
      errors.approveReason = 'Please provide notes';
    }
    if (!coreTransId) {
      errors.coreTransId = 'Please provide core banking transaction id';
    }
    return errors;
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }



  /**======================== API Calls ==========================*/

  const fetchCommission = async () => {
    let errorMsg = '';
    setLoading(true);

    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortCommissionAccName == "1") {
      data['SortingOn'] = 'CommissionAccName';
      data['IsDescending'] = false;
    } else if (sort.sortCommissionAccName == "2") {
      data['SortingOn'] = 'CommissionAccName';
      data['IsDescending'] = true;
    } else if (sort.sortCollectionAccountNo == "1") {
      data['SortingOn'] = 'CollectionAccountNo';
      data['IsDescending'] = false;
    } else if (sort.sortCollectionAccountNo == "2") {
      data['SortingOn'] = 'CollectionAccountNo';
      data['IsDescending'] = true;
    } else if (sort.sortCommissionAccNo == "1") {
      data['SortingOn'] = 'CommissionAccNo';
      data['IsDescending'] = false;
    } else if (sort.sortCommissionAccNo == "2") {
      data['SortingOn'] = 'CommissionAccNo';
      data['IsDescending'] = true;
    } else if (sort.sortCommissionAmount == "1") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = false;
    } else if (sort.sortCommissionAmount == "2") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = true;
    } else if (sort.sortStatus == "1") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = false;
    } else if (sort.sortStatus == "2") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = true;
    }

    postMethod('/api/Collection/BankCommissionCollection', data, 'post')
      .then(res => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (
          res && res.data
        ) {

          if (res.data.responseCode === "200" &&
            res.data.result) {
            // Success
            if (
              !res.data.result.list ||
              !res.data.result.list.length
            ) {
              res.data.result.list = [];
            }
            setList(res.data.result.list);
            setFilteredList(res.data.result.list)
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed('Internal Server Error');
      });
  }

  const transferCommission = () => {
    const validationErrors = approveValidate();
    setError(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      setModalLoading(true);
      var errorMsg = '';
      // var data = approveObj.commissionCollectionIds;
      var data = {
        'CorePaymentTransactionId': coreTransId,
        'Description': approveReason,
        'CommissionCollectionIds': approveObj.commissionCollectionIds,
      }
      let url = '/api/Collection/CommissionTransfer/';
      postMethod(url, data, 'post')
      .then(res => {
        setModalLoading(false);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            notifySuccess('Approved Successfuly');
            closeApproveModal();
            fetchCommission();
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in approving';
            closeApproveModal();
            notifyFailed(errorMsg);
            fetchCommission();
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
            closeApproveModal();
            fetchCommission();
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
          closeApproveModal();
          fetchCommission();
        }
      }).catch(err => {
        setModalLoading(false);
        errorMsg = 'Internal Server Error';
        notifyFailed(errorMsg);
        closeApproveModal();
        fetchCommission();
      });
    }
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={e => { e.preventDefault(); }}>
                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  &nbsp;&nbsp;

                  <Button
                    color="primary"
                    onClick={reloadList}
                    // size="sm"
                    className="my-4"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh">
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>


                </Form>


              </CardHeader>

              <Modal isOpen={openApproveModal} className="modal-md">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    Transfer Commission
                  </h3>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {approveObj && approveObj.status ?
                      <div>
                        <Row className="mb-3">
                          <Col>
                            <strong>Commission Details</strong>
                            <div className="mt-2">
                              <div>
                                <small>Bank Name - {approveObj.bankName}</small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Collection Account No. - {approveObj.collectionAccountNo}
                                </small>
                              </div>
                              <div>
                                <small>Commission Account Name - {approveObj.commissionAccName}</small>
                              </div>
                              <div>
                                <small>Commission Account No. - {approveObj.commissionAccNo}</small>
                              </div>
                              <div>
                                <small className="mt-2">
                                  Amount - {getAmt(approveObj.commissionAmount)}
                                </small>
                              </div>
                            </div>
                          </Col>
                        </Row>

                         
                        <Input
                          className="form-control-alternative mt-4"
                          id="approve-reason"
                          placeholder="Notes"
                          type="text"
                          name="approveReason"
                          onChange={(e) => setApproveReason(e.target.value)}
                        />
                        {errors.approveReason && <div className="text-left text-danger">
                          <small>* {errors.approveReason}</small>
                        </div>}
                        &nbsp;&nbsp;
                        <Input
                          className="form-control-alternative"
                          id="approve-reason"
                          placeholder="Enter core banking transaction id"
                          type="text"
                          name="coreTransId"
                          onChange={(e) => setCoreTransId(e.target.value)}
                        />
                        {errors.coreTransId && <div className="text-left text-danger">
                          <small>* {errors.coreTransId}</small>
                        </div>}
                        
                        <div className="text-right mt-4">
                          <Button color="primary" onClick={transferCommission} disabled={modalLoading}>
                            Yes {modalLoading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>{' '}
                          <Button color="secondary" onClick={closeApproveModal}
                            disabled={modalLoading}>No</Button>
                        </div>
                      </div> : <> </>
                    }
                  </div>
                </ModalBody>
              </Modal>



              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortCommissionAccName, 'sortCommissionAccName')}>
                      Commission Account Name
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCommissionAccName)}
                          onClick={() => sortHandle(sort.sortCommissionAccName, 'sortCommissionAccName')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortCommissionAccNo, 'sortCommissionAccNo')}>
                      Commission Account No
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCommissionAccNo)}
                          onClick={() => sortHandle(sort.sortCommissionAccNo, 'sortCommissionAccNo')}
                        />
                      </button>
                    </th>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortCollectionAccountNo, 'sortCollectionAccountNo')}>
                      Collection Account No
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCollectionAccountNo)}
                          onClick={() => sortHandle(sort.sortCollectionAccountNo, 'sortCollectionAccountNo')}
                        />
                      </button>
                    </th>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortCommissionAmount, 'sortCommissionAmount')}>
                      Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCommissionAmount)}
                          onClick={() => sortHandle(sort.sortCommissionAmount, 'sortCommissionAmount')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}>
                      Status
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortStatus)}
                          onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}
                        />
                      </button>
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {filteredList.length > 0 && filteredList.map((mmo, i) => {
                  return (
                    <>
                      <tr>
                        <th scope="row">
                          {mmo.commissionAccName}
                        </th>
                        <td>{mmo.commissionAccNo}</td>
                        <td>{mmo.collectionAccountNo}</td>
                        <td>{getAmt(mmo.commissionAmount)}</td>
                        <th scope="row">
                          {mmo.status}
                        </th>
                        <td>

                          {mmo.status == 'Collected' &&
                            <Button
                              color="primary"
                              onClick={() => approve(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Transfer">
                              <i class="fas fa-check fa-lg"
                                style={{ color: "#008000" }}></i>
                            </Button>
                          }

                        </td>
                      </tr>

                    </>
                  )
                }
                )}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>

              <CardFooter className="py-4">
              {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}

export default BankCollectionCommission;