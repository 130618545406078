import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import { useParams } from 'react-router-dom'
import VisuallyLoader from "components/VisuallyHiddenLoader/visuallyhiddenLoader";
import swal from 'sweetalert';
import { SECRET_KEY } from "../../appConfig";
import { useToasts } from 'react-toast-notifications';
import { 
  loginMethod, setUserId, getUserId,
  setLoginType, setUserName, setUserB64,
  checkSpace, postMethod 
} from "services/httpServices";
import { useHistory } from "react-router";
import jwt_decode from "jwt-decode";

var CryptoJS = require("crypto-js");

const initialValue = {
  merchantName: '',
  label: '',
  uniqueNo: '',
  bank: {},
  mmo: {},
  amount: '',
  otp: '',
  accountType: null
}

const LABEL = "Please wait while we are checking...";

const dummyValue = {
  merchantName: 'Merter Online',
  label: 'Meter Number',
  uniqueNo: '2432545',
  bank: {bankName: 'Axis', bban: '37469569843', userId: ''},
  mmo: {mmoName: 'Orange Money', mmoAccountNumber: '8465984369834', userId: ''},
  amount: '1000',
  otp: '',
  accountType: null,
  paymentLogId: '',
  subscriberId: '',
  merchantId: ''
}

const PayWithVoucher = () => {
  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [initialLoading, setinitialLoading] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const { id } = useParams();
  const { addToast } = useToasts();
  const [failed, setFailed] = React.useState(0);
  const [validationError, setValidError] = React.useState('');
  const history = useHistory();

  useEffect(()=> {
    // call an api to verify id
    // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(id), 'my-secret-key@123').toString();
    // setinitialLoading(true);

    // setTimeout(() => {
    //   setinitialLoading(false);
    //   setValues(dummyValue);
    //   // genOtp();
    // }, 3000);

    var ciphertext = getCypherText();
    // var bytes  = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    checkId();
  }, []);

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    setValidError(text);
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const getAmt = () => {
    let amt = Number(values.amount);
    return amt.toLocaleString();
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      if (name === "otp") {
        if (checkOTP(value)) {
          setValues({ ...values, [name]: value });
        }
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  }

  const checkOTP = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      if (value.length <= 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const genOtp = () => {
    // setTimeout(()=> {
    //   setSpinner(false);
    //   openModal('We have sent you an otp on your registered number');
    // }, 1000);

    var errorMsg = '';
    var data = JSON.stringify({
      // "UserId": values.subscriberId,
      "UserId": getUserId(),
      "OtpType": "TransactionOTP"
    });
    let url = '/api/Transactions/Sendotp';
    postMethod(url, data, 'post')
    .then(res => {
      setSpinner(false);
      if (res.data) {
        if (res.data.responseCode === "200") {
          let msg = res.data.responseMessage || 'Otp sent';
          // notifySuccess(msg);
          openModal(msg);
        } else if (res.data.responseCode === "400") {
          errorMsg = res.data.responseMessage || 'Error in generating otp';
          notifyFailed(errorMsg);
        } else {
          errorMsg = 'Unknown error in generating otp';
          notifyFailed(errorMsg);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      setSpinner(false);
      errorMsg = 'Internal Server Error';
      notifyFailed(errorMsg);
    });
  }

  const openModal = (msg) => {
    swal({
      // title: msg,
      icon: "success",
      text: msg,
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      buttons:
      {
        confirm: {
          text: "Ok",
          visible: true,
          closeModal: true,
          className: 'sweet-btn'
        }
      }
    })
  }

  const resendOtp = (e) => {
    e.preventDefault();
    setSpinner(true);
    genOtp();
  }

  const getCypherText = () => {
    // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(id), SECRET_KEY).toString();
    // return ciphertext;

    var key = CryptoJS.enc.Utf8.parse('8080808080808080');  
    var iv = CryptoJS.enc.Utf8.parse('8080808080808080'); 
    var ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(id), SECRET_KEY,  
    {  
      keySize: 128 / 8,  
      iv: iv,  
      mode: CryptoJS.mode.CBC,  
      padding: CryptoJS.pad.Pkcs7  
    }).toString(); 
    return ciphertext; 
  }

  const checkId = () => {
    var errorMsg = '';
    setinitialLoading(true);
    // let id = getCypherText();
    // let id = '7f59148f-6135-499d-1633-08d9267e1908';
    let url = '/api/Transactions/GetPaymentLogById/' + id;
    loginMethod(url, {}, 'post')
    .then(res => {
      setinitialLoading(false);
      if (res.data) {
        if (res.data.responseCode === "200") {
          setFailed(0);
          setCheckIdResp(res.data.result);
          genOtp();
        } else if (res.data.responseCode === "400") {
          errorMsg = res.data.responseMessage || 'Error in checking';
          notifyFailed(errorMsg);
          setFailed(1);
        } else {
          errorMsg = 'Unknown error in checking';
          notifyFailed(errorMsg);
          setFailed(1);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        setFailed(1);
      }
    }).catch(err => {
      errorMsg = 'Internal Server Error';
      notifyFailed(errorMsg);
      setinitialLoading(false);
      setFailed(1);
    });
  }

  const setCheckIdResp = (resp) => {
    var data = {
      amount: resp.amount,
      uniqueNo: resp.productId,
      subscriberId: resp.subscriberId,
      merchantId: resp.merchant ? resp.merchant.merchantId : null,
      merchantName: resp.merchant ? resp.merchant.merchantName : null,
      label: resp.merchant ? resp.merchant.labelKey : null,
      merchantUrl: resp.merchant ? resp.merchant.webSiteURL: null,
      bank: resp.bank,
      mmo: resp.mmo,
      paymentLogId: resp.paymentLogId
    }
     window.localStorage.setItem("token", resp.token);
    var decoded = jwt_decode(resp.token);
    if (decoded && decoded.UserProfile) {
      var userProfile = JSON.parse(decoded.UserProfile);
      if (userProfile.hasOwnProperty('UserRole')) {
        setLoginType(userProfile.UserRole);
      }
      if (
        userProfile.hasOwnProperty('UserName') &&
        userProfile.UserName.length
      ) {
        setUserName(userProfile.UserName)
      }

      if (
        userProfile.hasOwnProperty('UserProfileImage') &&
        userProfile.UserProfileImage.length
      ) {
        setUserB64(userProfile.UserProfileImage);
      } else {
        setUserB64(null);
      }

      if (
        userProfile.hasOwnProperty('UserID') &&
        userProfile.UserID.length
      ) {
        setUserId(userProfile.UserID);
      }
    }
     window.localStorage.setItem("validUser", true);
    setValues({...values, ...data});
  }

  const validate2 = function(inputs) {
    var errors = {};
    if (!inputs.accountType) {
      errors.accountType = 'Please select one account';
    }
    if (!inputs.amount) {
      errors.amount = 'Amount is required';
    }
    if (!inputs.subscriberId) {
      errors.id = 'Subscriber is required';
    }
    if (!inputs.otp) {
      errors.otp = 'Please enter OTP';
    }
    return errors;
  }


  const handleSubmitV2 = (e) => {
    e.preventDefault();
    // history.push({pathname: '/show-msg', state: {error: true, msg: 'Error in processing'}});
    const validationErrors = validate2(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      verifyOtp();
    }
  }

  const verifyOtp = () => {
    var errorMsg = '';
    var data = JSON.stringify({
      "UserId": getUserId(),
      "OtpType": "TransactionOTP",
      "Otp": values.otp
    });
    setLoading(true);
    let url = '/api/Transactions/verifyotp';
    postMethod(url, data, 'post')
    .then(res => {
      if (res.data) {
        if (res.data.responseCode === "200") {
          saveTrans();
        } else if (res.data.responseCode === "400") {
          errorMsg = res.data.responseMessage || 'Error in generating otp';
          notifyFailed(errorMsg);
          setLoading(false);
        } else {
          errorMsg = 'Unknown error in generating otp';
          notifyFailed(errorMsg);
          setLoading(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        setLoading(false);
      }
    }).catch(err => {
      errorMsg = 'Internal Server Error';
      notifyFailed(errorMsg);
      setLoading(false);
    });
  }

  const saveTrans = () => {
    var data = new FormData();
    data.append('SubscriberId', values.subscriberId);
    data.append('Amount', values.amount);
    data.append('PaymentMethod', values.accountType);
    data.append('PaymentType', 'Merchant');
    data.append('PaymentTo', values.merchantName);
    data.append('AmountType', 'P');
    data.append('MerchantId', values.merchantId);
    data.append('ProductId', values.uniqueNo);
    data.append('MerchantPaymentLogId', values.paymentLogId);
    if (values.accountType === "Bank") {
      data.append('PaymentFrom', values.bank.bankName);
      data.append('PaymentMethodId', values.bank.bankId);
    } else if (values.accountType === "MMO") {
      data.append('PaymentFrom', values.mmo.mmoName);
      data.append('PaymentMethodId', values.mmo.mmoId);
    }
    let errorMsg = '';
    setLoading(true);
    postMethod('/api/Transactions/Save', data, 'post')
    .then(res => {
      setLoading(false);
      if (res && res.data) {
        if (res.data.responseCode === "200") {
          notifySuccess('Payment added');
          sendToNext({error: false, msg: 'Payment added'});
        } else if (res.data.responseCode === "400") {
          errorMsg = res.data.responseMessage || 'Error while saving';
          notifyFailed(errorMsg);
          sendToNext({error: true, msg: errorMsg});
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in saving';
          notifyFailed(errorMsg);
          sendToNext({error: true, msg: errorMsg});
        }
      } else {
        // Not valid response from server
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        sendToNext({error: true, msg: errorMsg});
      }
    }).catch(err => {
      setLoading(false);
      errorMsg = 'Internal Server Error';
      notifyFailed(errorMsg);
      sendToNext({error: true, msg: errorMsg});
    });
  }

  const sendToNext = (obj) => {
    history.push({pathname: '/show-msg', state: obj});
  }

  return (
    <>
      <Col lg="6" md="7">
        <Card className="bg-secondary shadow border-0 mt--7">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col lg="12">
                <h3 className="mb-0 text-center">Make Payment</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody style={{ minHeight: '300px' }}>
            {initialLoading && 
              <div className="mt-6">
                <VisuallyLoader colorCls="text-teal" label={LABEL}/>
              </div>}

            {!initialLoading && failed == 1 &&
            <div className="mt-4">
              <Row>
                <Col className="text-center">
                  <img
                    alt="..."
                    height="100"
                    width="100"
                    className="round-circle"
                    src={
                      require("../../assets/img/brand/error.png")
                        .default
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-3">
                  <label>
                    {validationError}
                  </label>
                </Col>
              </Row> 
            </div>}
         
            {!initialLoading && failed == 0 && <Form role="form" onSubmit={handleSubmitV2}>
              <Row className="mb-3">
                <Col>
                  <strong>Payment Details</strong>
                  <div className="mt-2">
                    <div>
                      <small>Payment To - {values.merchantName}</small>
                    </div>
                    <div>
                      <small className="mt-2">{values.label} - {values.uniqueNo}</small>
                    </div>
                    <div>
                      <small className="mt-2">Amount - {getAmt()}</small>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-otp">
                        OTP
                        </label>
                        <div className="float-right text-small">
                          <small>
                            <a href="#pablo" onClick={resendOtp}>
                              Resend
                            </a> &nbsp; &nbsp;
                            {spinner && 
                              <div class="spinner-border spinner-border-sm" role="status">
                              <span class="sr-only"></span>
                            </div>}
                          </small>
                        </div>
                        
                    <Input
                      className="form-control-alternative"
                      id="input-otp"
                      placeholder="Enter OTP"
                      type="text"
                      name="otp"
                      value={values.otp}
                      onChange={handleInputChange}
                    />
                    <div className="text-left text-muted">
                      <small><i style={{fontSize: '12px'}}>*
                        we have sent you an OTP on your registered mobile number / email</i></small>
                    </div>
                    <div></div>
                    {errors.otp && <div className="text-left text-danger">
                      <small>* {errors.otp}</small>
                    </div>}
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup tag="fieldset" className="mt-1">
                <strong>Make Payment using</strong>
                <Row>
                  <Col lg="4">
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="accountType" value="Bank"
                          onChange={handleInputChange} />{' '}
                        Bank
                    </Label>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="accountType" value="MMO"
                          onChange={handleInputChange} />{' '}
                        Mobile Money
                    </Label>
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="accountType" value="Wallet"
                          onChange={handleInputChange} />{' '}
                        Voucher Wallet
                    </Label>
                    </FormGroup>
                  </Col>

                </Row>
              </FormGroup>

              {values.accountType && <Row>
                {values.accountType === "Bank" &&
                  <Col lg="12">
                    <strong>Bank Details</strong>
                    <div className="mt-2">
                      <div>
                        <small>Bank Name - {values.bank.bankName}</small>
                      </div>
                      <div>
                        <small className="mt-2">Account Number - {values.bank.bban}</small>
                      </div>
                    </div>

                  </Col>}

                  {values.accountType === "MMO" && <Col>
                    <strong>MMO Details</strong>
                    <div className="mt-2">
                      <div>
                        <small>MMO Name - {values.mmo.mmoName}</small>
                      </div>
                      <div>
                        <small className="mt-2">Account Number - {values.mmo.mmoAccountNumber}
                        </small>
                      </div>
                    </div>
                  </Col>}
              </Row>}

              <Row className="mt-4">
                {/* <Col lg-6>
                  <div>
                    <Button className="btn float-left" color="primary"
                      onClick={goBack}>
                      <i class="fas fa-arrow-alt-circle-left" style={{ fontSize: '18px' }}></i> Back
                      </Button>
                  </div>
                </Col> */}
                {values.accountType && <Col lg-6>
                  <div>
                    <Button className="btn float-right" color="primary" type="submit"
                      disabled={loading || spinner}>
                      Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                    </Button>
                  </div>
                </Col>}
              </Row>
            </Form>
          }
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default PayWithVoucher