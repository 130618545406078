import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Col
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { postMethod } from "../../services/httpServices";
import { useToasts } from 'react-toast-notifications';
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { btnCss, iconCss } from '../../appConfig';
import { getAmt, getPaymentType, getSortCls } from "services/util";
import moment from "moment";
import ReactPaginate from 'react-paginate';

const sortIS = {
  sortName: 0,
  sortPaymentType: 0,
  sortStatus: 0,
  sortPaymentAmt: 0,
  sortCommAmt: 0
};



const BankCommission = () => {
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [totalRec, setTotalRec] = React.useState(0);
  const [sort, setSort] = React.useState(sortIS);
  const [refresh, setRefresh] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);

  const { addToast } = useToasts();

  

  /**======================== React Hooks ==========================*/

  // React.useEffect(() => {
  //   fetchCommission();
  // }, []);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        if (currentPage != 0) {
          setCurrentPage(0);
        } else {
          fetchCommission();
        }
      }
    }
  }, [searchText]);


  React.useEffect(() => {
    fetchCommission();
  }, [currentPage]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchCommission();
      }
    }
  }, [sort]);

  React.useEffect(()=> {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchCommission();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);




  /**======================== File Functions ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const getSearch = (val) => {
    setSearchText(val);
  };

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  }

  const handleClick = (e, index) => {

    e.preventDefault();
    setCurrentPage(index);

  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }

  /**======================== API Calls ==========================*/

  const fetchCommission = async () => {
    let errorMsg = '';
    setLoading(true);

    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize,
      'AccountType': 'Bank'
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortName == "1") {
      data['SortingOn'] = 'UserName';
      data['IsDescending'] = false;
    } else if (sort.sortName == "2") {
      data['SortingOn'] = 'UserName';
      data['IsDescending'] = true;
    } else if (sort.sortPaymentType == "1") {
      data['SortingOn'] = 'PaymentType';
      data['IsDescending'] = false;
    } else if (sort.sortPaymentType == "2") {
      data['SortingOn'] = 'PaymentType';
      data['IsDescending'] = true;
    } else if (sort.sortStatus == "1") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = false;
    } else if (sort.sortStatus == "2") {
      data['SortingOn'] = 'Status';
      data['IsDescending'] = true;
    } else if (sort.sortPaymentAmt == "1") {
      data['SortingOn'] = 'PaymentAmount';
      data['IsDescending'] = false;
    } else if (sort.sortPaymentAmt == "2") {
      data['SortingOn'] = 'PaymentAmount';
      data['IsDescending'] = true;
    } else if (sort.sortCommAmt == "1") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = false;
    } else if (sort.sortCommAmt == "2") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = true;
    }

    postMethod('/api/admin/GetCommissionList/', data, 'post')
      .then(res => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (
          res && res.data
        ) {

          if (res.data.responseCode === "200" &&
            res.data.result) {
            // Success
            if (
              !res.data.result.list ||
              !res.data.result.list.length
            ) {
              res.data.result.list = [];
            }
            setList(res.data.result.list);
            setFilteredList(res.data.result.list)
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed('Internal Server Error');
      });
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={e => { e.preventDefault(); }}>
                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  &nbsp;&nbsp;

                  <Button
                    color="primary"
                    onClick={reloadList}
                    // size="sm"
                    className="my-4"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh">
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>


                </Form>


              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" onClick={() => sortHandle(sort.sortName, 'sortName')}>
                      Bank Name
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortName)}
                          onClick={() => sortHandle(sort.sortName, 'sortName')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortPaymentType, 'sortPaymentType')}>
                      Payment Type
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortPaymentType)}
                          onClick={() => sortHandle(sort.sortPaymentType, 'sortPaymentType')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}>
                      Status
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortStatus)}
                          onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}
                        />
                      </button>
                    </th>
                    <th scope="col" onClick={() => sortHandle(sort.sortPaymentAmt, 'sortPaymentAmt')}>
                      Payment Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortPaymentAmt)}
                          onClick={() => sortHandle(sort.sortPaymentAmt, 'sortPaymentAmt')}
                        />
                      </button>
                    </th>
                    <th scope="col" onClick={() => sortHandle(sort.sortCommAmt, 'sortCommAmt')}>
                      Commission Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCommAmt)}
                          onClick={() => sortHandle(sort.sortCommAmt, 'sortCommAmt')}
                        />
                      </button>
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {filteredList.length > 0 && filteredList.map((mmo, i)  => {
                  return (
                      <>
                        <tr>
                          <th scope="row">
                            {mmo.userName}
                          </th>
                          <td>{getPaymentType(mmo.paymentType)}</td>
                          <td>{mmo.status}</td>
                          <td>{getAmt(mmo.paymentAmount)}</td>
                          <th scope="row">
                            {getAmt(mmo.commissionAmount)}
                          </th>
                          <td>
                            <Button
                              onClick={() => setOpen(!open)}
                              data-toggle="collapse"
                              data-target={`.multi-collapse${i}`}
                              aria-controls={`multiCollapseExample${i}`}
                              aria-expanded="false"
                              style={btnCss}>
                              <i class="fas fa-lg">
                              </i>
                            </Button>
                          </td>
                        </tr>


                        <tr class={`collapse multi-collapse${i}`} id={`multiCollapseExample${i}`}>
                          <td scope="col" className="borderless">
                            <strong>Account Name  :</strong>
                          </td>
                          <td scope="col" className="borderless">
                            {mmo.commissionAccName}
                          </td>
                          <td scope="col" className="borderless">
                            <strong>Account No. : </strong>
                          </td>
                          <td scope="col" className="borderless">
                            {mmo.commissionAccNo}
                          </td>
                          <td scope="col" className="borderless">
                            <strong>Subscriber Name : </strong>
                          </td>
                          <td scope="col" className="borderless">
                            {mmo.subscriberName}
                          </td>
                        </tr>

                        <tr class={`collapse multi-collapse${i}`} id={`multiCollapseExample${i}`}>
                          <td scope="col" className="borderless">
                            <strong>Added On : </strong>
                          </td>
                          <td scope="col" className="borderless">
                            {moment(mmo.createdAt).format('lll')}
                          </td>
                          
                          <td scope="col" className="borderless">
                            <strong>Collected On. : </strong>
                          </td>
                          <td scope="col" className="borderless">
                            {mmo.updatedAt ? moment(mmo.updatedAt).format('lll') : '--'}
                          </td>
                        </tr>
                      </>
                    )
                  }
                )}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>

              <CardFooter className="py-4">
              {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}

export default BankCommission;