
import React, { useEffect } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { getAmt, daysInMonth, daysInArray } from "services/util";
import { postMethod } from "services/httpServices";
import { useToasts } from 'react-toast-notifications';
import { SettlementPieChart, financeMTDCommission, settlementMTDMerchant,
  SettlementDoughnutChart } from "variables/charts";
import { settlementMTDTransfer } from "variables/charts";


const SettlementBankHeader = (props) => {

  const [settleBal, setSettleBal] = React.useState(0);
  const [collBal, setCollBal] = React.useState(0);
  const [merchantPayable, setMerchantPayable] = React.useState(0);
  const [cardLoading, setCardLoading] = React.useState(false);

  const { addToast } = useToasts();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(()=> {
    props.showSettlementMTDMerchantLoader(true);
    fetchMerchantSettlement();
  }, [props.settlementMTDMerchantMonth]);

  useEffect(()=> {
    props.showSettlementMTDMerchantLoader(true);
    fetchMerchantSettlement();
  }, [props.settlementMTDMerchantYear]);

  useEffect(()=> {
    props.showSettlementPieLoader(true);
    fetchPieChart();
  }, [props.settlementPieMonth]);

  useEffect(()=> {
    props.showSettlementPieLoader(true);
    fetchPieChart();
  }, [props.settlementPieYear]);


  useEffect(()=> {
    props.showSettlementMTDTransferLoader(true);
    fetchMTDTransfer();
  }, [props.settlementMTDTransferMonth]);

  useEffect(()=> {
    props.showSettlementMTDTransferLoader(true);
    fetchMTDTransfer();
  }, [props.settlementMTDTransferYear]);

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }


  const fetch = () => {
    let errorMsg = '';
    setCardLoading(true);
    postMethod('/api/dashboard/settlementbankuser', {}, 'post')
    .then(res => {
      setCardLoading(false);
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          if (res.data.result.hasOwnProperty('totalCollection')) {
            setCollBal(res.data.result.totalCollection);
          }
          if (res.data.result.hasOwnProperty('totalSettlement')) {
            setSettleBal(res.data.result.totalSettlement);
          }
          if (res.data.result.hasOwnProperty('totalMerchantPayable')) {
            setMerchantPayable(res.data.result.totalMerchantPayable);
          }

        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching dashboard items';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      setCardLoading(false);
      notifyFailed('Internal Server Error');
    });
  }

  const fetchMerchantSettlement = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.settlementMTDMerchantYear),
      'Month': Number(props.settlementMTDMerchantMonth) + 1
    };
    postMethod('/api/dashboard/SettlementBankUser/MonthlyBarChartMerchantSettlement', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success
          let month = Number(props.settlementMTDMerchantMonth);
          let year = Number(props.settlementMTDMerchantYear);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let valArr = [];
          let totalComm = 0;

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('amount')) {
              valArr.push(Number(val.amount));
              totalComm = totalComm + Number(val.amount);
            } else {
              valArr.push(0);
            }
          }
          let mtdChart = settlementMTDMerchant({
            daysArr: arr, 
            dataArr: valArr,
            totalComm: totalComm
          });
          props.showSettlementMTDMerchant(mtdChart);
          props.showSettlementMTDMerchantLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSettlementMTDMerchantLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSettlementMTDMerchantLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showSettlementMTDMerchantLoader(false);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
      props.showSettlementMTDMerchantLoader(false);
    });
  }

  const fetchPieChart = () => {

    let obj = {
      settlementAmt: 0,
      transfer: 0
    };

    let errorMsg = '';
    var data = {
      'Year': Number(props.settlementPieYear),
      'Month': Number(props.settlementPieMonth) + 1
    };
    postMethod('/api/dashboard/settlementbankuser/MonthlyPiChart', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          if (res.data.result.hasOwnProperty('totalBankSettlement')) {
            obj.settlementAmt = res.data.result.totalBankSettlement;
          }
          if (res.data.result.hasOwnProperty('totalMerchantSettlement')) {
            obj.transfer = res.data.result.totalMerchantSettlement;
          }
          let chart = SettlementDoughnutChart(obj);
          props.showSettlementPieChart(chart);
          props.showSettlementPieLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          props.showSettlementPieLoader(false);
          errorMsg = res.data.responseMessage || 'Error in fetching dashboard items';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          props.showSettlementPieLoader(false);
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);
        }
      } else {
        props.showSettlementPieLoader(false);
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      props.showSettlementPieLoader(false);
      notifyFailed('Internal Server Error');
    });
  }

  const fetchMTDTransfer = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.settlementMTDTransferYear),
      'Month': Number(props.settlementMTDTransferMonth) + 1
    };
    postMethod('/api/dashboard/SettlementBankUser/MonthlyBarChartBankSettlement', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success
          let month = Number(props.settlementMTDTransferMonth);
          let year = Number(props.settlementMTDTransferYear);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let valArr = [];
          let totalComm = 0;

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('amount')) {
              valArr.push(Number(val.amount));
              totalComm = totalComm + Number(val.amount);
            } else {
              valArr.push(0);
            }
          }
          let mtdChart = settlementMTDTransfer({
            daysArr: arr, 
            dataArr: valArr,
            totalComm: totalComm
          });
          props.showSettlementMTDTransfer(mtdChart);
          props.showSettlementMTDTransferLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSettlementMTDTransferLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showSettlementMTDTransferLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showSettlementMTDTransferLoader(false);
      }
    }).catch(err => {
      notifyFailed('Internal Server Error');
      props.showSettlementMTDTransferLoader(false);
    });
  }


  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col xl="4" md="6" lg="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Settlement Account Balance</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(settleBal)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>

              <Col xl="4" md="6" lg="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Collection Accounts Balance </strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-envelope-open-text"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(collBal)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>


              <Col xl="4" md="6" lg="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Merchant Payables</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i class="fas fa-wallet"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(merchantPayable)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>
              

            </Row>

          </div>
        </Container>
      </div>
    </>
  );
};

export default SettlementBankHeader;
