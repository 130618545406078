export const API_ENDPOINTS = "https://lapowalletapi.smartsystemsserver.xyz";
export const LOGIN_API_ENDPOINTS = "https://lapowalletapi.smartsystemsserver.xyz";
// export const TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyUm9sZSI6IkFkbWluIiwiVXNl
// clR5cGUiOiJBZG1pbiIsImp0aSI6ImU5NDhkMzJkLTM4NzktNDU4Ny1hOTM5LTI1NmRhODUwZWU3ZSIsIm5iZiI6MTYxO
// DMwMDk0NSwiZXhwIjoxNjQ5ODM2OTQ1LCJpc3MiOiJodHRwOi8vdGVzdC5jb20iLCJhdWQiOiJKVXRjZE1oZTZ4WDVtd0l
// 3MXZiMnNCaVpaYjUzUm9ZUiJ9.XsnsHTEEsu03LMvVao-3BentRRWlDUVRHnJrYqm1Eik`;
// export const API_ENDPOINTS = 'http://localhost:4800'; // Production Api url
export const TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyUm9sZSI6IkFkbWluIiwiVXNlclR5cGUiOiJBZG1pbiIsImp0aSI6ImU5NDhkMzJkLTM4NzktNDU4Ny1hOTM5LTI1NmRhODUwZWU3ZSIsIm5iZiI6MTYxODMwMDk0NSwiZXhwIjoxNjQ5ODM2OTQ1LCJpc3MiOiJodHRwOi8vdGVzdC5jb20iLCJhdWQiOiJKVXRjZE1oZTZ4WDVtd0l3MXZiMnNCaVpaYjUzUm9ZUiJ9.XsnsHTEEsu03LMvVao-3BentRRWlDUVRHnJrYqm1Eik";
export const iconCss = {
  color: "#e67c18",
};

export const btnCss = {
  cursor: "pointer",
  backgroundColor: "transparent",
  boxShadow: "none",
  border: "none",
};

export const roles = [{ key: "Finance", value: "Finance" }];

export const bankType = [
  { key: "Collection", value: "Collection Account" },
  { key: "Settlement", value: "Settlement Account" },
];

export const subsStatus = [
  { key: "", value: "All", className: "bg-yellow" },
  { key: "Pending", value: "Pending", className: "bg-yellow" },
  // {key: 'SendForApproval', value: 'Send for approval', className: 'bg-blue'},
  { key: "Approved", value: "Approved", className: "bg-cyan" },
  { key: "Rejected", value: "Rejected", className: "bg-danger" },
  // {key: 'Paid', value: 'Paid', className: 'bg-success'}
];

export const collBankStatus = [
  { key: "Pending", value: "Pending", className: "bg-yellow" },
  // {key: 'SendForApproval', value: 'Send for approval', className: 'bg-blue'},
  { key: "Approved", value: "Approved", className: "bg-cyan" },
  { key: "Rejected", value: "Rejected", className: "bg-danger" },
  // {key: 'Paid', value: 'Paid', className: 'bg-success'}
];

export const ReportStatus = [
  { key: "Collected", value: "Collected", className: "bg-yellow" },
  { key: "InProcess", value: "In Process", className: "bg-orange" },
  { key: "Transferred", value: "Transferred", className: "bg-cyan" },
  { key: "Paid", value: "Paid", className: "bg-success" },
  {
    key: "CashTopUpSetteled",
    value: "Cash Topup Settled",
    className: "bg-success",
  },
  {
    key: "CashTopUpApproved",
    value: "Cash Topup Approved",
    className: "bg-blue",
  },
];

export const FinanceUserStatus = [
  { key: "Collected", value: "Collected", className: "bg-yellow" },
  { key: "InProcess", value: "In Process", className: "bg-orange" },
  // {key: 'SendForApproval', value: 'Send for approval', className: 'bg-blue'},
  { key: "Transferred", value: "Transferred", className: "bg-cyan" },
  // {key: 'Rejected', value: 'Rejected', className: 'bg-danger'},
  { key: "Paid", value: "Paid", className: "bg-success" },
];

export const SettleBankUserStatus = [
  { key: "", value: "All", className: "bg-yellow" },
  { key: "Pending", value: "Pending", className: "bg-yellow" },
  { key: "Paid", value: "Paid", className: "bg-success" },
];

export const DayEndReportStatus = [
  { key: "", value: "All", className: "bg-yellow" },
  { key: "Pending", value: "Pending", className: "bg-yellow" },
  { key: "Paid", value: "Paid", className: "bg-success" },
  {
    key: "CashTopUpSetteled",
    value: "Cash Topup Settled",
    className: "bg-success",
  },
  {
    key: "CashTopUpApproved",
    value: "Cash Topup Approved",
    className: "bg-blue",
  },
];

export const AUTH_MODE = [
  { key: "1FA", value: "1FA" },
  { key: "2FA", value: "2FA" },
];

export const MERCHANT_TYPE = [
  { key: "Online", value: "Online" },
  { key: "POS", value: "POS" },
];

export const NFC_OPTION = [
  { key: true, value: "Enable" },
  { key: false, value: "Disable" },
];

export const GET_YEAR_LIST = () => {
  const year = new Date().getFullYear();
  const arr = [
    <option key="" value="">
      -----Select Manufacture Year-------
    </option>,
  ];
  const max = 50;
  for (let i = 0; i < max; i++) {
    arr.push(
      <option key={i} value={year - i}>
        {year - i}
      </option>
    );
  }
  return arr;
};

export const AMOUNT_HINT = `While making payment of less than or equal this
amount your PIN is required, more than this amount PIN and OTP is required.`;

export const PIN_HINT = "Used for making payment";

export const PASSWORD_HINT = "Used for login";

export const SECRET_KEY = "NICHEVOUCHERENCRYPTION";

export const MOBILE_ONLY_COUNTRY = ["sl"];

export const CASH_TOPUP_HINT =
  "* This commission will be applicable while doing top-up to the subscriber";
