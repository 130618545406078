import React, { useEffect, useRef } from "react";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import {
  Table,
  Button,
  Popover, PopoverHeader, PopoverBody,
} from "reactstrap";
import moment from "moment";
import { getSortCls, reportloader, User_Log_Action_Type } from "services/util";
import { btnCss, iconCss } from '../../appConfig';
import FullPageLoader from "components/FullPageLoader/fullpageloader";

const sortIS = {
  sortAction: 0,
  sortActionBy: 0,
  sortLoginUserType: 0,
  sortDate: 0,
  sortStatus: 0,
  sortDesc: 0,
  sortUserType: 0
};

const reportloaderContainer = {
  position: 'absolute',
  top: '260px',
  left: 0,
  width: '100%',
  heigth: '100%',
  backgroundColor: '#F8F8F8AD',
  // display: 'none'
};

const UserLogTable = (props) => {

  const [logType, setLogType] = React.useState(User_Log_Action_Type);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [sort, setSort] = React.useState(sortIS);
  const [tooltips, setToolTips] = React.useState({});
  const toolTipRef = useRef();

  /**============================== React Hooks ============================= */

  useEffect(() => {
    setToolTips({});
    setFilteredList(props.datalist)
  }, [props.datalist]);

  useEffect(() => {
    setToolTips({});
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  useEffect(() => {
    setPageSize(props.pageSize);
  }, [props.pageSize]);

  useEffect(() => {
    props.setParentSort(sort);
  }, [sort]);

  useEffect(() => {
    let handler = (event) => {
      if (toolTipRef.current && !toolTipRef.current.contains(event.target)) {
        setToolTips({});
      }
    }
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    }
  }, [toolTipRef]);

  /**============================== File Function ============================= */

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const checkOpen = (i) => {
    let val = false;
    if (tooltips[i]) {
      val = true;
    }
    return val;
  }


  const toggle = (i) => {
    setToolTips({ [i]: !tooltips[i] });
  }


  const getActionType = (type) => {
    let ob = logType.find(x=> x.id == type);

    if (ob && ob.value) {
      return ob.value;
    } else {
      return type;
    }
  }

  const closepopOver = () => {
    setToolTips({});
  }

  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col"
              onClick={() => sortHandle(sort.sortAction, 'sortAction')}>
              Action
              <button style={btnCss} >
                <i className={getSortCls(sort.sortAction)}
                  onClick={() => sortHandle(sort.sortAction, 'sortAction')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortActionBy, 'sortActionBy')}>
              Action Performed By
              <button style={btnCss} >
                <i className={getSortCls(sort.sortActionBy)}
                  onClick={() => sortHandle(sort.sortActionBy, 'sortActionBy')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortLoginUserType, 'sortLoginUserType')}>
              Login User Type
              <button style={btnCss} >
                <i className={getSortCls(sort.sortLoginUserType)}
                  onClick={() => sortHandle(sort.sortLoginUserType, 'sortLoginUserType')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortUserType, 'sortUserType')}>
              Action Performed On
              <button style={btnCss} >
                <i className={getSortCls(sort.sortUserType)}
                  onClick={() => sortHandle(sort.sortUserType, 'sortUserType')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortDate, 'sortDate')}>
              Action Performed at
              <button style={btnCss} >
                <i className={getSortCls(sort.sortDate)}
                  onClick={() => sortHandle(sort.sortDate, 'sortDate')}
                />
              </button>
            </th>
            <th scope="col"
              onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}>
              Status
              <button style={btnCss} >
                <i className={getSortCls(sort.sortStatus)}
                  onClick={() => sortHandle(sort.sortStatus, 'sortStatus')}
                />
              </button>
            </th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length > 0 && filteredList.map((mmo, i) => {
            return (
              <tr>
                <td scope="row">
                  {getActionType(mmo.actionType)}
                </td>
                <td>{mmo.performBy}</td>
                <td>{mmo.performByRole ? mmo.performByRole : '--'}</td>
                <td>{mmo.userType ? mmo.userType : '--'}</td>
                <td>{moment(mmo.createdAt).format('lll')}</td>
                <td>{mmo.status}</td>
                <td>
                  <div ref={toolTipRef}>
                    <Button
                      color="primary"
                      size="sm"
                      style={btnCss}
                      id={'Popover-' + Number(currentPage * pageSize + i)}
                    >
                      <i class="fa fa-info-circle fa-lg"
                        aria-hidden="true"
                        style={iconCss}>
                      </i>

                    </Button>
                    <Popover
                      placement='bottom'
                      isOpen={checkOpen(Number(currentPage * pageSize + i))}
                      target={'Popover-' + Number(currentPage * pageSize + i)}
                      toggle={() => toggle(Number(currentPage * pageSize + i))}
                    >
                      <PopoverHeader className="popover-header-cls">
                        Description
                        <Button
                          color="primary"
                          onClick={closepopOver}
                          size="sm"
                          close
                          style={btnCss}
                        >
                          <i class="fa fa-times fa-lg"
                            aria-hidden="true"
                            style={{ color: '#212529', marginTop: '7px' }}>
                          </i>
                        </Button>
                      </PopoverHeader>
                      <PopoverBody>{mmo.description}</PopoverBody>
                    </Popover>
                  </div>
                </td>
              </tr>
            )
          }
          )}
        </tbody>
        {props.loading &&
          <FullPageLoader label={"Fetching..."}
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        }
        {!props.loading && !filteredList.length &&
          <NoRecordFound
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        }
      </Table>
    </>
  );
}

export default UserLogTable;