import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { getMethod } from "services/httpServices";
import CollectionReport from "./collection-report";
import CollectionReportTable from "./collection-report-table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CollectionSummaryReport from "./collection-summary-report";
import CollectionSummaryReportTable from "./collection-summary-table";
import SettlementSummaryReport from "./settlement-summary-report";
import SettlementSummaryReportTable from "./settlement-summary-table";
import SettlementReport from "./settlement-report";
import SettlementReportTable from "./settlement-report-table";
import ReactPaginate from "react-paginate";
import { ReportStatus, subsStatus } from "appConfig";
import { getAmt } from "services/util";
import TransactionReport from "./transaction-report";
import TransactionReportTable from "./transaction-report-table";
import BankReport from "./bank-report";
import BankReportTable from "./bank-report-table";
import MerchantCommission from "./merchant-commission";
import MerchantCommissionTable from "./merchant-commission-table";
import WalletCommissionTable from "./wallet-commission-table";
import WalletCommission from "./wallet-commission";
import EDSATransaction from "./EDSATransaction.js";
import PendingSettlementsReport from "./pending-settlements";
import SubscriberBalanceAnalysisReport from "./subscriber-balance-analysis";
import SettlementLiquidityAnalysisReport from "./settlement-liquidity-analysis";
import CommissionsGrowthAnalysisReport from "./commissions-growth-analysis";
import AgentCommissionsReport from "./agent-commissions";
import PendingSettlementsReportTable from "./pending-settlements-table";
import SubscriberBalanceAnalysisReportTable from "./subscriber-balance-analysis-table";
import SettlementLiquidityAnalysisReportTable from "./settlement-liquidity-analysis-table";
import AgentCommissionsReportTable from "./agent-commissions-table";
import CommissionsGrowthAnalysisReportTable from "./commissions-growth-analysis-table";

const REPORTS = [
  { key: 1, value: "Detailed Collection Reports" },
  { key: 2, value: "Daily Collection Summary Reports" },
  { key: 3, value: "Detailed Settlement Reports" },
  { key: 4, value: "Daily Summary Settlement Reports" },
  { key: 5, value: "Transaction Report" },
  { key: 6, value: "Daily Bank Report" },
  { key: 7, value: "Merchant Commissions Report" },
  { key: 8, value: "Wallet Commissions Report" },
  { key: 9, value: "EDSA Transaction Report" },
  { key: 10, value: "Pending Settlements Report" },
  { key: 11, value: "Subscriber Balance Analysis Report" },
  { key: 12, value: "Settlement Liquidity Analysis Report" },
  // { key: 13, value: "Commissions Growth Analysis Report" },
  // { key: 14, value: "Agent Commissions Report" },
];

const STATUS = ReportStatus;
const SUBS_STATUS = subsStatus;

const Reports = () => {
  const [reportFilter, setReportFilter] = React.useState("");
  const [merchant, setMerchant] = React.useState([]);
  const [result, setResult] = React.useState([]);
  const [collectionR, setCollectionR] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [pagesCount, setPageCount] = React.useState(0);
  const [response, setResponse] = React.useState(false);
  const [pdfLoading, setPdfLoading] = React.useState(false);
  const [xlLoading, setXlLoading] = React.useState(false);
  const [bank, setBank] = React.useState([]);
  const [mmo, setMMO] = React.useState([]);
  const [sort, setSort] = React.useState({});
  const [totalRec, setTotalRec] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);
  const [reportHeadings, setReportHeadings] = React.useState();

  const { addToast } = useToasts();

  useEffect(() => {
    fetchMerchants();
    fetchBank();
  }, []);

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const getPdfHeader = () => {
    if (reportFilter === "1") {
      return [
        "Instruction Date/Time",
        "Merchant Name",
        "Bank Code",
        "Customer UID",
        "Transaction Amount",
        "Reference Code",
        "Transaction Ref",
        "Status",
        "Clearing Date/Time",
      ];
    } else if (reportFilter === "2") {
      return ["Date", "Merchant Name", "Bank Code", "Total Amount"];
    } else if (reportFilter === "3") {
      return [
        "Instruction Date/Time",
        "Merchant Name",
        "Paying Bank Code",
        "Receving Bank Code",
        "Transaction Amount",
        "Transaction Fees",
        "Transaction Ref",
        "Status",
        "Clearing Date/Time",
      ];
    } else if (reportFilter === "4") {
      return [
        "Date",
        "Merchant Name",
        "Bank Code",
        "Total Amount",
        "Fees Amount",
      ];
    } else if (reportFilter === "5") {
      return [
        "Payment Type",
        "Payment To",
        "Payment From",
        "Payment Using",
        "Smart Pay Id",
        "Product Id",
        "Date",
        "Amount",
        "Fees Amount",
        "Description",
        "Status",
      ];
    } else if (reportFilter == "6") {
      return [
        "Bank Name",
        "Subscriber Name",
        "Date",
        "Payment Type",
        "Merchant Name",
        "Amount",
        "Transaction Fees",
        "Transaction Ref",
        "Status",
      ];
    } else if (reportFilter == "7") {
      return [
        "Instruction Date/Time",
        "Merchant Name",
        "Transaction Amount",
        "Commission Amount",
      ];
    } else if (reportFilter == "10") {
      return ["Merchant Name", "Bank Name", "Account Number", "Amount Due"];
    } else if (reportFilter == "11") {
      return [
        "Balance Range (Le)",
        "I.S Count",
        "C.S Count",
        "Total Count",
        "Percent Share",
      ];
    } else if (reportFilter == "12") {
      return ["Bank Type", "Amount", "Percent Share", "Required Action"];
    } else {
      return [[]];
    }
  };

  const getPdfTitle = () => {
    if (reportFilter == "1") {
      return "Detailed Collection Report";
    } else if (reportFilter == "2") {
      return "Daily Collection Summary Report";
    } else if (reportFilter == "3") {
      return "Detailed Settlement Reports";
    } else if (reportFilter == "4") {
      return "Daily Settlement Summary Report";
    } else if (reportFilter == "5") {
      return "Transaction Report";
    } else if (reportFilter == "6") {
      return "Daily Bank Report";
    } else if (reportFilter == "7") {
      return "Merchant Commission Report";
    } else if (reportFilter == "8") {
      return "Wallet Commission Report";
    } else if (reportFilter == "10") {
      return "Pending Settlements Report";
    } else if (reportFilter == "11") {
      return "Subscriber Balance Analysis Report";
    } else if (reportFilter == "12") {
      return "Settlement Liquidity Analysis Report";
    } else {
      return "";
    }
  };

  const statusFormat = (val) => {
    let ob = STATUS.find((s) => s.key === val);
    return ob && ob.value ? ob.value : val;
  };

  const transStatusFormat = (val) => {
    let ob = SUBS_STATUS.find((s) => s.key === val);
    return ob && ob.value ? ob.value : val;
  };

  const getPaymentType = (type) => {
    let str = type;
    if (type == "topup") {
      str = "Top Up";
    } else if (type == "BalanceTransfer") {
      str = "Balance Transfer";
    } else if (type == "Merchant") {
      str = "Merchant Payment";
    } else if (type == "WalletPayment") {
      str = "Wallet Payment";
    } else if (type == "CashTopUp") {
      str = "Cash Top Up";
    }
    return str;
  };

  const getPdfData = (reportData) => {
    if (reportFilter == "1") {
      return reportData.map((elt) => [
        moment(elt.createdat).format("lll"),
        elt.merchantName,
        elt.bank_MMOCode,
        elt.subscriberMobileNumber,
        getAmt(elt.amount),
        elt.productId,
        elt.referenceId,
        statusFormat(elt.status),
        elt.updatedat ? moment(elt.updatedat).format("lll") : "--",
      ]);
    } else if (reportFilter == "2") {
      return reportData.map((elt) => [
        moment(elt.createdat).format("MMM Do, YYYY"),
        elt.merchantName,
        elt.bank_MMOCode,
        getAmt(elt.amount),
      ]);
    } else if (reportFilter == "3") {
      return reportData.map((elt) => [
        moment(elt.createdat).format("lll"),
        elt.merchantName,
        elt.payingBankCode,
        elt.receivingBank,
        getAmt(elt.amount),
        getAmt(elt.feesAmount),
        elt.referenceId,
        statusFormat(elt.status),
        elt.updatedAt ? moment(elt.updatedAt).format("lll") : "--",
      ]);
    } else if (reportFilter == "4") {
      return reportData.map((elt) => [
        moment(elt.createdAt).format("MMM Do, YYYY"),
        elt.merchantName,
        elt.payingBankCode,
        getAmt(elt.amount),
        getAmt(elt.feesAmount),
      ]);
    } else if (reportFilter == "5") {
      return reportData.map((elt) => [
        getPaymentType(elt.paymentType),
        elt.paymentTo,
        elt.paymentFrom,
        elt.paymentMethod,
        elt.referenceId,
        elt.productId ? elt.productId : "--",
        moment(elt.createdAt).format("lll"),
        getAmt(elt.amount),
        getAmt(elt.feesAmount),
        elt.description,
        transStatusFormat(elt.status),
      ]);
    } else if (reportFilter == "6") {
      return reportData.map((elt) => [
        elt.paymentFrom,
        elt.subscriberName,
        moment(mmo.updatedAt).format("lll"),
        getPaymentType(elt.paymentType),
        elt.merchantName ? elt.merchantName : "--",
        getAmt(elt.amount),
        getAmt(elt.feesAmount),
        elt.referenceId,
        elt.status,
      ]);
    } else if (reportFilter == "7") {
      return reportData.map((elt) => [
        moment(elt.createdAt).format("lll"),
        elt.userName,
        getAmt(elt.paymentAmount),
        getAmt(elt.commissionAmount),
      ]);
    } else if (reportFilter == "8") {
      return reportData.map((elt) => [
        moment(elt.createdAt).format("lll"),
        elt.bankName,
        elt.subscriberName,
        getAmt(elt.paymentAmount),
        getTransactionType(elt.paymentType),
        getAmt(elt.commissionAmount),
      ]);
    } else if (reportFilter == "10") {
      return reportData.map((elt) => [
        elt.merchantName,
        elt.merchantBankName,
        elt.merchantAccountNo,
        getAmt(elt?.amount + elt?.commissionAmount),
      ]);
    } else if (reportFilter == "11") {
      return reportData.map((elt) => [
        elt.name,
        elt.individualSubscriber,
        elt.corporateSubscriber,
        elt.totalSubscriberCount,
        `${elt?.percentShare?.toFixed(2)}%`,
      ]);
    } else if (reportFilter == "12") {
      return [
        [
          "Settlement Bank",
          getAmt(reportData?.settlemenetBankBalance || 0),
          `${reportData?.percentagesettlementshare}%`,
          reportData?.netPosition < 0
            ? "Please transfer funds from Collection to Settlement Account to meet the settlement cash requirements"
            : "Liquidity position OK",
        ],
        [
          "Collection Bank",
          getAmt(reportData?.collectionBankBalance || 0),
          `${reportData?.percentagecollectionshare}%`,
          "",
        ],
      ];
    } else {
      return [];
    }
  };

  const getTransactionType = (type) => {
    if (type === "topup") {
      return "Top Up";
    }
    return type;
  };

  const getPdfTotal = (reportData) => {
    let total = reportData.reduce((sum, el) => sum + el.amount, 0);
    return total > 0 ? getAmt(total) : total;
  };

  const exportPDF = (reportData) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = getPdfTitle();
    const headers = [getPdfHeader()];
    const data = getPdfData(reportData);

    var summaryReport = false;
    if ((reportFilter == "2" || reportFilter == "4") && reportData.length) {
      summaryReport = true;
    }
    const COLSPAN = summaryReport ? (reportFilter == "2" ? 3 : 4) : 0;
    const total = summaryReport ? getPdfTotal(reportData) : 0;

    let content = {
      startY: 50,
      head: headers,
      margin: { left: 10, right: 10 },
      body: summaryReport
        ? [
            ...data,
            [
              {
                content: "Total",
                colSpan: COLSPAN,
                styles: { fillColor: [163, 174, 169] },
              },
              {
                content: total,
                colSpan: 1,
                styles: { fillColor: [163, 174, 169] },
              },
            ],
          ]
        : data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
    setTimeout(() => {
      setPdfLoading(false);
    }, 1000);
  };

  const getCsvHeading = () => {
    return [[getPdfTitle()], getPdfHeader()];
  };

  const getCsvHeader = () => {
    if (reportFilter == "1") {
      return [
        "createdat",
        "merchantName",
        "bank_MMOCode",
        "subscriberMobileNumber",
        "amount",
        "productId",
        "referenceId",
        "status",
        "updatedat",
      ];
    } else if (reportFilter == "2") {
      return ["createdat", "merchantName", "bank_MMOCode", "amount"];
    } else if (reportFilter == "3") {
      return [
        "createdat",
        "merchantName",
        "payingBankCode",
        "receivingBank",
        "amount",
        "feesAmount",
        "referenceId",
        "status",
        "updatedAt",
      ];
    } else if (reportFilter == "4") {
      return [
        "createdAt",
        "merchantName",
        "payingBankCode",
        "amount",
        "feesAmount",
      ];
    } else if (reportFilter == "5") {
      return [
        "paymentType",
        "paymentTo",
        "paymentFrom",
        "paymentMethod",
        "referenceId",
        "productId",
        "createdAt",
        "amount",
        "feesAmount",
        "description",
        "status",
      ];
    } else if (reportFilter == "6") {
      return [
        "paymentFrom",
        "subscriberName",
        "updatedAt",
        "paymentType",
        "merchantName",
        "amount",
        "feesAmount",
        "referenceId",
        "status",
      ];
    } else if (reportFilter == "7") {
      return ["createdAt", "userName", "paymentAmount", "commissionAmount"];
    } else if (reportFilter == "8") {
      return [
        "createdAt",
        "bankName",
        "subscriberName",
        "paymentAmount",
        "paymentType",
        "commissionAmount",
      ];
    } else if (reportFilter == "10") {
      return ["Merchant Name", "Bank Name", "Account Number", "Amount Due"];
    } else if (reportFilter == "11") {
      return [
        "Balance Range (Le)",
        "I.S Count",
        "C.S Count",
        "Total Count",
        "Percent Share",
      ];
    } else if (reportFilter == "12") {
      return ["Bank Type", "Amount", "Percent Share", "Required Action"];
    } else {
      return [];
    }
  };

  const getColumnName = (i) => {
    return (
      (i >= 26 ? getColumnName(Math.floor(i / 26) - 1) : "") +
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[i % 26]
    );
  };

  const getFeesAmt = (obj) => {
    obj.feesAmount = getAmt(obj.feesAmount);
    return obj;
  };

  const csvFormat = (obj) => {
    if (reportFilter == "1") {
      obj.createdat = moment(obj.createdat).format("lll");
      obj.updatedat = obj.updatedat
        ? moment(obj.updatedat).format("lll")
        : "--";
    } else if (reportFilter == "2") {
      obj.createdat = moment(obj.createdat).format("MMM Do, YYYY");
    } else if (reportFilter == "3") {
      obj.createdat = moment(obj.createdat).format("lll");
      obj.updatedAt = obj.updatedAt
        ? moment(obj.updatedAt).format("lll")
        : "--";
    } else if (reportFilter == "4") {
      obj.createdAt = moment(obj.createdAt).format("MMM Do, YYYY");
    } else if (reportFilter == "5") {
      obj.status = transStatusFormat(obj.status);
      obj.description = transStatusFormat(obj.description);
      obj.paymentType = getPaymentType(obj.paymentType);
      obj.createdAt = moment(obj.createdAt).format("lll");
      obj.productId = obj.productId ? obj.productId : "--";
    } else if (reportFilter == "6") {
      obj.paymentType = getPaymentType(obj.paymentType);
      obj.updatedAt = moment(obj.updatedAt).format("lll");
    } else if (reportFilter == "7") {
      obj.createdAt = moment(obj.createdAt).format("lll");
      obj.paymentAmount = getAmt(obj.paymentAmount);
      obj.commissionAmount = getAmt(obj.commissionAmount);
    } else if (reportFilter == "8") {
      obj.createdAt = moment(obj.createdAt).format("lll");
      obj.paymentAmount = getAmt(obj.paymentAmount);
      obj.commissionAmount = getAmt(obj.commissionAmount);
      obj.paymentType = getTransactionType(obj.paymentType);
    }
    if (obj.feesAmount) {
      obj.feesAmount = getAmt(obj.feesAmount);
    }
    return obj;
  };

  const exportToXlsx = (reportData) => {
    const wb = XLSX.utils.book_new();
    let data = [];
    if (reportFilter == "10") {
      data = reportData.map((elt) => {
        const obj = {};
        obj["Merchant Name"] = elt.merchantName;
        obj["Bank Name"] = elt.merchantBankName;
        obj["Account Number"] = elt.merchantAccountNo;
        obj["Amount Due"] = getAmt(elt?.amount + elt?.commissionAmount);
        return obj;
      });
    } else if (reportFilter == "11") {
      data = reportData.map((elt) => {
        const obj = {};
        obj["Balance Range (Le)"] = elt.name;
        obj["I.S Count"] = elt.individualSubscriber;
        obj["C.S Count"] = elt.corporateSubscriber;
        obj["Total Count"] = elt.totalSubscriberCount;
        obj["Percent Share"] = `${elt.percentShare?.toFixed(2)}%`;
        return obj;
      });
    } else if (reportFilter == "12") {
      data = [
        {
          "Bank Type": "Settlement Bank",
          Amount: getAmt(reportData?.settlemenetBankBalance || 0),
          "Percent Share": `${reportData?.percentagesettlementshare}%`,
          "Required Action":
            reportData?.netPosition < 0
              ? "Please transfer funds from Collection to Settlement Account to meet the settlement cash requirements"
              : "Liquidity position OK",
        },
        {
          "Bank Type": "Collection Bank",
          Amount: getAmt(reportData?.collectionBankBalance || 0),
          "Percent Share": `${reportData?.percentagecollectionshare}%`,
          "Required Action": "",
        },
      ];
    }

    const headings = getCsvHeader();
    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Set up column headers
    ws["!cols"] = headings.map((heading) => ({ wch: heading.length + 5 }));

    // Export the workbook to a file
    setTimeout(() => {
      setXlLoading(false);
    }, 1000);
    XLSX.writeFile(wb, "report.xlsx");
  };

  const exportToCSV = (reportData) => {
    if (reportFilter == "10" || reportFilter === "11" || reportFilter == "12") {
      exportToXlsx(reportData);
      return;
    }
    const header = getCsvHeader();
    const originalHeader = [...header];

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    var Heading = getCsvHeading();

    let newReportData = reportData.map((elt) => {
      const obj = Object.assign({}, elt);
      obj.status = statusFormat(obj.status);
      obj.amount = getAmt(obj.amount);
      // return obj.feesAmount ?  getFeesAmt(obj) : obj
      return csvFormat(obj);
    });

    var ws = XLSX.utils.aoa_to_sheet(Heading);
    XLSX.utils.sheet_add_json(ws, newReportData, {
      header: header,
      skipHeader: true,
      origin: -1,
    });

    if (reportFilter == "4" && reportData.length) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Total", "", "", "", getPdfTotal(reportData)]],
        { origin: -1 }
      );
    }

    if (reportFilter == "2" && reportData.length) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Total", "", "", getPdfTotal(reportData)]],
        { origin: -1 }
      );
    }
    if (reportFilter == "10" && reportData.length) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Total", "", "", getPdfTotal(reportData)]],
        { origin: -1 }
      );
    }

    // For setting width of columns
    var wscols = [];
    for (var i = 0; i < header.length; i++) {
      // columns length added
      wscols.push({ wch: header[i].length + 10 });
    }
    ws["!cols"] = wscols;
    if (ws["!cols"]) {
      const original = getColumnName(
        ws["!cols"].length > 0 ? ws["!cols"].length - 1 : 0
      );
      const replace = getColumnName(
        originalHeader.length > 0 ? originalHeader.length - 1 : 0
      );

      // For removing other columns
      ws["!ref"] = ws["!ref"].replace(original, replace);
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Report" + fileExtension);
    setTimeout(() => {
      setXlLoading(false);
    }, 1000);
  };

  const fetchMerchants = async () => {
    let errorMsg = "";
    getMethod("/api/MerchantProfile/ddllist", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setMerchant(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching merchant";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
      });
  };

  const fetchBank = async () => {
    let errorMsg = "";
    getMethod("/api/bankProfile/ddllist?Accounttype=Collection", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setBank(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching bank";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in bank";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Internal Server Error");
      });
  };

  const changeReportType = (val) => {
    setReportFilter(val);
    setResponse(false);
    setResult([]);
    setLoading(false);
    setPdfLoading(false);
    setXlLoading(false);
  };

  const detailHandleCB = (resp) => {
    setResponse(true);
    setResult(resp.list);
    let pc = Math.ceil(resp.totalRecords / pageSize);
    setTotalRec(resp.totalRecords);
    // setCurrentPage(0);
    setCollectionR(resp.list);
    setPageCount(pc);
  };

  const detailSummaryHandleCB = (resp) => {
    setResponse(true);
    setResult(resp.list);
    let pc = Math.ceil(resp.totalRecords / pageSize);
    setTotalRec(resp.totalRecords);
    // setCurrentPage(0);
    setCollectionR(resp.list);
    setPageCount(pc);
  };

  const detailSummarySort = (s) => {
    setSort(s);
  };

  const settlementSummaryHandleCB = (resp) => {
    setResponse(true);
    setResult(resp.list);
    let pc = Math.ceil(resp.totalRecords / pageSize);
    setTotalRec(resp.totalRecords);
    // setCurrentPage(0);
    setCollectionR(resp.list);
    setPageCount(pc);
  };

  const transactionReportHandleCB = (resp) => {
    setResponse(true);
    setResult(reportFilter == "12" ? resp : resp.list);
    setReportHeadings(resp);
    let pc = Math.ceil(resp.totalRecords / pageSize);
    setTotalRec(resp.totalRecords);
    // setCurrentPage(0);
    setCollectionR(reportFilter == "12" ? resp : resp.list);
    setPageCount(pc);
  };

  const bankReportHandleCB = (resp) => {
    setResponse(true);
    setResult(resp.list);
    let pc = Math.ceil(resp.totalRecords / pageSize);
    setTotalRec(resp.totalRecords);
    // setCurrentPage(0);
    setCollectionR(resp.list);
    setPageCount(pc);
  };

  const summaryHandleCB = (resp) => {
    setResponse(true);
    setResult(resp.list);
    let pc = Math.ceil(resp.totalRecords / pageSize);
    setTotalRec(resp.totalRecords);
    // setCurrentPage(0);
    setCollectionR(resp.list);
    setPageCount(pc);
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index + 1);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const reportLoading = (loaderValue) => {
    setLoading(loaderValue);
  };

  const getReportData = () => {
    setPdfLoading(true);
    // setReportData([]);
  };

  const getXLReportData = () => {
    setXlLoading(true);
    // setReportData([]);
  };

  const setReportHandle = (resp) => {
    if (pdfLoading) {
      exportPDF(reportFilter == "12" ? resp : resp.list);
    } else if (xlLoading) {
      exportToCSV(reportFilter == "12" ? resp : resp.list);
    }
  };

  const setErrorReportData = () => {
    setPdfLoading(false);
    setXlLoading(false);
  };

  const changePageNo = (num) => {
    setCurrentPage(num);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <Form
                  className="form-inline"
                  style={{
                    alignItems: reportFilter === "9" ? "flex-start" : "center",
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Row>
                    <Col>
                      <Row>
                        <Col lg="12">
                          <small>
                            <strong>Reports</strong>
                          </small>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup>
                            <div class="dropdown-border">
                              <Input
                                type="select"
                                id="report"
                                className="form-control-alternative status-filter"
                                name="reportFilter"
                                value={reportFilter}
                                onChange={(e) =>
                                  changeReportType(e.target.value)
                                }
                              >
                                <option>---- Select Report ----</option>
                                {REPORTS.map((c) => (
                                  <option key={c.key} value={c.key}>
                                    {c.value}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* <FormGroup>
                    <div class="dropdown-border mb-4 mt-5">
                      <Input
                        type="select"
                        id="report"
                        className="form-control-alternative status-filter"
                        name="reportFilter"
                        value={reportFilter}
                        onChange={(e) => changeReportType(e.target.value)}
                      >
                        <option>----Select Report ----</option>
                        {REPORTS.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )}
                      </Input>
                    </div>

                  </FormGroup> */}

                  {reportFilter === "1" && (
                    <CollectionReport
                      merchant={merchant}
                      bank={bank}
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={detailHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}

                  {reportFilter === "2" && (
                    <CollectionSummaryReport
                      merchant={merchant}
                      bank={bank}
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={detailSummaryHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}

                  {reportFilter === "3" && (
                    <SettlementReport
                      merchant={merchant}
                      bank={bank}
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={summaryHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}

                  {reportFilter === "4" && (
                    <SettlementSummaryReport
                      merchant={merchant}
                      bank={bank}
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={settlementSummaryHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}

                  {reportFilter === "5" && (
                    <TransactionReport
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={transactionReportHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}

                  {reportFilter === "6" && (
                    <BankReport
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={bankReportHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}

                  {reportFilter === "7" && (
                    <MerchantCommission
                      merchant={merchant}
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={bankReportHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}

                  {reportFilter === "8" && (
                    <WalletCommission
                      bank={bank}
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={detailHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}
                  {reportFilter === "9" && (
                    <Col>
                      {" "}
                      <EDSATransaction />
                    </Col>
                  )}
                  {reportFilter === "10" && (
                    <PendingSettlementsReport
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={transactionReportHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}
                  {reportFilter === "11" && (
                    <SubscriberBalanceAnalysisReport
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={transactionReportHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}
                  {reportFilter === "12" && (
                    <SettlementLiquidityAnalysisReport
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={transactionReportHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}
                  {reportFilter === "13" && (
                    <CommissionsGrowthAnalysisReport
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={transactionReportHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}
                  {reportFilter === "14" && (
                    <AgentCommissionsReport
                      currentPage={currentPage}
                      sort={sort}
                      parentCallback={transactionReportHandleCB}
                      parentLoading={reportLoading}
                      parentReportData={setReportHandle}
                      errorReportData={setErrorReportData}
                      pdfLoading={pdfLoading}
                      xlLoading={xlLoading}
                      totalRec={totalRec}
                      changePage={changePageNo}
                    />
                  )}

                  {response && (
                    <>
                      <div className="ml-4 mt-3">
                        {/* <label>Export</label> */}
                        Export &nbsp; &nbsp; &nbsp;
                        <Button
                          color="primary"
                          size="sm"
                          disabled={pdfLoading}
                          onClick={getReportData}
                        >
                          PDF{" "}
                          {pdfLoading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                          color="primary"
                          size="sm"
                          disabled={xlLoading}
                          onClick={getXLReportData}
                        >
                          EXCEL{" "}
                          {xlLoading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              </CardHeader>

              {response && reportFilter === "1" && (
                <CollectionReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "2" && (
                <CollectionSummaryReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "3" && (
                <SettlementReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "4" && (
                <SettlementSummaryReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "5" && (
                <TransactionReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "6" && (
                <BankReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "7" && (
                <MerchantCommissionTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "8" && (
                <WalletCommissionTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "10" && (
                <PendingSettlementsReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  headings={reportHeadings}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "11" && (
                <SubscriberBalanceAnalysisReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  headings={reportHeadings}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "12" && (
                <SettlementLiquidityAnalysisReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "13" && (
                <CommissionsGrowthAnalysisReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              {response && reportFilter === "14" && (
                <AgentCommissionsReportTable
                  datalist={result}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setParentSort={detailSummarySort}
                  loading={loading}
                />
              )}

              <CardFooter className="py-5">
                {result.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>Total Records : {totalRec}</strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6">
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage}
                          />
                        </div>
                      </nav>
                    </Col>
                  </Row>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Reports;
